import { createI18n } from 'vue-i18n';

import { ELocale } from '@/ui/experimental/LocalizationButton/domain/constants';

import localeTextTranslationsEn from './utils/localeTextTranslationsEn';
import localeTextTranslationsRu from './utils/localeTextTranslationsRu';

/**
 * This function returns the index of the plural form to use for a given `choice`
 * and `choicesLength`.
 *
 * For Russian, case sets of 3 or 4 positions should be used.
 *
 * Given the localization of zero:
 * ```
 * "declensions": "нет данных | {n} тонна | {n} тонны | {n} тонн"
 * ```
 *
 * Without explicitly localizing the zero - there will be `0 тонн':
 * ```
 * "declensions": "{n} тонна | {n} тонны | {n} тонн"
 * ```
 *
 * The function implements the rules for Russian language.
 *
 * @param {number} choice The choice number.
 * @param {number} choicesLength The length of the choices array.
 * @returns {number} The index of the plural form to use.
 */
function customRule(choice: number, choicesLength: number) {
  if (choice === 0) {
    return choicesLength < 4 ? 2 : 0;
  }

  const teen = choice > 10 && choice < 20;
  const endsWithOne = choice % 10 === 1;
  if (!teen && endsWithOne) {
    return choicesLength < 4 ? 0 : 1;
  }
  if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
    return choicesLength < 4 ? 1 : 2;
  }

  return choicesLength < 4 ? 2 : 3;
}

export const i18n = createI18n({
  fallbackLocale: ELocale.ru,
  locale: ELocale.ru,
  pluralizationRules: { ru: customRule },

  messages: {
    ru: localeTextTranslationsRu,
    en: localeTextTranslationsEn,
    zh: {},
  },
});
