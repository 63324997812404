import { computed, watchEffect } from 'vue';

import store from '@/store';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

import { useAppGlobalEntities } from './useAppGlobalEntities';

const useInitialLoadingState = () => {
  const { isGlobalEntitiesLoading } = useAppGlobalEntities();

  const isInitialLoadingInProgress = computed(() => {
    if (checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants)) {
      return store?.getters['app/isIntermediate'] || store.state.app.isAppStateLoading;
    }
    return store?.getters['app/isIntermediate'] || isGlobalEntitiesLoading.value;
  });

  let loadingResolver: null | ((value?: unknown) => void) = null;

  const onLoadingFinished = new Promise((resolve) => {
    loadingResolver = resolve;
  });

  // TODO тут летит ошибка ReferenceError: Cannot access 'stopWatcher' before initialization
  // Временный фикс это замена const на var, нужно разобраться для чего используется stopWatcher и исправить нормально
  // eslint-disable-next-line no-var
  var stopWatcher = watchEffect(() => {
    if (isInitialLoadingInProgress.value) return;
    if (loadingResolver) {
      loadingResolver();
    }
    if (typeof stopWatcher === 'function') {
      stopWatcher();
    }
  });

  return { onLoadingFinished };
};

export default useInitialLoadingState;
