import {
  RouteLocationNormalized,
  useRoute,
  useRouter,
} from 'vue-router';
import { computed, ComputedRef } from 'vue';

import { ETransitionMethod } from '@/constants';
import { showErrorNotification } from '@/utils';
import useStore from '@/store/useStore';
import { SET_LOADING_STATE } from '@/store/modules/app/mutation-types';
import { EExperimentalFeatures } from '@/domains/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';

import useWebsocketController from '../useWebsocketController';
import { useAppGeneralNavigation } from './useAppGeneralNavigation';
import { useAppGlobalEntities } from './useAppGlobalEntities';
import useAppHeap from './useAppHeap';
import useTargetTenant from './useTargetTenant';

export const useAppTransitions = (isAuthorizedByUserAccount: ComputedRef<boolean>) => {
  const store = useStore();
  const route = useRoute();
  const router = useRouter();

  const {
    // TODO: выпилить isNavigationAfterSignInInProgress вместе с FF piniaMigrationStoreTenants
    isNavigationAfterSignInInProgress,
    navigateAfterSignIn,
    navigateAfterLogout,
    navigateAfterFailedAuthentication,
    navigateToMaintenance,
  } = useAppGeneralNavigation();

  const { identify } = useAppHeap();
  const { openWsConnectionForCurrentTenant } = useWebsocketController();
  const { fetchGlobalEntities } = useAppGlobalEntities();
  const { processTargetTenant } = useTargetTenant();

  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  const fetchCommonData = () => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      store.commit(`app/${SET_LOADING_STATE}`, true);
    } else {
      isNavigationAfterSignInInProgress.value = true;
    }

    identify();
    openWsConnectionForCurrentTenant();
    return fetchGlobalEntities()
      .then(navigateAfterSignIn)
      .catch(showErrorNotification)
      .finally(() => {
        /** Роутер не успевает резолвится после получения данных по юзеру и тенанту и до перехода на новый роут,
         * поэтому нужно дождаться готовности будущего роута и только после этого отключить лоадер.
         * Лоадер в свою очередь зависит от переменной isNavigationAfterSignInInProgress (после выпила FF piniaMigrationStoreTenants - store.state.app.isAppStateLoading)
         */
        router.beforeResolve((to: RouteLocationNormalized) => {
          /** Перед тем, как попасть на главную страницу тенанта(заказы ГВ, лента заказов ГП, главная корп аккаунта),
           * метод redirectToInitialRoute редиректит нас на главную('/' === 'summaryView'),
           * затем мы получаем currentTenantInfo и внутри пришедших данных проверяем лицензии/подписки тенанта
           * и только после этого мы редиректим юзера на "его главную страницу"(заказы ГВ или лента заказов ГП).
           * Поэтому лоадер нужно отключать только после того, как все проверки выполнены иначе мы получаем моргалку с дровером добавления новой организации. */
          if (to.name !== 'summaryView' || to.path !== '/') {
            if (isPiniaMigrationStoreTenantsAvailable.value) {
              store.commit(`app/${SET_LOADING_STATE}`, false);
            } else {
              isNavigationAfterSignInInProgress.value = false;
            }
          }
        });
      });
  };

  const stateTransitions: Record<ETransitionMethod, () => unknown> = {
    [ETransitionMethod.onCheck]: () => {},
    [ETransitionMethod.onFailedAuthentication]: async () => {
      await router.isReady().catch(() => {});
      navigateAfterFailedAuthentication();
    },
    [ETransitionMethod.onLogin]: () => {
    /**
     * onLogin вызывается после успешного входа через форму входа
     */
      if (isAuthorizedByUserAccount.value) {
        return fetchCommonData();
      }
      return Promise.resolve();
    },
    [ETransitionMethod.onSuccessfulAuthentication]: async () => {
    /**
     * onSuccessfulAuthentication вызывается после старта приложения после успешной проверки
     * на то, что текущий пользователь авторизован
     */
      if (isAuthorizedByUserAccount.value) {
        if (isPiniaMigrationStoreTenantsAvailable.value) {
          store.commit(`app/${SET_LOADING_STATE}`, true);
        } else {
          isNavigationAfterSignInInProgress.value = true;
        }
        identify();
        try {
          await router.isReady().catch(() => {});
          /**
         * здесь небольшой костылик с проверкой и вызовом processTargetTenant.
         * Дело в том, что если у роута есть permissions, значит он был создан с помощью утилиты buildProtectedRoute,
         * а эта утилита в себе вызывает в самом начале функцию processTargetTenant.
         * А если у роута не указаны permissions, то мы вручную должны вызвать processTargetTenant для
         * проверки target_tenant в query.
         */
          if (!route.meta.permissions) {
            await processTargetTenant();
          }
          await navigateAfterSignIn();
          await openWsConnectionForCurrentTenant();
          return Promise.resolve();
        } catch (error: unknown) {
          return Promise.reject(error);
        } finally {
          if (isPiniaMigrationStoreTenantsAvailable.value) {
            store.commit(`app/${SET_LOADING_STATE}`, false);
          } else {
            isNavigationAfterSignInInProgress.value = false;
          }
        }
      }
      return Promise.resolve();
    },
    [ETransitionMethod.onLogout]: () => navigateAfterLogout(),
    [ETransitionMethod.onMaintenance]: () => navigateToMaintenance(),
    [ETransitionMethod.onInteractionLock]: () => {},
    [ETransitionMethod.onInteractionUnlock]: () => {
      /**
       * onInteractionUnlock вызывается после успешной разблокировки системой
       */
      if (isAuthorizedByUserAccount.value) {
        return fetchCommonData();
      }
      return Promise.resolve();
    },
  };

  return {
    stateTransitions,
    isNavigationAfterSignInInProgress,
  };
};
