import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { useWalletStore } from '@/stores/wallet';

export const useView = () => {
  const router = useRouter();
  const walletStore = useWalletStore();

  const missingAmount = computed(() => walletStore.insufficientFunds?.missingAmount);

  const { setInsufficientFunds } = walletStore;

  const handleRedirectButtonClick = () => {
    setInsufficientFunds(null);
    router.push('/tenant/finance');
  };

  return {
    missingAmount,
    handleRedirectButtonClick,
    setInsufficientFunds,
  };
};
