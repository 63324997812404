import { computed } from 'vue';

import useViewState from '@/composables/useViewState';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import redirectToInitialRoute from '@/domains/redirectToInitialRoute';
import useStore from '@/store/useStore';
import { useTenantsStore } from '@/stores/tenants';

export const useContinue = (emit: (event: 'close', ...args: unknown[]) => void) => {
  const store = useStore();
  const tenantsStore = useTenantsStore();
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  const { isLoading, setViewStateAs } = useViewState();

  const onContinue = () => {
    setViewStateAs.loading();
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      Promise.all([tenantsStore.loadTenants(), tenantsStore.loadTenantInfo()])
        .then(() => {
          emit('close');
          redirectToInitialRoute();
        })
        .catch(() => {})
        .finally(setViewStateAs.done);
      // TODO: удалить вместе с FF piniaMigrationStoreTenants
    } else {
      Promise.all([
        store.dispatch('tenants/fetchCurrentTenantInfo'),
        store.dispatch('tenants/fetchTenants'),
      ])
        .then(() => {
          emit('close');
          redirectToInitialRoute();
        })
        .catch(() => {})
        .finally(setViewStateAs.done);
    }
  };

  return {
    isLoading,

    onContinue,
  };
};
