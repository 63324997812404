import store from '@/store';
import { useTenantsStore } from '@/stores/tenants';

import { EExperimentalFeatures } from './constants';

export const checkFeatureIsEnabled = (
  featureName: EExperimentalFeatures,
): boolean => checkGlobalFeatureIsEnabled(featureName) || checkFeatureForTenantIsEnabled(featureName);

const checkGlobalFeatureIsEnabled = (featureName: EExperimentalFeatures): boolean => {
  const globalExperimentalFeatures = store.state.app.appSettings?.experimentalFeatures;
  return !!globalExperimentalFeatures?.[featureName];
};

const checkFeatureForTenantIsEnabled = (featureName: EExperimentalFeatures): boolean => {
  // TODO: удалить вместе с FF piniaMigrationStoreTenants
  const doSearchInNewTenantsStore = checkGlobalFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants);

  const experimentalFeatures = doSearchInNewTenantsStore
    ? useTenantsStore().currentTenantDetails?.experimentalFeatures
    : store.state.tenants.currentTenantInfo?.experimentalFeatures;
  return !!experimentalFeatures?.[featureName];
};
