import { defineStore } from 'pinia';
import {
  computed,
  ref,
} from 'vue';

import type { TDigitalQueueGates } from '@/contexts/contractorsContext/domain/types';
import { TDateAppFormat, TTableColumn } from '@/types';
import {
  EDigitalQueueDatePickerContext,
  EDigitalQueuePageTab,
  EDigitalQueuePageType,
  EDigitalQueuePageView,
} from '@/contexts/digitalQueuesContext/domain/constants';
import { TDigitalQueuePermissions } from '@/contexts/digitalQueuesContext/domain/types';
import { DATE_FORMAT, ETimeUnit } from '@/constants';
import {
  formatDate,
  getDateByStartOfPart,
  getWeekOfYearFromDate,
  isDateSame,
} from '@/utils/dateUtils';

import {
  Nullable,
  TColumnsSettings,
  TDigitalQueueSettingsStore,
  TGateSettings,
} from './types';

export const useDigitalQueueSettingsStore = defineStore(
  'TMS_DIGITAL_QUEUE_SETTINGS',
  (): TDigitalQueueSettingsStore => {
    const weekNumber = ref<string>('');
    const startDateOfCurrentWeek = ref<string>(''); // Дата начала выбранной недели
    const currentDigitalQueueId = ref<string>('');
    const openedGates = ref<TGateSettings[]>([]);
    const currentGateId = ref<string>('');
    const currentGates = ref<TDigitalQueueGates[]>([]);
    const selectedGatesForDigitalQueue = ref<Record<string, string[]>>({});
    // Удалить после переезда в новый контекст digitalQueuesContext
    const pageType = ref<EDigitalQueuePageType>(EDigitalQueuePageType.booking);
    // Удалить после переезда в новый контекст digitalQueuesContext
    const viewMode = ref<EDigitalQueuePageView>(EDigitalQueuePageView.timeline);
    const permissions = ref<TDigitalQueuePermissions | null>(null);
    const columnsSettings = ref<TColumnsSettings[]>([]);
    /** Табы "Настройка таймслотов" и "Редактирование шаблона" */
    const currentPageTab = ref<EDigitalQueuePageTab>(EDigitalQueuePageTab.timeSlots);
    /** Выбранная пользователем дата во вкладке "Таймслоты и бронирования" */
    const bookingDate = ref('');
    const bookingRangeDatesForDigitalQueue = ref<Record<string, { firstDate: string, secondDate: string }>>({});
    const hasActualWeeklyScheduleLayout = ref(false);
    const currentDigitalQueueTimeZone = ref('');

    /** Возвращает выбранные ID всех выбранных ворот для текущей ЦО */
    const getSelectedGatesForDigitalQueue = computed(
      () => selectedGatesForDigitalQueue.value[currentDigitalQueueId.value] || []);

    /** Возвращает выбранные даты в датапикере для текущей ЦО */
    const getBookingRangeDatesForDigitalQueue = computed(
      () => bookingRangeDatesForDigitalQueue.value[currentDigitalQueueId.value] || []);

    const setDigitalQueueId = (id: Nullable<string>) => {
      currentDigitalQueueId.value = id || '';
    };

    const setDigitalQueueTimeZone = (timeZone: string) => {
      currentDigitalQueueTimeZone.value = timeZone;
    };

    const setWeekNumber = (newWeekNumber: string) => {
      weekNumber.value = newWeekNumber;
    };

    const setStartDateOfCurrentWeek = (date: string) => {
      startDateOfCurrentWeek.value = date;
    };

    const setOpenedGates = (gates: TGateSettings[]) => {
      openedGates.value = gates;
    };

    const setCurrentGates = (gates: TDigitalQueueGates[]) => {
      currentGates.value = gates;
    };

    const setCurrentGateId = (id: string) => {
      currentGateId.value = id;
    };

    const setPageType = (newPageType: EDigitalQueuePageType) => {
      pageType.value = newPageType;
    };

    const setViewMode = (newViewMode: EDigitalQueuePageView) => {
      viewMode.value = newViewMode;
    };

    const setViewPageType = (newPageTab: EDigitalQueuePageTab) => {
      currentPageTab.value = newPageTab;
    };

    const setGateSlotDuration = (payload: TGateSettings) => {
      const currentOpenedGate = openedGates.value.find((gate: TGateSettings) => payload.id === gate.id);
      if (currentOpenedGate) {
        currentOpenedGate.slotDuration = payload.slotDuration;
      } else {
        setOpenedGates([...openedGates.value, payload]);
        openedGates.value.find((gate: TGateSettings) => payload.id === gate.id)!.slotDuration = payload.slotDuration;
      }
    };

    const setPermissions = (payload: TDigitalQueuePermissions | null) => {
      permissions.value = payload;
    };

    const setColumnsSettings = (digitalQueueId: string, payload: TTableColumn[]) => {
      const currentDigitalQueueIndex = columnsSettings.value
        .findIndex((item: TColumnsSettings) => item.id === digitalQueueId);

      // Если текущий конфиг колонок для конкретной ЦО существует, то обновляем его на новый
      if (currentDigitalQueueIndex !== -1) {
        columnsSettings.value[currentDigitalQueueIndex].columnsSettings = payload;
      } else {
        // Если не существует, то создаем новый
        columnsSettings.value.push({
          id: digitalQueueId,
          columnsSettings: payload,
        });
      }
    };

    const setBookingDate = (date: typeof DATE_FORMAT.UTCformat) => {
      bookingDate.value = date;
    };

    /* Синхронизирует дату во всех дейтпикерах ЦО */
    const setDigitalQueueGlobalDate = (
      dates: TDateAppFormat | [TDateAppFormat, TDateAppFormat],
      datePickerContext: EDigitalQueueDatePickerContext,
    ) => {
      const getMondayOfCurrentWeek = (date: TDateAppFormat) => formatDate(
        getDateByStartOfPart(date, ETimeUnit.week),
        { outputFormat: DATE_FORMAT.fullDate },
      );

      const isArrayOfDates = Array.isArray(dates);
      // Если менялся дейтпикер таблицы бронирований
      if (isArrayOfDates && datePickerContext === EDigitalQueueDatePickerContext.table) {
        const [firstDate, secondDate] = dates;

        // Если начало и конец диапазона дат одинаковый:
        if (isDateSame({
          firstDate,
          secondDate,
        })) {
          // Сетим дату в дейтпикер таймлайна
          setBookingDate(formatDate(firstDate, { outputFormat: DATE_FORMAT.UTCformat }));
          // Сетим дату в дейтпикер расписания, если оно присутствует
          if (hasActualWeeklyScheduleLayout.value) {
            setWeekNumber(getWeekOfYearFromDate(firstDate) as string);
          }
          // Сетим понедельник текущей недели
          setStartDateOfCurrentWeek(getMondayOfCurrentWeek(firstDate));
        }

        // Сетим дату в дейтпикер таблицы
        setBookingRangeDate([firstDate, secondDate]);

        return;
      }

      // Если менялся дейтпикер таймлайна или расписания
      if (
        !isArrayOfDates
        && [
          EDigitalQueueDatePickerContext.timeline,
          EDigitalQueueDatePickerContext.schedule,
        ].includes(datePickerContext)
      ) {
        const weekNumber = getWeekOfYearFromDate(dates) as string;
        const bookingDate = formatDate(dates, { outputFormat: DATE_FORMAT.UTCformat });

        // Сетим дату в дейтпикер таймлайна
        setBookingDate(bookingDate);
        // Сетим дату в дейтпикер расписания, если оно присутствует
        if (hasActualWeeklyScheduleLayout.value) {
          setWeekNumber(weekNumber);
        }
        // Сетим дату в дейтпикер таблицы
        setBookingRangeDate([dates, dates]);
        // Сетим понедельник текущей недели
        setStartDateOfCurrentWeek(getMondayOfCurrentWeek(dates));
      }
    };

    const setBookingRangeDate = (dates: [TDateAppFormat, TDateAppFormat]) => {
      const [firstDate, secondDate] = dates;

      bookingRangeDatesForDigitalQueue.value[currentDigitalQueueId.value] = {
        firstDate: formatDate(firstDate, { outputFormat: DATE_FORMAT.UTCformat }),
        secondDate: formatDate(secondDate, { outputFormat: DATE_FORMAT.UTCformat }),
      };
    };

    const setSelectedGateIds = (ids: string[]) => {
      selectedGatesForDigitalQueue.value[currentDigitalQueueId.value] = ids;
    };

    const setActualWeeklyScheduleLayoutState = (value: boolean) => {
      hasActualWeeklyScheduleLayout.value = value;
    };

    const $reset = () => {
      currentDigitalQueueId.value = '';
      openedGates.value = [];
      currentGates.value = [];
      permissions.value = null;
      currentGateId.value = '';
      bookingDate.value = '';
      currentDigitalQueueTimeZone.value = '';
      bookingRangeDatesForDigitalQueue.value = {};
    };

    return {
      weekNumber,
      currentDigitalQueueId,
      currentDigitalQueueTimeZone,
      currentGateId,
      openedGates,
      currentGates,
      pageType,
      viewMode,
      permissions,
      columnsSettings,
      currentPageTab,
      startDateOfCurrentWeek,
      bookingDate,
      bookingRangeDatesForDigitalQueue,
      selectedGatesForDigitalQueue,
      hasActualWeeklyScheduleLayout,

      getSelectedGatesForDigitalQueue,
      getBookingRangeDatesForDigitalQueue,

      setWeekNumber,
      setDigitalQueueId,
      setDigitalQueueTimeZone,
      setOpenedGates,
      setCurrentGates,
      setCurrentGateId,
      setPageType,
      setViewMode,
      setGateSlotDuration,
      setPermissions,
      setColumnsSettings,
      setViewPageType,
      setStartDateOfCurrentWeek,
      setBookingDate,
      setBookingRangeDate,
      setSelectedGateIds,
      setDigitalQueueGlobalDate,
      setActualWeeklyScheduleLayoutState,

      $reset,
    };
  },
  { persist: true },
);
