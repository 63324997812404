import { computed } from 'vue';

import useExpirationTimer from '@/composables/useExpirationTimer';
/** TODO: удалить вместе с FF piniaMigrationStoreTenants */
import { useUserStore } from '@/stores';
import { useUserStore as useUserStoreNew } from '@/stores/user';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { useWalletStore } from '@/stores/wallet';

export const useView = () => {
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  const isBillingWalletAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.billingWallet));

  /** TODO: заменить useUserStore > useUserStore() при выпиле FF piniaMigrationStoreTenants */
  const userStore = isPiniaMigrationStoreTenantsAvailable.value
    ? useUserStoreNew()
    : useUserStore;
  const user = computed(() => userStore.user);
  const isOTP = computed(() => user.value?.oneTimePassword);
  const passwordExpiresAt = computed(() => user.value?.passwordExpiresAt); // Дата валидности пароля
  const lockedSince = computed(() => user.value?.lockedSince); // Дата блокировки аккаунта

  const walletStore = useWalletStore();

  const insufficientFunds = computed(() => walletStore.insufficientFunds);

  const isBillingInsufficientFundsModalVisible = computed(
    () => !!insufficientFunds.value && isBillingWalletAvailable.value,
  );

  // Таймер истечения срока валидности пароля
  // TODO: А что это за таймер и зачем он нужен? Это самый простой способ проверить, не истёк ли пароль?
  const passwordExpirationTimer = useExpirationTimer({
    expirationDateRef: passwordExpiresAt,
    labelOnFinish: '',
    suffix: '',
  }).expirationValue;

  return {
    user,
    passwordExpirationTimer,
    passwordExpiresAt,
    isOTP,
    lockedSince,
    isBillingInsufficientFundsModalVisible,
  };
};
