/** TODO: удалить вместе с FF piniaMigrationStoreTenants */
import { computed } from 'vue';

import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { useUserStore } from '@/stores';
import { useUserStore as useUserStoreNew } from '@/stores/user';

export default function useAppHeap() {
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  /** TODO: заменить useUserStore > useUserStore() при выпиле FF piniaMigrationStoreTenants */
  const userStore = isPiniaMigrationStoreTenantsAvailable.value
    ? useUserStoreNew()
    : useUserStore;

  const identify = () => {
    if (window.heap) {
      const user = userStore.user?.email || userStore.user?.id;
      if (user) {
        window.heap.identify(user);
      }
    }
  };

  return { identify };
}
