<template>
  <section class="auth-layout">
    <slot />
  </section>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({ name: 'AuthLayout' });
</script>

<style lang="scss" src="./styles.scss" />
