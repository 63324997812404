import { useRoute } from 'vue-router';
import { computed } from 'vue';

import useStore from '@/store/useStore';
import { EExperimentalFeatures, EExternalAccountProviders } from '@/domains/constants';
import { TExternalAccount } from '@/contexts/accountingContext/domain/types';
/** TODO: удалить вместе с FF piniaMigrationStoreTenants */
import { useUserStore } from '@/stores';
import { useUserStore as useUserStoreNew } from '@/stores/user';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';

import { EIntegrationEvent } from '../domain/constants';

export const useSberButtonVisible = (integrationEvent: EIntegrationEvent) => {
  const route = useRoute();
  const store = useStore();
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  /** TODO: заменить useUserStore > useUserStore() при выпиле FF piniaMigrationStoreTenants */
  const userStore = isPiniaMigrationStoreTenantsAvailable.value
    ? useUserStoreNew()
    : useUserStore;

  const isSberBusinessEnabled = !!store?.state.app.appSettings?.settings?.sberBusinessEnabled || false;

  const hasSberAccount = computed(() => !!userStore.user && userStore.user?.externalAccounts
    .findIndex((item: TExternalAccount) => item.provider === EExternalAccountProviders.sber_business) !== -1);

  const isSberButtonVisible = computed(() => {
    if (integrationEvent !== EIntegrationEvent.linking) {
      const isSocialAuth = route.query.socialAuth === 'sber';
      return isSberBusinessEnabled && !isSocialAuth;
    }
    return isSberBusinessEnabled && !hasSberAccount.value;
  });

  return { isSberButtonVisible };
};
