export const ROUTE_NAME = { extraServices: 'extra_services' };

export enum EExtraServicesContext {
  forOrder = 'for_order',
  forTransitPoint = 'for_transit_point',
  forOrderAndTransitPoint = 'for_order_and_transit_point',
}

export enum EExtraServicesUnit {
  perService = 'per_service',
  perHour = 'per_hour',
  perKm = 'per_km',
}

export const DEFAULT_ADD_EXTRA_SERVICE_FORM_OPTION = {
  value: '',
  label: '',
  descriptionRequired: false,
  countRequired: false,
  unit: null,
  identifier: null,
};

export enum EExtraServicesApproval {
  pending = 'pending',
  inRevision = 'in_revision',
  approved = 'approved',
  rejected = 'rejected',
  revoked = 'revoked',
}

export enum EExtraServicesCreatableBy {
  customer = 'customer',
  executor = 'executor',
  customerOrExecutor = 'customer_or_executor',
}

export enum EExtraServicesUsageContext {
  order = 'order',
  executionRequest = 'executionRequest',
  run = 'run',
  priceDrawer = 'priceDrawer',
}

export const TRANSIT_POINT_ID_FIELD = {
  [EExtraServicesUsageContext.order]: 'orderPointId',
  [EExtraServicesUsageContext.priceDrawer]: 'orderPointId',
  [EExtraServicesUsageContext.executionRequest]: 'executionRequestPointId',
  [EExtraServicesUsageContext.run]: 'runPointId',
};
