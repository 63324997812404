import { computed, watch } from 'vue';

import store from '@/store';
import {
  disconnectConsumer,
  openWsConnection,
  reconnectWithNewUrl,
} from '@/websocket/controller';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { useTenantsStore } from '@/stores/tenants';

const useWebsocketController = () => {
  const tenantsStore = useTenantsStore();
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  const currentTenant = computed(() => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      return tenantsStore.currentTenant;
    }
    return store.state.tenants.currentTenant;
  });

  watch(() => currentTenant.value?.id, (newValue?: string, oldValue?: string) => {
    if (newValue && (newValue !== oldValue)) {
      reconnectWithNewUrl();
    }
  });

  return {
    disconnectConsumer,
    openWsConnectionForCurrentTenant: openWsConnection,
  };
};

export default useWebsocketController;
