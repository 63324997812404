import qs from 'qs';
import { computed } from 'vue';

import store from '@/store';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { useTenantsStore } from '@/stores/tenants';
import { TTenant } from '@/types';

const removeTargetTenantFromQuery = () => {
  /**
   * здесь пришлось напрямую обращаться к window.location, потому что в момент вызова этой функции роутер
   * и конкретный роут ещё не существуют и не имеют path и query
   */
  const { target_tenant: targetTenantId, ...rest } = qs.parse(window.location.search, { ignoreQueryPrefix: true });
  const restStringified = qs.stringify(rest);
  const resultPath = `${window.location.pathname}?${restStringified}` as unknown as Location;

  /**
   * удаляем target_tenant из query, перезагружаем приложение через грубый window.location,
   * чтобы все обработчики и роуты корректно инициализировались изначально
   */
  window.location = resultPath;
};

/**
 * В приложении есть возможность сменить текущего тенанта, указав в query строке параметр target_tenant
 * с корректным айдишником тенанта, на который нужно переключиться. Данный функционал полезен при
 * переходе из писем по ссылке в TMS.
 * Если в строке есть target_tenant, то он удаляется.
 * Если в хранилище есть информация о том тенанте, переход на который осуществляется, то вызываем метод selectTenant в тенант сторе.
 */
const useTargetTenant = () => {
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  /**
   * Эта функция должна вызываться почти в самом начале старта приложения, сразу после проверки авторизации,
   * чтобы пользователь сразу получил нужного тенанта.
   */
  const processTargetTenant = () => {
    let shouldReplaceQuery = false;
    let promise = Promise.resolve();
    const { target_tenant: targetTenantId } = qs.parse(window.location.search, { ignoreQueryPrefix: true });

    if (targetTenantId) {
      if (isPiniaMigrationStoreTenantsAvailable.value) {
        const { userTenants, currentTenant, selectTenant } = useTenantsStore();
        shouldReplaceQuery = true;
        if (userTenants && currentTenant?.id !== targetTenantId) {
          const targetTenant = userTenants.find((tenant: TTenant) => tenant.id === targetTenantId);
          if (targetTenant) {
            promise = Promise.resolve(selectTenant(targetTenant));
          }
        }
        // TODO: удалить вместе с FF piniaMigrationStoreTenants
      } else {
        const { userTenants, currentTenant } = store.state.tenants;
        shouldReplaceQuery = true;

        if (currentTenant?.id !== targetTenantId) {
          const targetTenant = userTenants.find((tenantFromStore) => tenantFromStore.id === targetTenantId);
          if (targetTenant) {
            promise = store.dispatch('tenants/selectTenant', { tenant: targetTenant });
          }
        }
      }
    }

    if (shouldReplaceQuery) {
      removeTargetTenantFromQuery();
    }

    return promise;
  };

  return { processTargetTenant };
};

export default useTargetTenant;
