import {
  Slots,
  VNode,
  Text,
  Comment,
  Fragment,
} from 'vue';

// TODO: добавить generic для типа slots
type THasSlotContentArguments = {
  slots: Readonly<Slots>,
  slotName: string,
  /**
   * Объект с пропсами scoped слота (слот, в который передаются какие-то пропсы).
   * Нужно передавать всегда, когда хотим использовать hasSlotContent для scoped слота,
   * иначе функция получения виртуальной ноды слота (т.е. `slots[slotName]`) увидит,
   * что у слота должны быть пропсы, попытается их взять, не найдет их и упадет с ошибкой
   * */
  slotProps?: Record<PropertyKey, unknown>,
};

export const hasSlotContent = ({
  slots,
  slotName,
  slotProps,
}: THasSlotContentArguments) => {
  if (!slots || typeof slots[slotName] !== 'function') {
    return false;
  }

  const preparedSlotProps = slotProps ? [slotProps] : [];
  const slotContent = slots[slotName](...preparedSlotProps);
  return Array.isArray(slotContent)
    && slotContent.length > 0
    && slotContent.some((vnode: VNode) => {
      if (vnode.type === Comment) {
        return false;
      }
      if (vnode.type === Text) {
        return typeof vnode.children === 'string' && vnode.children?.trim().length > 0;
      }
      if (vnode.type === Fragment || vnode.type === 'template') {
        return !!vnode.children?.length;
      }
      return true;
    });
};
