import { computed, watch } from 'vue';

import useStore from '@/store/useStore';
import useWebsocketChannel from '@/composables/useWebsocketChannel';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { useTenantsStore } from '@/stores/tenants';

import useGlobalEventBus from '../useGlobalEventBus';

// TenantImportsChannel - общий канал для импорта excel файлов разных сущностей
const useImportWsUtils = () => {
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  const store = useStore();
  const tenantsStore = useTenantsStore();

  const { emit: emitToBus } = useGlobalEventBus();

  const handleNewMessage = (message: any) => {
    if (!message) return;
    emitToBus('getImportsChannelPayload', message.payload);
  };

  const { connect, closeConnection } = useWebsocketChannel({
    channelName: 'TenantImportsChannel',
    onMessage: handleNewMessage,
  });

  const connectToTenantImportsChannel = () => {
    connect();
  };

  const disconnectFromTenantImportsChannel = () => {
    closeConnection();
  };

  const reconnectTenantImportsChannel = () => {
    disconnectFromTenantImportsChannel();
    connectToTenantImportsChannel();
  };

  if (isPiniaMigrationStoreTenantsAvailable.value) {
    watch(() => tenantsStore.currentTenant?.id, () => {
      if (!tenantsStore.currentTenant?.id) return;
      reconnectTenantImportsChannel();
    });
  } else {
    watch(() => store.state.tenants.currentTenant?.id, () => {
      if (!store.state.tenants.currentTenant?.id) return;
      reconnectTenantImportsChannel();
    });
  }
};

export default useImportWsUtils;
