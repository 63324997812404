import { computed } from 'vue';

import store from '@/store';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { useTenantsStore } from '@/stores/tenants';

const isPiniaMigrationStoreTenantsAvailable = computed(
  () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

const isTenantVerified = () => (isPiniaMigrationStoreTenantsAvailable.value
  ? !!useTenantsStore().currentTenantDetails?.verifiedAt
  : !!store.state.tenants.currentTenantInfo?.verifiedAt);

export default isTenantVerified;
