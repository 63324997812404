<template>
  <ControlWrapper
    class="number-input-range"
    :size="size"
    :addonAfter="addonAfter"
    :isInvalid="isInvalid"
    :disabled="disabled"
    @blur="$emit('blur', rangeValue)"
  >
    <template #default="{ onFocus, onBlur }">
      <NumberInput
        v-model:value="rangeValue.min"
        class="number-input-range__input"
        :disabled="disabled"
        :placeholder="minValuePlaceholder"
        :readonly="isMinValueReadonly"
        :size="size"
        :hasBorder="false"
        @focus="onFocus"
        @blur="onBlur"
      />
    </template>
    <template #divider>
      <RangeSvg class="number-input-range__range-icon" />
    </template>
    <template #additional="{ onFocus, onBlur }">
      <NumberInput
        v-model:value="rangeValue.max"
        :disabled="disabled"
        :placeholder="maxValuePlaceholder"
        :readonly="isMaxValueReadonly"
        :size="size"
        :hasBorder="false"
        @focus="onFocus"
        @blur="onBlur"
      />
    </template>
  </ControlWrapper>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  reactive,
  watch,
} from 'vue';

import RangeSvg from '@/assets/svg/16x16/range.svg';

import ControlWrapper from '../ControlWrapper/index.vue';
import NumberInput from '../NumberInput/index.vue';
import { ESize } from '../types';
import { TNumberInputRangeValue } from '../types/types';

export default defineComponent({
  name: 'NumberInputRange',
  components: {
    ControlWrapper,
    NumberInput,
    RangeSvg,
  },
  props: {
    addonAfter: {
      type: String,
      required: true,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    minValuePlaceholder: {
      type: String,
      default: '',
    },
    isMinValueReadonly: {
      type: Boolean,
      default: false,
    },
    maxValuePlaceholder: {
      type: String,
      default: '',
    },
    isMaxValueReadonly: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.medium,
    },
    value: {
      type: [Object, null] as PropType<TNumberInputRangeValue | null>,
      default: null,
    },
    handle: {
      type: Function as PropType<(_value: TNumberInputRangeValue) => void>,
      default: () => {},
    },
  },
  emits: ['change', 'update:value', 'blur'],
  setup(props, { emit }) {
    const rangeValue = reactive({
      min: props.value?.min || null,
      max: props.value?.max || null,
    });

    watch(rangeValue, () => {
      emit('change', rangeValue);
      emit('update:value', rangeValue);
      props.handle(rangeValue);
    });

    watch(() => props.value, (newValue: TNumberInputRangeValue | null) => {
      rangeValue.min = newValue?.min || null;
      rangeValue.max = newValue?.max || null;
    });

    return { rangeValue };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
