import {
  DROPDOWN_MARGIN,
  DROPDOWN_MARGIN_HORIZONTAL,
  EPopoverPlacement,
  VIEWPORT_PADDING,
} from '@/ui/types/constants';
import {
  isBoolean,
  isNumber,
  joinString,
} from '@/utils';

import { getDropdownListHeight } from './getDropdownListHeight';
import { getCSSProperty } from './getCSSProperty';
import { setLeftWithoutMaxWidth } from './setLeftWithoutMaxWidth';
import { setLeftWithMaxWidth } from './setLeftWithMaxWidth';

type TInlineStyle = {
  placement: EPopoverPlacement,
  minHeight?: number | null,
  maxHeight?: number | null,
  targetElementClientRectBottom?: number,
  targetElementClientRectTop?: number,
  targetElementClientRectRight?: number,
  targetElementClientRectLeft?: number,
  targetElementClientRectWidth?: number,
  targetElementSubMenuHeight?: number,
  targetElementSubMenuWidth?: number,
  optionsLength?: number | null,
  dropdownMatchSelectWidth: number | boolean,
  isFixedOnBottomLeft: boolean,
  isFixedOnTopRight: boolean,
};

export const getInlineStyle = ({
  placement,
  minHeight,
  maxHeight,
  targetElementClientRectBottom,
  targetElementClientRectTop,
  targetElementClientRectRight,
  targetElementClientRectLeft,
  targetElementClientRectWidth,
  targetElementSubMenuHeight,
  targetElementSubMenuWidth,
  optionsLength,
  dropdownMatchSelectWidth,
  isFixedOnBottomLeft,
  isFixedOnTopRight,
}: TInlineStyle) => {
  if (!targetElementClientRectTop) return '';

  let clientHeight = targetElementSubMenuHeight || getDropdownListHeight(optionsLength);
  let left = '';
  let top = '';
  let height = '';

  if (maxHeight && targetElementSubMenuHeight && targetElementSubMenuHeight > maxHeight) {
    height = getCSSProperty({
      propertyName: 'height',
      value: maxHeight,
    });
    clientHeight = maxHeight;
  }

  // Помещается ли дропдаун сверху + VIEWPORT_PADDING + DROPDOWN_MARGIN
  const canShowDropdownFitOnTheTop = targetElementClientRectTop > (clientHeight + VIEWPORT_PADDING + DROPDOWN_MARGIN);
  // Помещается ли дропдаун снизу + VIEWPORT_PADDING + DROPDOWN_MARGIN
  const canShowDropdownFitOnTheBottom = targetElementClientRectBottom && document.documentElement.clientHeight - targetElementClientRectBottom < clientHeight + VIEWPORT_PADDING;

  /* Устанавливаем позицию и уменьшаем высоту контента для отображения сверху */
  const setVerticalStylesForTopPlacement = () => {
    height = getCSSProperty({
      propertyName: 'height',
      value: targetElementClientRectTop - VIEWPORT_PADDING - DROPDOWN_MARGIN,
    });
    top = getCSSProperty({
      propertyName: 'top',
      value: VIEWPORT_PADDING,
    });
  };

  /* Позиционирование дропдауна по вертикали для placement === auto */
  const setVerticalStylesForAutoPlacement = () => {
    // Рендерим дропдаун снизу targetElement + отступ DROPDOWN_MARGIN
    top = targetElementClientRectBottom ? getCSSProperty({
      propertyName: 'top',
      value: targetElementClientRectBottom + DROPDOWN_MARGIN,
    }) : '';

    // Если необходимо отрендерить дропдаун сверху справа
    if (isFixedOnTopRight && targetElementClientRectTop) {
      // Помещается ли контент сверху
      if (canShowDropdownFitOnTheTop) {
        top = getCSSProperty({
          propertyName: 'top',
          value: targetElementClientRectTop - DROPDOWN_MARGIN,
        });
      } else {
        setVerticalStylesForTopPlacement();
      }
    }

    // если Dropdown начинает упираться в окно браузера снизу + VIEWPORT_PADDING, то он будет рендериться сверху контрола
    if (targetElementClientRectTop && canShowDropdownFitOnTheBottom) {
      // Помещается ли контент сверху
      if (canShowDropdownFitOnTheTop) {
        const topPosition = targetElementClientRectTop - clientHeight - DROPDOWN_MARGIN;
        top = getCSSProperty({
          propertyName: 'top',
          value: topPosition,
        });
      } else {
        setVerticalStylesForTopPlacement();
      }
    }
  };

  /* Позиционирование по вертикали для placement === bottom */
  const setVerticalStylesForBottomPlacement = () => {
    top = targetElementClientRectBottom ? getCSSProperty({
      propertyName: 'top',
      value: targetElementClientRectBottom + DROPDOWN_MARGIN,
    }) : '';
    // Помещается ли контент снизу
    if (canShowDropdownFitOnTheBottom && targetElementClientRectTop) {
      const heightValue = document.documentElement.clientHeight - targetElementClientRectBottom - VIEWPORT_PADDING - DROPDOWN_MARGIN;
      const value = minHeight && minHeight > heightValue ? minHeight : heightValue;
      height = getCSSProperty({
        propertyName: 'height',
        value,
      });
    }
  };

  /* Позиционирование дропдауна справа для placement === right */
  const setHorizontalStylesForRightPlacement = () => {
    if (!targetElementSubMenuWidth || !targetElementClientRectRight || !targetElementClientRectLeft) return;
    const spaceToRightOfDropdownList = document.documentElement.clientWidth - targetElementClientRectRight - DROPDOWN_MARGIN_HORIZONTAL;
    top = getCSSProperty({
      propertyName: 'top',
      value: targetElementClientRectTop,
    });
    // Если дропдаун помещается справа
    if (spaceToRightOfDropdownList > targetElementSubMenuWidth) {
      left = getCSSProperty({
        propertyName: 'left',
        value: targetElementClientRectRight + DROPDOWN_MARGIN_HORIZONTAL,
      });
      // Если дропдаун не помещается справа, рендерим слева
    } else if (spaceToRightOfDropdownList < targetElementSubMenuWidth) {
      left = getCSSProperty({
        propertyName: 'left',
        value: targetElementClientRectLeft - targetElementSubMenuWidth - DROPDOWN_MARGIN_HORIZONTAL,
      });
      // Если дропдаун не помещается ни справа, ни слева, рендерим снизу (появляется скролл за экран, если снизу места тоже не будет хватать)
    } else {
      top = targetElementClientRectBottom ? getCSSProperty({
        propertyName: 'top',
        value: targetElementClientRectBottom + DROPDOWN_MARGIN,
      }) : '';
    }
  };

  /* Позиционирование дропдауна по горизонтали */
  const setLeftStyles = () => {
    left = targetElementClientRectLeft ? getCSSProperty({
      propertyName: 'left',
      value: targetElementClientRectLeft,
    }) : '';

    if (isBoolean(dropdownMatchSelectWidth) && !dropdownMatchSelectWidth) {
      left = setLeftWithoutMaxWidth({
        isFixedOnTopRight,
        targetElementSubMenuWidth,
        targetElementClientRectRight,
        targetElementClientRectLeft,
        targetElementClientRectWidth,
      });
    } else if (isNumber(dropdownMatchSelectWidth)) {
      left = setLeftWithMaxWidth({
        isFixedOnBottomLeft,
        targetElementClientRectLeft,
        targetElementClientRectRight,
        targetElementClientRectWidth,
        dropdownMatchSelectWidth,
      });
    }
  };

  // Вызов происходит здесь потому что ниже в switch (placement) есть кейсы,
  // где должно быть кастомный рендер по горизонтали, который не входит в условия этой функции
  setLeftStyles();

  switch (placement) {
    case EPopoverPlacement.bottom:
      setVerticalStylesForBottomPlacement();
      break;
    case EPopoverPlacement.right:
      setHorizontalStylesForRightPlacement();
      break;
    default:
      setVerticalStylesForAutoPlacement();
  }

  return joinString([top, left, height]);
};
