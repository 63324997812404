<template>
  <Modal
    :visible="isVisible"
    :title="title"
    :width="width"
    :footer="footer"
    :bodyStyle="bodyStyle"
    :destroyOnClose="destroyOnClose"
    class="custom-modal"
  >
    <template #closeIcon>
      <CloseSvg
        class="custom-modal__close-svg"
        @click="$emit('close')"
      />
    </template>
    <slot name="header" />

    <div class="custom-modal__content">
      <slot />
    </div>

    <slot name="preFooter" />

    <template #footer>
      <slot name="footer" />
    </template>
  </Modal>
</template>

<script lang="ts">
import { Modal } from 'ant-design-vue';
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import CloseSvg from '@/assets/svg/16x16/close.svg';
import { hasSlotContent } from '@/utils/hasSlotContent';

export default defineComponent({
  name: 'CustomModal',
  components: {
    Modal,
    CloseSvg,
  },
  props: {
    isVisible: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    width: {
      type: Number,
      default: 440,
    },
    bodyStyle: {
      type: Object as PropType<Record<string, string>>,
      default: () => ({}),
    },
    destroyOnClose: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['close'],
  setup(_, { slots }) {
    const hasFooterSlot = computed(() => hasSlotContent({
      slots,
      slotName: 'footer',
    }));

    // чтобы полностью скрыть футер, когда ничего не передано в слот, в антовский Modal передаём проп :footer="null"
    const footer = computed(() => (hasFooterSlot.value ? undefined : null));

    return { footer };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
