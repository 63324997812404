import { ROUTE_TYPE } from '@/constants';
import { canBrowseTenantBaseSettingsByRole } from '@/domains/permissions/role/tenantBaseSettings';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import { EExperimentalFeatures } from '@/domains/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { hasCargoOwnerWorkplaceSubscription, hasCarrierWorkplaceSubscription } from '@/domains/permissions/subscription';
import { hasInstanceLicense } from '@/domains/hasInstanceLicense';

import { EAccountingRouteName } from '../domain/constants';

const routes = [
  buildProtectedRoute({
    path: '/sign_up',
    name: EAccountingRouteName.signUp,
    meta: {
      type: ROUTE_TYPE.unauthenticated,
      permissions: [],
      component: () => import('@/views/Infrastructure/SignUpView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/sign_in',
    name: EAccountingRouteName.signIn,
    meta: {
      type: ROUTE_TYPE.unauthenticated,
      permissions: [],
      component: () => import('@/views/Infrastructure/SignInView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/forgot_password',
    name: EAccountingRouteName.forgotPassword,
    meta: {
      type: ROUTE_TYPE.unauthenticated,
      permissions: [],
      component: () => import('@/views/Infrastructure/ForgotPassword/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/reset_passwords',
    name: EAccountingRouteName.resetPassword,
    meta: {
      type: ROUTE_TYPE.unauthenticated,
      permissions: [],
      component: () => import('@/views/Infrastructure/ResetPassword/index.vue'),
    },
  }),
  {
    path: '/account',
    name: EAccountingRouteName.account,
    meta: { type: ROUTE_TYPE.private },
    component: () => import('@/contexts/accountingContext/views/AccountView/index.vue'),
  },
  {
    path: '/tenant',
    name: EAccountingRouteName.tenant,
    component: () => import('@/contexts/accountingContext/views/TenantSettingsView/index.vue'),
    meta: { type: ROUTE_TYPE.private },
    redirect: () => (canBrowseTenantBaseSettingsByRole() ? '/tenant/settings' : '/tenant/integrations'),
    children: [
      buildProtectedRoute({
        path: '/tenant/settings',
        name: EAccountingRouteName.tenantSettings,
        meta: {
          type: ROUTE_TYPE.private,
          component: () => import('@/contexts/accountingContext/views/TenantSettingsView/components/CommonSettings/index.vue'),
          permissions: [canBrowseTenantBaseSettingsByRole],
        },
      }),
      buildProtectedRoute({
        path: '/tenant/finance',
        name: EAccountingRouteName.tenantSettingsFinance,
        meta: {
          type: ROUTE_TYPE.private,
          component: () => import('@/contexts/accountingContext/views/TenantSettingsView/components/TenantSettingsFinance/index.vue'),
          permissions: [
            () => !hasInstanceLicense(),
            () => !hasCargoOwnerWorkplaceSubscription(),
            canBrowseTenantBaseSettingsByRole,
            () => checkFeatureIsEnabled(EExperimentalFeatures.billingWallet),
          ],
        },
      }),
      {
        path: '/tenant/integrations',
        name: EAccountingRouteName.tenantIntegrations,
        component: () => import('@/contexts/accountingContext/views/TenantSettingsView/components/IntegrationsSettings/index.vue'),
      },
      buildProtectedRoute({
        path: '/tenant/additional_features',
        name: EAccountingRouteName.tenantAdditionalFeatures,
        meta: {
          type: ROUTE_TYPE.private,
          component: () => import('@/contexts/accountingContext/views/TenantSettingsView/components/TenantSettingsAdditionalFeatures/index.vue'),
          permissions: [
            hasCarrierWorkplaceSubscription,
            () => checkFeatureIsEnabled(EExperimentalFeatures.reductionBuyNow),
          ],
        },

      }),
    ],
  },
];

export default routes;
