import { computed, ref } from 'vue';
import camelCase from 'lodash.camelcase';

import { ELicenseRole } from '@/contexts/accountingContext/domain/constants';
import useStore from '@/store/useStore';
import { setLicenseRole } from '@/contexts/accountingContext/services';
import { showErrorNotification } from '@/utils';
import redirectToInitialRoute from '@/domains/redirectToInitialRoute';
import useViewState from '@/composables/useViewState';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { useTenantsStore } from '@/stores/tenants';

import { allLicenseRoles } from '../domain/constants';

export const useSelectLicenseRole = (emit: (event: 'close', ...args: unknown[]) => void) => {
  const { isLoading, setViewStateAs } = useViewState();

  const store = useStore();
  const tenantsStore = useTenantsStore();
  const licenseSettings = computed(() => store.state.app.appSettings?.licenseSettings);

  const selectedLicenseRole = ref<ELicenseRole | null>(null);

  const availableLicenseRoles = computed(() => allLicenseRoles.filter((
    role: {
      value: ELicenseRole,
      label: string,
    }) => licenseSettings.value?.[camelCase(role.value)]?.enabled));

  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  const updateTenantsData = () => {
    setViewStateAs.loading();

    if (isPiniaMigrationStoreTenantsAvailable.value) {
      Promise.all([tenantsStore.loadTenants(), tenantsStore.loadTenantInfo()])
        .then(() => {
          emit('close');
          redirectToInitialRoute();
        })
        .catch(() => {})
        .finally(setViewStateAs.done);
      // TODO: удалить вместе с FF piniaMigrationStoreTenants
    } else {
      Promise.all([
        store.dispatch('tenants/fetchCurrentTenantInfo'),
        store.dispatch('tenants/fetchTenants'),
      ])
        .then(() => {
          emit('close');
          redirectToInitialRoute();
        })
        .catch(() => {})
        .finally(setViewStateAs.done);
    }
  };

  const onSubmit = () => {
    if (!selectedLicenseRole.value) return;

    setLicenseRole(selectedLicenseRole.value)
      .then(updateTenantsData)
      .catch(showErrorNotification);
  };

  return {
    isLoading,
    selectedLicenseRole,
    availableLicenseRoles,

    onSubmit,
  };
};
