<template>
  <button
    ref="buttonRef"
    :type="htmlType"
    :class="classes"
    @mousedown="$emit('mousedown', $event)"
    @click="handleClick"
  >
    <slot
      v-if="!loading"
      name="icon"
      class="button__icon"
    />
    <component
      :is="icon"
      v-if="loading"
      class="button__loader"
    />
    <span class="button__title">
      {{ title }}
    </span>
  </button>
</template>
<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
  ref,
} from 'vue';

import LoaderSvgMediumSize from '@/assets/svg/16x16/loader.svg';
import LoaderSvgLargeSize from '@/assets/svg/20x20/loader.svg';
import {
  ESize,
  EButtonType,
  EHtmlType,
} from '@/ui/types';

export default defineComponent({
  name: 'Button',
  components: {
    LoaderSvgMediumSize,
    LoaderSvgLargeSize,
  },
  props: {
    isActive: {
      type: Boolean,
      default: false,
    },
    isInline: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<EButtonType>,
      default: EButtonType.secondary,
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.medium,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    htmlType: {
      type: String as PropType<EHtmlType>,
      default: EHtmlType.button,
    },
  },
  emits: ['click', 'mousedown'],
  setup(props, { slots, expose, emit }) {
    const icon = computed(() => (props.size === ESize.large ? LoaderSvgLargeSize : LoaderSvgMediumSize));
    const buttonRef = ref<HTMLButtonElement>();
    const classes = computed(() => [
      'button',
      { button_loading: props.loading },
      { button_disabled: props.disabled },
      { button_active: props.isActive },
      { button_inline: props.isInline },
      `button_type_${props.type}`,
      `button_size_${props.size}`,
      { 'button__only-loading': props.loading && !slots.icon && props.title },
      { 'button__only-icon': slots.icon && !props.title },
    ]);

    const handleClick = (event: Event) => {
      if (!props.loading && !props.disabled) {
        emit('click', event);
      }
    };

    expose({ button: buttonRef });

    return {
      buttonRef,
      classes,
      icon,

      handleClick,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
