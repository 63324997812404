<template>
  <li
    ref="actionSheetItemRef"
    class="action-sheet-item"
    :class="className"
  >
    <div
      class="action-sheet-item__row-container"
      @click="onClick(action)"
    >
      <div class="action-sheet-item__flex-row">
        <div v-if="icon" class="action-sheet-item__icon-left">
          <component
            :is="icon"
          />
        </div>
        <div
          v-if="label"
          class="action-sheet-item__flex-row"
        >
          <span v-if="canShowFullLabel" class="action-sheet-item__title_full-label">
            {{ label }}
          </span>
          <Tooltip
            v-else-if="label"
            :title="String(label)"
            :placement="EPopoverPlacement.right"
          >
            <span class="action-sheet-item__title">
              {{ label }}
            </span>
          </Tooltip>
        </div>
        <div v-if="isCheckIconVisible" class="action-sheet-item__flex-row action-sheet-item__info">
          <CheckSvg
            class="action-sheet-item__check-icon"
          />
        </div>
        <div v-if="hasBadge || withToggle" class="action-sheet-item__icons-right">
          <Toggle
            v-if="withToggle"
            :size="ESize.small"
            :disabled="disabled"
            @change="onChange"
          />
          <Badge
            v-if="hasBadge"
            :size="ESize.small"
            :color="badge.color"
          >
            <template #icon>
              <component
                :is="badge.icon"
              />
            </template>
            {{ badge.label }}
          </Badge>
        </div>
        <div
          v-if="canShowSubMenu"
          class="action-sheet-item__sub-menu"
        >
          <div class="action-sheet-item__sub-menu-icon">
            <ChevronRight />
          </div>

          <Dropdown
            ref="dropdownRef"
            :visible="isDropdownVisible"
            :targetElement="actionSheetItemRef"
            :optionsLength="optionsLength"
            :isFixedOnTopRight="isFixedOnTopRight"
            :dropdownMatchSelectWidth="dropdownMatchSelectWidth"
          >
            <DropdownItem
              v-for="parentItemOption in parentItemOptions"
              :key="parentItemOption.value"
              :value="parentItemOption.label"
              @click="onClick(parentItemOption.action, parentItemOption.value)"
            />
          </Dropdown>
        </div>
      </div>
      <div
        v-if="subTitle && label"
        class="action-sheet-item__flex-row action-sheet-item__sub-title"
      >
        <span>
          {{ subTitle }}
        </span>
      </div>
    </div>
  </li>
</template>
<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
  ref,
} from 'vue';

import {
  EActionSheetType,
  EPopoverPlacement,
  ESize,
  TActionSheetParentItemOption,
  TBadgeOption,
  TRawActionSheetValue,
} from '@/ui/types';
import CheckSvg from '@/assets/svg/16x16/check.svg';
import ChevronRight from '@/assets/svg/16x16/chevron-right.svg';
import Tooltip from '@/ui/Tooltip/index.vue';
import Badge from '@/ui/Badge/index.vue';
import Toggle from '@/ui/Toggle/index.vue';
import Dropdown from '@/ui/Dropdown/index.vue';
import DropdownItem from '@/ui/Dropdown/components/DropdownItem/index.vue';

export default defineComponent({
  name: 'ActionSheetItem',
  components: {
    DropdownItem,
    Dropdown,
    Tooltip,
    CheckSvg,
    ChevronRight,
    Badge,
    Toggle,
  },
  props: {
    value: {
      type: [String, Number] as PropType<TRawActionSheetValue>,
      required: true,
    },
    label: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    withToggle: {
      type: Boolean,
      default: false,
    },
    subTitle: {
      type: String,
      default: '',
    },
    isFullLabelVisible: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String as PropType<EActionSheetType>,
      default: EActionSheetType.default,
    },
    icon: {
      type: [Object, null] as PropType<SVGElement | null>,
      default: null,
    },
    badge: {
      type: Object as PropType<TBadgeOption>,
      default: () => ({}),
    },
    parentItemOptions: {
      type: Array as PropType<TActionSheetParentItemOption[]>,
      default: () => [],
    },
    action: {
      type: Function as PropType<() => void>,
      default: () => {},
    },
  },
  emits: ['click', 'change'],
  setup(props, { expose, emit }) {
    const dropdownRef = ref<typeof Dropdown | null>(null);
    const actionSheetItemRef = ref<HTMLLIElement | null>(null);
    const isDropdownVisible = ref<boolean>(false);
    const dropdownMatchSelectWidth = ref<boolean | number>(false);
    const toggleValue = ref(false);

    const optionsLength = computed<number | null>(() => props.parentItemOptions.length);
    const className = computed(() => {
      let resultClassName = props.disabled ? 'action-sheet-item_disabled' : '';

      if (props.selected) {
        resultClassName += ' action-sheet-item_selected';
      }

      if (props.type === EActionSheetType.negative) {
        resultClassName += ' action-sheet-item_negative';
      }

      return resultClassName;
    });
    const isFixedOnTopRight = computed(() => !!props.parentItemOptions.length);
    const hasBadge = computed(() => !!Object.keys(props.badge).length);
    const hasItemContent = computed(() => hasBadge.value || props.withToggle || !!props.subTitle);
    const canShowFullLabel = computed(() => props.isFullLabelVisible
      && !hasItemContent.value
      && props.type !== EActionSheetType.parentItem);
    const isCheckIconVisible = computed(() => props.selected && !hasBadge.value && !props.withToggle);
    const canShowSubMenu = computed(() => props.type === EActionSheetType.parentItem
      && !hasBadge.value
      && !props.withToggle,
    );

    const changeSubMenuVisibility = () => {
      if (props.type === EActionSheetType.parentItem) {
        isDropdownVisible.value = !isDropdownVisible.value;
      }
    };

    const onClick = (action: () => void, value?: TRawActionSheetValue) => {
      // мы проверяем, есть ли совпадение по value, чтобы понять, свернуть лишь subMenu или закрыть весь Action Sheet
      const isSubMenu = props.parentItemOptions.some((item: TActionSheetParentItemOption) => item.value === value);
      // есть три клика:
      if (props.type === EActionSheetType.parentItem) {
        if (isDropdownVisible.value && isSubMenu) {
          // клик по элементу списка внутри EActionSheetType.parentItem
          emit('click', action);
        } else {
          // клик по EActionSheetType.parentItem(открыть sub-menu) элементу списка actionSheetItem
          changeSubMenuVisibility();
        }
      } else {
        // клик по EActionSheetType.default(вызвать метод из списка элементов) элементу списка actionSheetItem
        emit('click', action);
      }
    };

    const onChange = () => {
      toggleValue.value = !toggleValue.value;
      emit('change', toggleValue.value, props.value);
    };

    expose({ changeSubMenuVisibility });

    return {
      className,
      canShowFullLabel,
      canShowSubMenu,
      EActionSheetType,
      EPopoverPlacement,
      ESize,
      hasBadge,
      isCheckIconVisible,
      isDropdownVisible,
      actionSheetItemRef,
      isFixedOnTopRight,
      optionsLength,
      dropdownMatchSelectWidth,
      toggleValue,
      dropdownRef,

      onClick,
      onChange,
      changeSubMenuVisibility,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
