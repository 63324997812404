import { computed } from 'vue';

import { EPermissionComparisonFunction } from '@/constants';
import store from '@/store';
import checkPermissionsCommon from '@/domains/permissions/checkPermissionsCommon';
import { TActiveSubscriptionsConditionsFeatures } from '@/types';
import { useTenantsStore } from '@/stores/tenants';

import { checkFeatureIsEnabled } from '../checkFeatureIsEnabled';
import { EExperimentalFeatures } from '../constants';

export type TSubscriptionPermissionItem = TFinalPathsOfObject<TActiveSubscriptionsConditionsFeatures>;

const isPiniaMigrationStoreTenantsAvailable = computed(
  () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

const checkSubscriptionPermissions = (
  subscriptionItems: TSubscriptionPermissionItem[],
  operator: EPermissionComparisonFunction = EPermissionComparisonFunction.AND,
) => {
  const tenantsStore = useTenantsStore();
  const currentTenantFeatures = isPiniaMigrationStoreTenantsAvailable.value
    ? tenantsStore.currentTenant?.activeSubscriptionsConditions?.features
    : store.state.tenants.currentTenant?.activeSubscriptionsConditions?.features;

  return checkPermissionsCommon<TSubscriptionPermissionItem[]>(currentTenantFeatures, subscriptionItems, operator);
};

export default checkSubscriptionPermissions;
