<template>
  <teleport to="body">
    <div
      v-if="visible"
      ref="dropdown"
      v-click-outside="handleClickOutside"
      class="dropdown"
      :class="className"
      aria-labelledby="dropdown"
      tabindex="0"
      :style="dropdownStyles"
      @keydown="$emit('keydown', $event)"
      @focusout="handleBlur"
      @mousedown.stop
    >
      <div
        v-if="withSearch"
        class="dropdown__header"
      >
        <Input
          ref="searchInputRef"
          :value="searchValue"
          :placeholder="tt('shared.search')"
          @input="$emit('search', $event.target.value)"
          @keydown.stop
          @blur="handleBlur"
        />
      </div>
      <div
        v-if="withoutOptions"
        :style="listItemsStyles"
        class="dropdown__content"
      >
        <slot />
      </div>
      <slot v-else-if="isNotFoundVisible" name="notFoundContent">
        <div
          class="dropdown__empty"
          :style="listItemsStyles"
        >
          <div class="dropdown__empty-image" />
          <p class="dropdown__empty-text">
            {{ notFoundContent }}
          </p>
        </div>
      </slot>
      <ul
        v-else
        v-infinite-scroll="handleUpdateList"
        class="dropdown__items-list"
        :style="listItemsStyles"
        role="listbox"
      >
        <slot />
      </ul>
      <div
        v-if="isFooterVisible"
        ref="footerRef"
        class="dropdown__footer"
      >
        <div class="dropdown__footer-control_left">
          <slot name="footerControlLeft" />
        </div>
        <div class="dropdown__footer-control_right">
          <slot name="footerControlRight" />
        </div>
      </div>
    </div>
  </teleport>
</template>
<script lang="ts">

import {
  PropType,
  computed,
  defineComponent,
  ref,
  watch,
  onMounted,
  onUnmounted,
  nextTick,
} from 'vue';

import tt from '@/i18n/utils/translateText';
import { joinString } from '@/utils';
import { hasSlotContent } from '@/utils/hasSlotContent';

import Input from '../Input/index.vue';
import { getInlineStyle } from './utils/getInlineStyle';
import { DROPDOWN_LIST_HEADER, EPopoverPlacement } from '../types/constants';
import { getDropdownWidth } from './utils/getDropdownWidth';

export default defineComponent({
  name: 'Dropdown',
  components: { Input },
  props: {
    placement: {
      type: String as PropType<EPopoverPlacement>,
      default: EPopoverPlacement.auto,
    },
    minHeight: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    maxHeight: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    optionsLength: {
      type: [Number, null] as PropType<number | null>,
      default: null,
    },
    withoutOptions: {
      type: Boolean,
      default: false,
    },
    visible: {
      type: Boolean,
      default: false,
    },
    targetElement: {
      type: [HTMLElement, null] as PropType<HTMLElement | null>,
      default: null,
    },
    className: {
      type: [String, Array] as PropType<string | string[]>,
      default: '',
    },
    dropdownMatchSelectWidth: {
      type: [Number, Boolean] as PropType<number | boolean>,
      default: true,
    },
    notFoundContent: {
      type: String,
      default: '',
    },
    /** зафиксировать Dropdown у левого края Select'а */
    isFixedOnBottomLeft: {
      type: Boolean,
      default: false,
    },
    /** зафиксировать Dropdown сбоку от Control'a */
    isFixedOnTopRight: {
      type: Boolean,
      default: false,
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
    searchValue: {
      type: String,
      default: '',
    },
    withAutoClosing: {
      type: Boolean,
      default: true,
    },
  },
  emits: [
    'keydown',
    'blur',
    'search',
    'updateList',
    'update:visible',
    'visibleChange',
  ],
  setup(props, { slots, expose, emit }) {
    const targetElementClientRect = ref<DOMRect | null>(null);
    const targetElementSubMenu = ref<DOMRect | null>(null);
    const dropdownRef = ref<HTMLDivElement>();
    const searchInputRef = ref<typeof Input | null>(null);
    const footerRef = ref<HTMLDivElement>();
    const dropdownStyles = ref<string>('');

    const isFooterVisible = computed(() => hasSlotContent({
      slots,
      slotName: 'footerControlLeft',
    }) || hasSlotContent({
      slots,
      slotName: 'footerControlRight',
    }));

    const isNotFoundVisible = computed(() => !props.optionsLength);

    const listItemsStyles = computed(() => {
      const stringWithHeight = dropdownStyles.value.split('height: ')[1];
      const dropdownHeight = stringWithHeight?.split('px')[0];

      if (dropdownHeight) {
        // Кейс когда контент дропдауна нужно отобразить сверху и уменьшить по высоте, при этом включен пропс withSearch или есть footer
        const searchHeight = props.withSearch ? DROPDOWN_LIST_HEADER + 1 : 0; // 1px border
        const footerHeight = footerRef.value ? footerRef.value.getBoundingClientRect().height + 1 : 0; // 1px border
        // Чтобы контент списка правильно отобразился нужно отнять высоту хедера (search) и footer
        const height = Number(dropdownHeight.replace(/[^\d.]/g, '')) - searchHeight - footerHeight;
        if (dropdownStyles.value && (props.withSearch || isFooterVisible.value)) {
          if (dropdownHeight) {
            return `height: ${Math.ceil(height)}px;`;
          }
        }
        // Отнимаем 2px border (по 1 сверху и снизу)
        return `height: ${Math.ceil(height) - 2}px;`;
      }
      return '';
    });

    const setTargetElementClientRect = () => {
      if (props.targetElement) {
        targetElementClientRect.value = props.targetElement.getBoundingClientRect();
        // После появления targetElement - сначала нужно определить ширину dropdown, чтобы правильно вычислить высоту и позицию
        dropdownStyles.value = getDropdownWidth(props.dropdownMatchSelectWidth, targetElementClientRect.value.width);
      }
    };

    const handleTargetElementClientRectChange = () => {
      if (props.visible) {
        setTargetElementClientRect();
      } else {
        targetElementClientRect.value = null;
        targetElementSubMenu.value = null;
      }
    };

    const handleBlur = (event: FocusEvent) => {
      // Здесь используем именно setTimeout, для того чтобы подвинуть обработчик в конец стека (дождаться всплытия
      // event до панели, если событие было произведено инпутом)
      setTimeout(() => {
        if (!dropdownRef.value?.contains(document.activeElement)) {
          emit('blur', event);
        }
      }, 0);
    };

    const focus = (options: FocusOptions) => dropdownRef.value?.focus(options);

    const focusSearch = () => {
      searchInputRef.value?.focus();
    };

    // Данная обертка нужна, чтобы в директиве v-infinite-scroll в binding.value попала ф-ция которую можно вызвать
    const handleUpdateList = () => {
      emit('updateList');
    };

    const handleClickOutside = (event: PointerEvent) => {
      if (!props.withAutoClosing) return;

      if (!(event.target instanceof Element)) return;
      const isEventTargetInsideDropdown = dropdownRef.value?.contains(event.target);
      const isActiveElementTargetElement = props.targetElement && event.target === props.targetElement;
      const isTargetElementContainEventTarget = props.targetElement?.contains(event.target);
      if (isEventTargetInsideDropdown || isActiveElementTargetElement || isTargetElementContainEventTarget) return;
      emit('update:visible', false);
      /*
        Эмит нужен для удобства: чтобы в коде, использующем компонент, не приходилось делать watch.
        Эмита visibleChange со значением true у нас нет, т.к. Dropdown сам себя открыть не может
      */
      emit('visibleChange', false);
    };

    const setDropdownStyles = () => {
      if (targetElementSubMenu.value) {
        const styles = getInlineStyle({
          placement: props.placement,
          minHeight: props.minHeight,
          maxHeight: props.maxHeight,
          targetElementClientRectBottom: targetElementClientRect.value?.bottom,
          targetElementClientRectTop: targetElementClientRect.value?.top,
          targetElementClientRectRight: targetElementClientRect.value?.right,
          targetElementClientRectLeft: targetElementClientRect.value?.left,
          targetElementClientRectWidth: targetElementClientRect.value?.width,
          targetElementSubMenuHeight: targetElementSubMenu.value.height,
          targetElementSubMenuWidth: targetElementSubMenu.value.width,
          optionsLength: props.optionsLength,
          dropdownMatchSelectWidth: props.dropdownMatchSelectWidth,
          isFixedOnBottomLeft: props.isFixedOnBottomLeft,
          isFixedOnTopRight: props.isFixedOnTopRight,
        });
        dropdownStyles.value = joinString([styles, dropdownStyles.value]);
      }
    };

    const handleWindowResize = () => {
      setTargetElementClientRect();
      nextTick(setDropdownStyles);
    };

    expose({
      dropdown: dropdownRef,
      focus,
      focusSearch,
    });

    watch(dropdownRef, () => {
      if (dropdownRef.value) {
        targetElementSubMenu.value = dropdownRef.value.getBoundingClientRect();
      }
    });

    watch(targetElementSubMenu, setDropdownStyles);

    watch(() => props.visible, handleTargetElementClientRectChange);

    onMounted(() => window.addEventListener('resize', handleWindowResize));

    onUnmounted(() => window.removeEventListener('resize', handleWindowResize));

    return {
      dropdown: dropdownRef,
      isFooterVisible,
      isNotFoundVisible,
      searchInputRef,
      footerRef,
      targetElementSubMenu,
      listItemsStyles,
      dropdownStyles,

      tt,
      handleBlur,
      handleUpdateList,
      focus,
      handleClickOutside,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
