import { useRoute } from 'vue-router';
import { computed } from 'vue';

import store from '@/store';
import { ROUTE_TYPE } from '@/constants';
import { EExperimentalFeatures } from '@/domains/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { useTenantsStore } from '@/stores/tenants';

export const getIsLicensePaywallMessageShown = (): boolean => {
  const route = useRoute();
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));
  const isPrivateRoute = route?.meta?.type === ROUTE_TYPE.private;
  const tenantsStore = useTenantsStore();

  const hasTenants = computed(() => (isPiniaMigrationStoreTenantsAvailable.value
    ? !!tenantsStore.userTenants?.length
    : !!store.state.tenants.userTenants?.length));
  const hasInstanceLicense = !!store.state.app.appSettings?.licenseSettings;
  const hasLicenseRole = computed(() => (isPiniaMigrationStoreTenantsAvailable.value
    ? !!tenantsStore.currentTenantDetails?.licenseRole
    : !!store.state.tenants.currentTenantInfo?.licenseRole));

  return hasTenants.value
  && isPrivateRoute
  && hasInstanceLicense
  && !hasLicenseRole.value;
};
