export const getCSSProperty = (
  {
    propertyName,
    value,
    unit = 'px',
  }: {
    propertyName: 'left' | 'height' | 'top' | 'width' | 'min-width' | 'max-width',
    value: number | string,
    unit?: string,
  }) => (propertyName && value ? `${propertyName}: ${value}${unit};` : '');
