import { ROUTE_TYPE } from '@/constants';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import store from '@/store';
import { ROUTE_NAME } from '@/contexts/reportsContext/domain/constants';
import { canBrowseReportsTemplatesByRole } from '@/domains/permissions/role/reportTemplates';
import { hasCarrierWorkplaceSubscription } from '@/domains/permissions/subscription';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { canBrowseBiAnalyticsByRole } from '@/domains/permissions/role/biAnalytics';
import { useTenantsStore } from '@/stores/tenants';

export default [
  buildProtectedRoute({
    path: '/report_templates',
    name: ROUTE_NAME.reportTemplates,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [canBrowseReportsTemplatesByRole],
      component: () => import('@/contexts/reportsContext/views/ReportTemplatesView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/analytics',
    name: 'analytics',
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        () => checkFeatureIsEnabled(EExperimentalFeatures.biAnalytics),
        () => hasCarrierWorkplaceSubscription() || (() => {
          if (checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants)) {
            return !!useTenantsStore().currentTenantDetails?.settings.hasCustomBiDashboards;
          }
          return !!store.state.tenants.currentTenantInfo?.settings.hasCustomBiDashboards;
        })(),
        canBrowseBiAnalyticsByRole,
      ],
      component: () => import('@/contexts/reportsContext/views/AnalyticsView/index.vue'),
    },
  }),
];
