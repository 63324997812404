import { useRoute } from 'vue-router';

import store from '@/store';
import { ROUTE_TYPE } from '@/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { useTenantsStore } from '@/stores/tenants';

export const getIsLicenseRestrictionLayoutShown = (): boolean => {
  const route = useRoute();
  const isPrivateRoute = route?.meta?.type === ROUTE_TYPE.private;
  const tenantsStore = useTenantsStore();

  const hasTenants = checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants)
    ? !!tenantsStore.userTenants?.length
    : !!store.state.tenants.userTenants?.length;

  const hasInstanceLicense = !!store.state.app.appSettings?.licenseSettings;
  const hasLicenseRole = checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants)
    ? !!tenantsStore.currentTenantDetails?.licenseRole
    : !!store.state.tenants.currentTenantInfo?.licenseRole;

  return hasTenants
    && isPrivateRoute
    && !route.path.startsWith('/employees')
    && !route.path.startsWith('/tenant/settings')
    && !route.path.startsWith('/account')
    && !route.path.startsWith('/invitations')
    && hasInstanceLicense
    && !hasLicenseRole;
};
