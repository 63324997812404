import { DROPDOWN_PADDING_WITH_MARGIN } from '@/ui/types/constants';

import { getCSSProperty } from './getCSSProperty';

type TSetLeftWithoutMaxWidthParams = {
  targetElementClientRectRight?: number,
  targetElementClientRectLeft?: number,
  targetElementClientRectWidth?: number,
  targetElementSubMenuWidth?: number,
  isFixedOnTopRight: boolean,
};

export const setLeftWithoutMaxWidth = ({
  isFixedOnTopRight,
  targetElementSubMenuWidth,
  targetElementClientRectRight,
  targetElementClientRectLeft,
  targetElementClientRectWidth,
}: TSetLeftWithoutMaxWidthParams) => {
  // рендерим дропдаун от левого каря targetElement
  let left = targetElementClientRectLeft ? getCSSProperty({
    propertyName: 'left',
    value: targetElementClientRectLeft,
  }) : '';

  const hasTargetElementClientRect = targetElementClientRectRight
    && targetElementClientRectLeft
    && targetElementClientRectWidth;

  const spaceToRightOfDropdownList = targetElementClientRectLeft
  && document.documentElement.clientWidth - targetElementClientRectLeft;

  // Если дропдаун заходит за экран справа, то начинаем рендерить дропдаун в левую сторону, от правого края targetElement
  if (spaceToRightOfDropdownList && targetElementSubMenuWidth && targetElementClientRectRight
    && spaceToRightOfDropdownList < targetElementSubMenuWidth) {
    // padding 6px (dropdown) + margin 2px = 8
    left = getCSSProperty({
      propertyName: 'left',
      value: targetElementClientRectRight - targetElementSubMenuWidth,
    });
  }

  /** зафиксировать Dropdown с правого края контрола, либо, если места нет, то с левого края */
  if (isFixedOnTopRight && targetElementSubMenuWidth && hasTargetElementClientRect) {
    // padding 6px (dropdown) + margin 2px = 8
    if (spaceToRightOfDropdownList && spaceToRightOfDropdownList < targetElementSubMenuWidth) {
      // padding 6px (dropdown) + margin 2px = 8
      left = getCSSProperty({
        propertyName: 'left',
        value: targetElementClientRectRight - targetElementSubMenuWidth - DROPDOWN_PADDING_WITH_MARGIN,
      });
    } else if (spaceToRightOfDropdownList && spaceToRightOfDropdownList > targetElementSubMenuWidth) {
      // padding 6px (dropdown) + margin 2px = 8
      left = getCSSProperty({
        propertyName: 'left',
        value: targetElementClientRectLeft,
      });
    }
  }

  return left;
};
