import { ROUTE_TYPE } from '@/constants';
// eslint-disable-next-line import/order
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';

// TODO: Эти функции должны быть публичным интерфейсом другого контекста (IdentityAccessManagement)
import isCurrentUserAdmin from '@/contexts/accountingContext/domain/isCurrentUserAdmin';
import isCurrentUserSupport from '@/contexts/accountingContext/domain/isCurrentUserSupport';

const routes = [
  buildProtectedRoute({
    path: '/admin',
    name: 'adminView',
    meta: {
      type: ROUTE_TYPE.admin,
      permissions: [() => isCurrentUserAdmin() || isCurrentUserSupport()],
      component: () => import('@platformAdministrationContext/views/AdminView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/admin/tenants',
    name: 'adminTenantsList',
    meta: {
      type: ROUTE_TYPE.admin,
      permissions: [() => isCurrentUserAdmin() || isCurrentUserSupport()],
      component: () => import('@platformAdministrationContext/views/TenantsListView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/admin/tenants/:id',
    name: 'adminTenantInfo',
    meta: {
      type: ROUTE_TYPE.admin,
      permissions: [() => isCurrentUserAdmin() || isCurrentUserSupport()],
      component: () => import('@platformAdministrationContext/views/TenantsListView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/admin/users',
    name: 'adminUsersList',
    meta: {
      type: ROUTE_TYPE.admin,
      permissions: [() => isCurrentUserAdmin() || isCurrentUserSupport()],
      component: () => import('@platformAdministrationContext/views/UsersListView/index.vue'),
    },
  }),
  buildProtectedRoute({
    path: '/admin/users/:id',
    name: 'adminUserInfo',
    meta: {
      type: ROUTE_TYPE.admin,
      permissions: [() => isCurrentUserAdmin() || isCurrentUserSupport()],
      component: () => import('@platformAdministrationContext/views/UsersListView/index.vue'),
    },
  }),
];

export default routes;
