export enum EApprovalsType {
  surcharges = 'surcharges',
  claims = 'claims',
}

export enum EApprovalsFilters {
  all = 'all',
  approved = 'approved',
  rejected = 'rejected',
  revision = 'in_revision',
  revoked = 'revoked',
  pending = 'pending',
}

export enum ESurchargesType {
  mileage = 'mileage',
  work = 'work',
  point = 'point',
  downtime = 'downtime',
  rasputitsa = 'rasputitsa',
  loadingTypesRear = 'loadingTypesRear',
  loadingTypesBothSide = 'loadingTypesBothSide',
  loadingTypesSide = 'loadingTypesSide',
  loadingTypesTop = 'loadingTypesTop',
  expressDelivery = 'expressDelivery',
}

export enum EApprovalsStatus {
  /** Согласовано */
  approved = 'approved',
  /** Отклонено */
  rejected = 'rejected',
  /** На доработке */
  revision = 'in_revision',
  /** Отозвано */
  revoked = 'revoked',
  /** На рассмотрении */
  pending = 'pending',
}

export enum EApprovalSubjectType {
  extraService = 'FreightExecutionContext::ExtraService',
  claim = 'FreightExecutionContext::Claim',
}

export const MAX_APPROVAL_REJECTIONS = 6;

export enum EApprovalExecutionRequestType {
  executionRequest = 'execution_request',
  combinedExecutionRequest = 'combined_execution_request',
}
