<template>
  <div class="restriction-layout">
    <div class="restriction-layout__container">
      <div class="restriction-layout__title">
        {{ title }}
      </div>
      <div class="restriction-layout__description">
        {{ description }}
      </div>
      <div class="restriction-layout__divider" />
      <div class="restriction-layout__contacts">
        <SmartLink :href="`tel:${phone}`">
          {{ phone }}
        </SmartLink>
        <span class="restriction-layout__contacts-point" />
        <SmartLink :href="`mailto:${email}`">
          {{ email }}
        </SmartLink>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue';

import useAppTheme from '@/composables/application/useAppTheme';
import { SmartLink } from '@/ui/experimental';

export default defineComponent({
  name: 'RestrictionLayout',
  components: { SmartLink },
  props: {
    title: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    description: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
  },
  setup() {
    const { getThemeConfig } = useAppTheme();
    const { support } = getThemeConfig();

    return {
      phone: support.phone,
      email: support.email,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
