import { computed } from 'vue';

import router from '@/router';
import { useSiderMenuItems } from '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/application/useSiderMenuItems';
import { ECommonSchemaItemKeys, ECommonSchemaItemType } from '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/domains/constants';
import store from '@/store';
import { EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';
import { useFoldersStore } from '@/stores/folders';
import { TSubscription } from '@/types';
import { TCommonSchemaItem } from '@/components/layouts/AppLayoutWrapper/components/AppLayoutSider/domains/types';
import { useTenantsStore } from '@/stores/tenants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

import { hasCargoOwnerWorkplaceSubscription, hasCarrierWorkplaceSubscription } from './permissions/subscription';
import isTenantVerified from './isTenantVerified';

const redirectToInitialRoute = (route?: string | null) => {
  const foldersStore = useFoldersStore();
  const tenantsStore = useTenantsStore();
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));
  const { siderMenuItems } = useSiderMenuItems(foldersStore);

  if (route) {
    return router.push(route);
  }

  const hasActiveSubscription = computed<boolean | undefined>(() => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      return tenantsStore.currentTenantDetails?.subscriptions
        .some((subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active);
    }
    return store.state.tenants.currentTenantInfo?.subscriptions?.some(
      (plan: TSubscription) => plan.status === EBillingPlanCodeStatus.active);
  });

  /** Если hasActiveSubscription.value undefined, значит redirectToInitialRoute внутри метода navigateAfterSignIn
   *  был вызван до получения ответа по запросу на currentTenantInfo.
   *  Вернем reject, чтобы не отработала установка лоадера в false(переменная isGlobalEntitiesLoading[после выпила FF piniaMigrationStoreTenants - store.state.app.isAppStateLoading])
   */
  if (hasActiveSubscription.value === undefined) {
    return Promise.reject();
  }

  const hasInstanceLicense = !!store.state.app.appSettings?.licenseSettings;
  const hasLicenseRole = isPiniaMigrationStoreTenantsAvailable.value
    ? !!tenantsStore.currentTenantDetails?.licenseRole
    : !!store.state.tenants.currentTenantInfo?.licenseRole;

  // редиректим на корневой роут, если:
  // на инстансе есть настройки лицензии, но не засечена роль тенанта
  // или нет настроек лицензии (используется биллинг), но тенант не верифицирован или не имеет активной подписки

  if ((hasInstanceLicense && !hasLicenseRole)
     || (!hasInstanceLicense && (!isTenantVerified() || !hasActiveSubscription.value))) {
    return router.push('/');
  }

  let firstPage: string | null = null;
  if (hasCarrierWorkplaceSubscription()) { // если ГП, то firstPage всегда Лента заказов
    firstPage = siderMenuItems.value
      .filter((item: TCommonSchemaItem) => item.key === ECommonSchemaItemKeys.executorsLookupsFeed)[0]?.path || null;
  } else if (hasCargoOwnerWorkplaceSubscription()) { // если ГВ, то firstPage всегда Заказы
    firstPage = siderMenuItems.value
      .filter((item: TCommonSchemaItem) => item.key === ECommonSchemaItemKeys.orders)[0]?.path || null;
  } else {
    firstPage = siderMenuItems.value
      .filter((item: TCommonSchemaItem) => item.type !== ECommonSchemaItemType.withDivider)[0]?.path || null;
  }

  if (firstPage) {
    return router.push(firstPage);
  }
  return Promise.resolve();
};

export default redirectToInitialRoute;
