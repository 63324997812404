import { computed } from 'vue';

import store from '@/store';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { useTenantsStore } from '@/stores/tenants';

const isPiniaMigrationStoreTenantsAvailable = computed(
  () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

export const getIsTenantWithCustomization = (): boolean => {
  if (isPiniaMigrationStoreTenantsAvailable.value) {
    const tenantsStore = useTenantsStore();
    return !!tenantsStore.currentTenantDetails?.settings.hasCustomerConfiguration;
  }
  return !!store.state.tenants.currentTenantInfo?.settings.hasCustomerConfiguration;
};
