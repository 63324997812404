<template>
  <BillingPlanSelectionCard
    :title="tt('billing.planCode.basic')"
    :subtitle="tt('billing.modal.ifYouAreTheSenderOrRecipientOfTheGoods')"
    :planCode="EBillingPlanCode.shipper"
    :isActivePlan="isActivePlan"
    :price="planPrice"
    style="width: 100%;"
    @change="$emit('change', $event)"
  >
    <template #description>
      <li
        v-for="(description, index) in getDescriptionByPlanCode(EBillingPlanCode.shipper)"
        :key="index"
      >
        {{ description }}
      </li>
    </template>
  </BillingPlanSelectionCard>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import tt from '@/i18n/utils/translateText';
import { EBillingPlanCode, EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';
import useStore from '@/store/useStore';
import { TSubscription } from '@/types';
import { TBillingAvailablePlansResponse } from '@/contexts/billingContext/domain/types';
import { useTenantsStore } from '@/stores/tenants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

import BillingPlanSelectionCard from '../BillingPlanSelectionCard/index.vue';
import { getPlanPrice } from '../../domain/getPlanPrice';
import { getDescriptionByPlanCode } from '../../domain/getDescriptionByPlanCode';

export default defineComponent({
  name: 'BillingCardsForShipper',
  components: { BillingPlanSelectionCard },
  props: {
    availablePlans: {
      type: Array as PropType<TBillingAvailablePlansResponse>,
      default: () => [],
    },
  },
  emits: ['change'],
  setup(props) {
    const store = useStore();
    const tenantsStore = useTenantsStore();
    const isPiniaMigrationStoreTenantsAvailable = computed(
      () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

    const currentActiveSubscription = computed(() => {
      if (isPiniaMigrationStoreTenantsAvailable.value) {
        return tenantsStore.currentTenantDetails?.subscriptions
          .find((subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active) || null;
      }
      return store.state.tenants.currentTenantInfo?.subscriptions?.find(
        (subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active) || null;
    });

    const isActivePlan = computed(() => currentActiveSubscription.value?.planCode === EBillingPlanCode.shipper);

    const planPrice = computed(
      () => getPlanPrice({
        planCode: EBillingPlanCode.shipper,
        availablePlans: props.availablePlans,
        currentActiveSubscription: currentActiveSubscription.value,
      }),
    );

    return {
      EBillingPlanCode,
      isActivePlan,
      currentActiveSubscription,
      planPrice,

      tt,
      getDescriptionByPlanCode,
    };
  },
});
</script>
