<template>
  <section class="app-section">
    <ConfigProvider :locale="ruRU">
      <router-view v-slot="{ Component }">
        <WelcomeLoader v-if="showWelcomeLoader" />
        <Redirect
          v-else-if="shouldRedirectToSignUp"
          :to="redirectPathToSignIn"
        />
        <AppLayoutWrapper
          v-else
          :hasSider="showSider"
        >
          <component :is="Component" />
        </AppLayoutWrapper>
      </router-view>
    </ConfigProvider>
  </section>
</template>

<script lang="ts">
import { ConfigProvider } from 'ant-design-vue';
import ruRU from 'ant-design-vue/lib/locale-provider/ru_RU';
import {
  computed,
  defineComponent,
  onMounted,
} from 'vue';
import { useRoute } from 'vue-router';

import useStore from '@/store/useStore';
import WelcomeLoader from '@/components/WelcomeLoader/index.vue';
import Redirect from '@/components/Redirect/index.vue';
import { useAppGeneralNavigation } from '@/composables/application/useAppGeneralNavigation';
import { useAppGlobalEntities } from '@/composables/application/useAppGlobalEntities';
import { useAppState } from '@/composables/application/useAppState';
import useAppTabState from '@/composables/application/useAppTabState';
import useTenantServices from '@/composables/application/useTenantServices';
import useNotificationsUtils from '@/composables/application/useNotificationsUtils';
import useImportWsUtils from '@/composables/application/useImportWsUtils';
import useReportsWsUtils from '@/composables/application/useReportsWsUtils';
import useTenantBalanceWsUtils from '@/composables/application/useTenantBalanceWsUtils';
import useI18n from '@/composables/application/useI18n';
import { setDefaultFavicon, showErrorNotification } from '@/utils';
import useBreakpoints from '@/composables/useBreakpoints';
import { getApplicationSettings } from '@/services';
import { TReachedResponse } from '@/api/types';
import { TApplicationSettings } from '@/types';
import AppLayoutWrapper from '@/components/layouts/AppLayoutWrapper/index.vue';
import { useAppTransitions } from '@/composables/application/useAppTransitions';
import { useAppTestograf } from '@/composables/application/useAppTestograf';
import { useCaptureGlobalErrors } from '@/composables/application/useCaptureGlobalErrors';
import { useI18nStore } from '@/stores/i18n';
import { EExperimentalFeatures } from '@/domains/constants';
import { useAppSentry } from '@/composables/application/useAppSentry';

import { useSyncTokens } from './composables/application/useSyncTokens';
import { useMigrateStoreData } from './composables/application/useMigrateStoreData';
import { checkFeatureIsEnabled } from './domains/checkFeatureIsEnabled';
import { ELocale } from './ui/experimental/LocalizationButton/domain/constants';

export default defineComponent({
  name: 'App',
  components: {
    WelcomeLoader,
    ConfigProvider,
    Redirect,
    AppLayoutWrapper,
  },
  setup() {
    const store = useStore();
    const route = useRoute();
    const i18nStore = useI18nStore();

    const isPiniaPaginationStoreAvailable = computed(
      () => checkFeatureIsEnabled(EExperimentalFeatures.piniaPaginationStore),
    );

    const isPiniaMigrationStoreTenantsAvailable = computed(
      () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

    const { fetchCommonTenantData } = useTenantServices();
    const { fetchGlobalEntities, isGlobalEntitiesLoading } = useAppGlobalEntities();
    const {
      initializeRouter,
      isPrivateRoute,
      isAdminRoute,
      redirectPathToSignIn,
    } = useAppGeneralNavigation();
    const { setUserForSentry } = useAppSentry();

    const { isMobile } = useBreakpoints();

    const isAuthByLoginPassword = computed((): boolean => store.getters['app/isAuthByLoginPassword']);
    const isAuthBySocial = computed((): boolean => store.getters['app/isAuthBySocial']);
    const isAuthByTemporaryAccessSession = computed((): boolean => store.getters['app/isAuthByTemporaryAccessSession']);
    const isAuthorizedByUserAccount = computed(() => isAuthByLoginPassword.value || isAuthBySocial.value || isAuthByTemporaryAccessSession.value);
    const preparationMethod = computed(() => (isAuthorizedByUserAccount.value
      ? fetchAppData
      : () => Promise.resolve()));

    const isContractorLayout = store.getters['app/isAuthByEntryCode'];

    const {
      stateTransitions,
      isNavigationAfterSignInInProgress,
    } = useAppTransitions(isAuthorizedByUserAccount);

    const showIfMobile = computed(() => {
      if (isContractorLayout.value) return !isMobile.value;
      return true;
    });

    const showSider = computed(() => (isPrivateRoute(route) || isAdminRoute(route)) && showIfMobile.value);

    const fetchAppData = async () => {
      try {
        await fetchGlobalEntities();
        await fetchCommonTenantData();
      } catch (error: unknown) {
        showErrorNotification({ message: error as string });
      }
    };

    const fetchAppSettings = () => {
      getApplicationSettings()
        .then((response: TReachedResponse<TApplicationSettings>) => {
          if (!response.data) return;
          store.dispatch('app/setAppSettings', response.data);
        })
        .catch(() => {});
    };

    const {
      isTokenValidityCheckInProgress,
      shouldRedirectToSignUp,
      performAuthCheck,
    } = useAppState(
      preparationMethod.value,
      stateTransitions,
    );

    const { migrateStoreData } = useMigrateStoreData();

    const showWelcomeLoader = computed(() => {
      if (isPiniaMigrationStoreTenantsAvailable.value) {
        return isTokenValidityCheckInProgress.value || store.state.app.isAppStateLoading;
      }
      return isTokenValidityCheckInProgress.value
      || isGlobalEntitiesLoading.value
      || isNavigationAfterSignInInProgress.value;
    });

    useI18n();
    useNotificationsUtils();
    useImportWsUtils();
    useReportsWsUtils();
    useAppTabState();
    useAppTestograf();
    useCaptureGlobalErrors();
    useSyncTokens();
    useTenantBalanceWsUtils();

    onMounted(() => {
      setUserForSentry();
      initializeRouter();
      performAuthCheck();
      setDefaultFavicon();
      fetchAppSettings();
      i18nStore.setCurrentLocale(i18nStore.currentLocale ?? ELocale.ru);
      /** Миграция данных из Vuex-сторов в стор pinia */
      if (isPiniaMigrationStoreTenantsAvailable.value) {
        migrateStoreData('TMS_TENANTS', 'tenants');
      }
      if (isPiniaPaginationStoreAvailable.value) {
        migrateStoreData('TMS_PAGINATION', 'pagination', 'state');
      }
    });

    return {
      shouldRedirectToSignUp,
      ruRU,
      showSider,
      redirectPathToSignIn,
      showWelcomeLoader,
    };
  },
});
</script>

<style lang="scss">
#app {
  height: 100%;
  transition: transform 0.3s cubic-bezier(0.78, 0.14, 0.15, 0.86);
}

.app-page-header {
  border-top: 1px solid $neutral30;
}

.app-section {
  height: 100%;
}

.ant-form-vertical .ant-form-item-label {
  padding-bottom: 4px;
}

.ant-form-item {
  margin-bottom: 8px;
}

.ant-btn-primary {
  box-shadow: none;
  text-shadow: none;
}

.ttp-bg {
  z-index: $z-medium;
}

.ttp-container {
  z-index: $z-high;
}

</style>
