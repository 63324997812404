/** Юз для миграции данных по одному ключу из LocalStorage в другие */
export const useMigrateStoreData = () => {
  const sourceStorageName = 'TMS_STORAGE_KEY';
  // Получаем данные из исходного хранилища
  const sourceStorageData = localStorage.getItem(sourceStorageName);

  const migrateStoreData = (
    /** Куда переносим данные */
    targetStorageKey: string,
    /** Ключ из TMS_STORAGE_KEY */
    sourceStorageNestedKey: string,
    /** Кастомный ключ под который переносим данные в новый стор */
    targetStorageSubKey?: string,
  ) => {
    // Если нет исходного хранилища, тогда завершаем вычисления
    if (!sourceStorageData) {
      console.warn(`Нет данных для миграции модуля ${targetStorageKey}. Данные с ключом "${sourceStorageName}" не найдены в localStorage.`);
      return;
    }

    try {
      // Получаем данные из нового стора
      const targetData = localStorage.getItem(targetStorageKey);

      if (targetData) {
        try {
          const parsedTargetData = JSON.parse(targetData);
          const isDataMigrated = targetStorageSubKey
            ? !!parsedTargetData[targetStorageSubKey].migratedAt
            : !!parsedTargetData.migratedAt;

          // Если данные уже переносились, то отменяем миграцию
          if (isDataMigrated) return;
        } catch {
          console.error(`Ошибка при парсинге данных из "${targetStorageKey}".`);
        }
      }

      // Парсим данные из JSON
      let parsedData;
      try {
        parsedData = JSON.parse(sourceStorageData);
      } catch {
        console.error(`Ошибка при парсинге данных из "${sourceStorageName}".`);
        return;
      }

      // Проверяем наличие данных под конкретным ключом
      const nestedData = parsedData?.[sourceStorageNestedKey];
      if (!nestedData) {
        console.warn(`Ключ "${sourceStorageNestedKey}" отсутствует в данных "${sourceStorageName}".`);
        return;
      }

      // Добавляем дату миграции
      const transformedData = {
        ...nestedData,
        migratedAt: new Date().toISOString(),
      };

      // Записываем данные в новое хранилище
      localStorage.setItem(targetStorageKey, JSON.stringify(targetStorageSubKey
        ? { [targetStorageSubKey]: transformedData }
        : transformedData),
      );

      // eslint-disable-next-line no-console
      console.log(`Данные успешно перенесены из "${sourceStorageName}/${sourceStorageNestedKey}" в "${targetStorageKey}".`);
    } catch (error: any) {
      console.error(`Ошибка при миграции данных: ${error.message}`);
    }
  };

  return { migrateStoreData };
};
