import tt from '@/i18n/utils/translateText';
import { EAccessLevels } from '@/contexts/executorsLookupContext/domain/constants';

export const ROUTE_NAME = {
  tenders: 'tenders',
  tender: 'tender',
  surveyStage: 'survey_stage',
  submissionStage: 'submission_stage',
  tendersMailings: 'tenders_mailings',
  mailingCreate: 'mailing_create',
  mailing: 'mailing',
};

export enum ESubmissionStageListType {
  directions = 'directions',
  participants = 'participants',
}

export enum ETenderPeriodType {
  day = 'day',
  week = 'week',
  month = 'month',
}

export enum EStageType {
  survey = 'survey',
  submission = 'submission',
}

export enum ESurveyStageKinds {
  withAnswerOptions = 'with_answer_options',
  freeInput = 'free_input',
}

export enum EStageRelation {
  bid = 'bid',
  lot = 'lot',
  offer = 'offer',
}

export enum ECustomRenderLotSlugs {
  bestScore = 'best_score',
  lightsIndicatorColor = 'lights_indicator_color',
  vatRate = 'vat_rate',
}

// TODO: используется и в таблице заказов. Придумать, куда это поместить и ничего при этом не сломать
export const ACCESS_LEVEL_TRANSLATE = {
  [EAccessLevels.allPartners]: tt('tenders.view.stages.accessSettings.allPartners'),
  [EAccessLevels.selectedPartners]: tt('tenders.view.stages.accessSettings.selectedPartners'),
  [EAccessLevels.allExecutors]: tt('tenders.view.stages.accessSettings.allExecutors'),
};

export enum ETenderStatus {
  draft = 'draft',
  active = 'active',
  archived = 'archived',
}

export enum ETenderState {
  hasNotActiveStages = 'has_not_active_stages',
  hasActiveStages = 'has_active_stages',
  archived = 'archived',
}

export enum ETenderStageStatus {
  draft = 'draft',
  paused = 'paused',
  readyToStart = 'ready_to_start',
  started = 'started',
  finished = 'finished',
  canceled = 'canceled',
}

export enum ETenderCustomerFilter {
  all = 'all',
  hasNotActiveStages = 'has_not_active_stages',
  hasActiveStage = 'has_active_stages',
  archived = 'archived',
}

export enum ETenderStageExecutorFilter {
  active = 'active',
  finished = 'finished',
}

// светофор начинается со значения в 100% по нижней границе зеленого цвета
export const MIN_VALUE_GREEN_LIGHT = 100;

export enum ELightIndicator {
  green = 'green',
  orange = 'orange',
  red = 'red',
  withoutColor = 'without_color',
}

export enum ETenderOfferStatus {
  draft = 'draft',
  submitted = 'submitted',
}

export enum ETenderAttributeType {
  string = 'string',
  dictionary = 'dictionary',
  boolean = 'boolean',
  integer = 'integer',
  float = 'float',
  date = 'date',
}

export enum EValidationConstraint {
  past = 'past',
  future = 'future',
}

export enum EAttributeValidation {
  filled = 'filled',
  minSize = 'minSize',
  maxSize = 'maxSize',
  includedIn = 'includedIn',
  gt = 'gt',
  lt = 'lt',
  constraint = 'constraint',
}

export enum ERebiddingValidationType {
  none = 'none',
  lessThatPrevious = 'less_that_previous',
  lessThatPreviousWithPercentStep = 'less_that_previous_with_percent_step',
}

export enum EMailingStatus {
  active = 'active',
  archived = 'archived',
}

export enum EDispatchesStatus {
  pending = 'pending',
  completed = 'completed',
}

export enum ESurveyStageListType {
  results = 'results',
  questions = 'questions',
  participants = 'participants',
}
