import { computed } from 'vue';

import { canCreateTenantsByRole, canManageTenantsByRole } from '@/domains/permissions/role/tenants';
import { canBrowseEmployeesByRole } from '@/domains/permissions/role/employees';
import { concatFullname } from '@/domains/concatFullname';
import useStore from '@/store/useStore';
import { joinString } from '@/utils';
import { TTenantEmployeeRole } from '@/types';
/** TODO: удалить вместе с FF piniaMigrationStoreTenants */
import { useUserStore } from '@/stores';
import { useUserStore as useUserStoreNew } from '@/stores/user';
import { useTenantsStore } from '@/stores/tenants';
import { hasCarrierStarterWorkplaceSubscription, hasCarrierWorkplaceSubscription } from '@/domains/permissions/subscription';
import { canBrowseWalletsByRole } from '@/domains/permissions/role/wallets';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

export const useView = () => {
  const store = useStore();
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  /** TODO: заменить useUserStore > useUserStore() при выпиле FF piniaMigrationStoreTenants */
  const userStore = isPiniaMigrationStoreTenantsAvailable.value
    ? useUserStoreNew()
    : useUserStore;
  const tenantsStore = useTenantsStore();

  const currentTenant = computed(() => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      return tenantsStore.currentTenant;
    }
    return store.state.tenants.currentTenant;
  });

  const availableUserTenants = computed(() => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      return tenantsStore.userTenants || [];
    }
    return store.state.tenants.userTenants;
  });

  const isAuthByTemporaryAccessSession = computed((): boolean => store.getters['app/isAuthByTemporaryAccessSession']);

  const fullName = computed(() => concatFullname(currentTenant.value?.employee));
  const email = computed(() => userStore.user?.email || '');
  const showOrganizations = computed(() => !userStore.user?.ownerTenantId && !isAuthByTemporaryAccessSession.value);
  const showSettingsButton = computed(() => canManageTenantsByRole() && currentTenant.value?.employee.id);
  const employeeRoles = computed(() => joinString(currentTenant.value?.employee.roles.map((role: TTenantEmployeeRole) => role.name), ', '));
  const showEmployeeProfileLink = computed(() => currentTenant.value?.employee.id && canBrowseEmployeesByRole());
  const isAddTenantButtonShown = computed(() => canCreateTenantsByRole());

  const isBillingWalletAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.billingWallet),
  );

  const isBillingWalletLinkShown = computed(() => isBillingWalletAvailable.value
  && (
    hasCarrierWorkplaceSubscription()
    || hasCarrierStarterWorkplaceSubscription()
  ) && canBrowseWalletsByRole());

  return {
    isAddTenantButtonShown,
    currentTenant,
    availableUserTenants,
    fullName,
    email,
    showOrganizations,
    showSettingsButton,
    employeeRoles,
    showEmployeeProfileLink,
    isBillingWalletLinkShown,
    isAuthByTemporaryAccessSession,
  };
};
