import { Ref, computed } from 'vue';

import useStore from '@/store/useStore';
import { EBillingPlanCode, EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';
import { TSubscription } from '@/types';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { useTenantsStore } from '@/stores/tenants';

type TUseView = {
  isPublicOfferAccept: Ref<boolean>,
  isManyEmployeesAlertShown: Ref<boolean>,
  isEmailConfirmed: Ref<boolean>,
  planCode: Ref<EBillingPlanCode | null>,
};

export const useView = (
  {
    isPublicOfferAccept,
    isManyEmployeesAlertShown,
    isEmailConfirmed,
    planCode,
  }: TUseView,
) => {
  const store = useStore();
  const tenantsStore = useTenantsStore();
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  // TODO: удалить currentTenantInfo вместе с FF piniaMigrationStoreTenants
  const currentTenantInfo = computed(() => store.state.tenants.currentTenantInfo);

  const isSubmitDisabled = computed(() => !isPublicOfferAccept.value
  || isManyEmployeesAlertShown.value
  || !isEmailConfirmed.value);

  const hasActiveSubscription = computed(() => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      return !!tenantsStore.currentTenantDetails?.subscriptions
        .some((subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active);
    }
    return !!store.state.tenants.currentTenantInfo?.subscriptions?.some(
      (plan: TSubscription) => plan.status === EBillingPlanCodeStatus.active);
  });

  const hasPastSubscriptions = computed(() => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      return !!tenantsStore.currentTenantDetails?.hasPastSubscriptions;
    }
    return !!currentTenantInfo.value?.hasPastSubscriptions;
  });

  const isBillingTrialPeriodInfoShown = computed(
    () => !hasPastSubscriptions.value
    && !hasActiveSubscription.value
    && planCode.value !== EBillingPlanCode.carrierStart,
  );

  return {
    isSubmitDisabled,
    isBillingTrialPeriodInfoShown,
  };
};
