import { computed } from 'vue';

import store from '@/store';
import { useTenantsStore } from '@/stores/tenants';

import { checkFeatureIsEnabled } from './checkFeatureIsEnabled';
import { EExperimentalFeatures } from './constants';

const isPiniaMigrationStoreTenantsAvailable = computed(
  () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

const hasCurrentTenant = () => {
  if (isPiniaMigrationStoreTenantsAvailable.value) {
    const tenantsStore = useTenantsStore();
    return !!tenantsStore.currentTenant;
  }
  return !!store.state.tenants.currentTenant;
};

export default hasCurrentTenant;
