import app from './app';
import contractorInfo from './contractorInfo';
import filter from './filter';
import notifications from './notifications';
import pagination from './pagination';
import rightPanel from './rightPanel';
import tenants from './tenants';

const modules = {
  app,
  contractorInfo,
  filter,
  notifications,
  pagination,
  rightPanel,
  tenants,
};

export default modules;
