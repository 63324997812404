import { computed } from 'vue';
import { useRouter } from 'vue-router';

import { useWalletStore } from '@/stores/wallet';
import { formatNumberAsCurrency } from '@/utils';
import tt from '@/i18n/utils/translateText';

export const useView = () => {
  const router = useRouter();
  const walletStore = useWalletStore();

  const wallet = computed(() => walletStore.wallet);

  const activeBalance = computed(() => formatNumberAsCurrency(wallet.value?.activeBalance || 0, 2, {
    separator: ' ',
    symbol: tt('shared.currency.symbol.rub'),
    pattern: '# !',
  }));

  const handleRedirectButtonClick = () => {
    router.push('/tenant/finance');
  };

  return {
    activeBalance,

    handleRedirectButtonClick,
  };
};
