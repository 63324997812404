import { computed, watch } from 'vue';
import * as Sentry from '@sentry/vue';

import store from '@/store';
/** TODO: удалить вместе с FF piniaMigrationStoreTenants */
import { useUserStore } from '@/stores';
import { useUserStore as useUserStoreNew } from '@/stores/user';
import { useTenantsStore } from '@/stores/tenants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

export const useAppSentry = () => {
  const sentryDsn = import.meta.env.VITE_APP_SENTRY_DSN || null;
  const environment = import.meta.env.VITE_APP_SENTRY_ENVIRONMENT || null;
  const replaysSessionSampleRate = import.meta.env.VITE_APP_SENTRY_REPLAYS_SESSION_SAMPLE_RATE || null;
  const replaysOnErrorSampleRate = import.meta.env.VITE_APP_SENTRY_REPLAYS_ON_ERROR_SAMPLE_RATE || null;
  const isSentryEnabled = import.meta.env.VITE_APP_USE_SENTRY === 'true';
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  const setUserForSentry = () => {
    if (!isSentryEnabled) return;

    const currentTenantId = computed(() => {
      if (isPiniaMigrationStoreTenantsAvailable.value) {
        return useTenantsStore().currentTenant?.id || null;
      }
      return store.state.tenants.currentTenant?.id || null;
    });

    /** TODO: заменить useUserStore > useUserStore() при выпиле FF piniaMigrationStoreTenants */
    const userStore = isPiniaMigrationStoreTenantsAvailable.value
      ? useUserStoreNew()
      : useUserStore;
    const currentUserId = computed(() => userStore.user?.id || null);
    const currentUserEmail = computed(() => userStore.user?.email || null);

    watch([currentTenantId, currentUserId, currentUserEmail], ([tenantId, userId, userEmail]) => {
      if (tenantId && userId && userEmail) {
        Sentry.setUser({
          id: `tenantId: ${tenantId}, userId: ${userId}`,
          username: userEmail,
        });
      }
    });
  };

  const sentryInit = (app) => {
    if (!isSentryEnabled) return;

    if (sentryDsn) {
      if (environment === 'production') {
        Sentry.init({
          app,
          logErrors: true,
          dsn: sentryDsn,
          environment,
          tracesSampleRate: 1.0,
          replaysSessionSampleRate: Number(replaysSessionSampleRate),
          replaysOnErrorSampleRate: Number(replaysOnErrorSampleRate),
          integrations: [
            Sentry.replayIntegration({
              maskAllText: false,
              blockAllMedia: false,
              minReplayDuration: 15000,
              networkDetailAllowUrls: [/^https:\/\/api-tms-(.*?)\.stage\.trucker\.group\/(.*?)/, 'api.tms.ru'],
            }),
          ],
          ignoreErrors: [
            'Failed to fetch dynamically imported module', // игнорируем ошибку vite из роутера
          ],
        });
      } else {
        Sentry.init({
          app,
          logErrors: true,
          dsn: sentryDsn,
          environment,
          tracesSampleRate: 1.0,
          ignoreErrors: [
            'Failed to fetch dynamically imported module', // игнорируем ошибку vite из роутера
          ],
        });
      }
    }
  };

  return {
    setUserForSentry,
    sentryInit,
  };
};
