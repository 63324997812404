import {
  computed,
  ref,
  watch,
} from 'vue';

import useStore from '@/store/useStore';
import { RESET_AUTH_STATE } from '@/store/modules/app/action-types';
import { TDomainError, TTenant } from '@/types';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
/** TODO: удалить вместе с FF piniaMigrationStoreTenants */
import { useUserStore } from '@/stores';
import { useUserStore as useUserStoreNew } from '@/stores/user';
import { useTenantsStore } from '@/stores/tenants';

export const useAppGlobalEntities = () => {
  const store = useStore();
  const isGlobalEntitiesLoading = ref(false);

  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  // TODO: удалить isTenantLoading вместе с FF piniaMigrationStoreTenants
  const isTenantLoading = computed(() => store?.getters['tenants/tenantLoading']);

  const userTenants = computed<TTenant[]>(() => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      return useTenantsStore().userTenants || [];
    }
    return store.getters['tenants/userTenants'];
  });
  // TODO: удалить isUserLoading вместе с FF piniaMigrationStoreTenants
  const isUserLoading = computed(() => useUserStore.isUserLoading);

  /** Если oneTimePassword === true, то fetchTenant - отдаст 403, т.к. не будет доступа к tenants
    * чтобы не делать ненужного запроса и не ловить ошибку - добавим проверку на oneTimePassword(OTP)
    * TODO: заменить useUserStore > useUserStore() при выпиле FF piniaMigrationStoreTenants */
  const isUserLoggedInByOTP = computed(() => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      return useUserStoreNew().user?.lockedSince && useUserStoreNew().user?.oneTimePassword;
    }
    return useUserStore.user?.lockedSince && useUserStore.user?.oneTimePassword;
  });

  // TODO: удалить метод вместе с FF piniaMigrationStoreTenants
  const setGlobalEntitiesLoading = (isLoading: boolean) => {
    isGlobalEntitiesLoading.value = isLoading;
  };

  const fetchGlobalEntities = () => {
    /** TODO: заменить useUserStore > useUserStore() при выпиле FF piniaMigrationStoreTenants */
    const userStore = isPiniaMigrationStoreTenantsAvailable.value
      ? useUserStoreNew()
      : useUserStore;
    const tenantsStore = useTenantsStore();

    if (userStore.user && !isUserLoggedInByOTP.value) {
      if (isPiniaMigrationStoreTenantsAvailable.value) {
        return tenantsStore.loadTenants().catch((error: TDomainError) => {
          store.dispatch(`app/${RESET_AUTH_STATE}`);
          throw error;
        });
      }
      // TODO: удалить вместе с FF piniaMigrationStoreTenants
      return store.dispatch('tenants/fetchTenants').catch((error: TDomainError) => {
        store.dispatch(`app/${RESET_AUTH_STATE}`);
        throw error;
      });
    }

    if (!userStore.user) {
      return userStore.loadUser()
        .then(() => {
          if (!isUserLoggedInByOTP.value) {
            if (isPiniaMigrationStoreTenantsAvailable.value) {
              return tenantsStore.loadTenants().catch((error: TDomainError) => {
                throw error;
              });
            }
            // TODO: удалить вместе с FF piniaMigrationStoreTenants
            return store.dispatch('tenants/fetchTenants').catch((error: TDomainError) => {
              throw error;
            }) as PromiseLike<unknown>;
          }
          return Promise.resolve();
        })
        .catch((error: TDomainError) => {
          store.dispatch(`app/${RESET_AUTH_STATE}`);
          throw error;
        });
    }

    return Promise.resolve();
  };

  // TODO: удалить вотчер вместе с FF piniaMigrationStoreTenants
  if (!isPiniaMigrationStoreTenantsAvailable.value) {
    watch(() => [isUserLoading.value, isTenantLoading.value], ([isUserLoading, isTenantLoading]: boolean[]) => {
      setGlobalEntitiesLoading(isUserLoading || isTenantLoading);
    });
  }

  return {
    userTenants,
    isGlobalEntitiesLoading,

    fetchGlobalEntities,
  };
};
