import { useRoute } from 'vue-router';
import { computed } from 'vue';

import store from '@/store';
import { TSubscription } from '@/types';
import { hasCarrierStarterWorkplaceSubscription, hasCarrierWorkplaceSubscription } from '@/domains/permissions/subscription';
import { ROUTE_TYPE } from '@/constants';
import { useTenantsStore } from '@/stores/tenants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

import { EBillingPlanCodeStatus } from './constants';

export const getIsBillingSpecialOfferPaywallMessageShown = () => {
  const route = useRoute();
  const tenantsStore = useTenantsStore();
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  const isPrivateRoute = route?.meta?.type === ROUTE_TYPE.private;
  const hasTenants = computed(() => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      return !!tenantsStore.userTenants?.length;
    }
    return !!store.state.tenants.userTenants?.length;
  });

  const hasInstanceLicense = !!store.state.app.appSettings?.licenseSettings;

  const activeSubscription = computed(() => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      return tenantsStore.currentTenantDetails?.subscriptions
        .find((subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active);
    }
    return store.state.tenants.currentTenantInfo?.subscriptions
      .find((subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active);
  });

  return isPrivateRoute
  && !hasInstanceLicense
  && hasTenants.value
  && (hasCarrierWorkplaceSubscription() || hasCarrierStarterWorkplaceSubscription())
  && !!activeSubscription.value?.specialOffer
  && !activeSubscription.value.specialOffer.confirmedAt;
};
