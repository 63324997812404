import { ROUTE_TYPE } from '@/constants';
import { buildProtectedRoute } from '@/router/utils/buildProtectedRoute';
import { getIsTenantWithCustomization } from '@/domains/getIsTenantWithCustomization';
import { hasCargoOwnerWorkplaceSubscription } from '@/domains/permissions/subscription';
import canBrowseCustomReferencesByRole from '@/domains/permissions/role/customReferences/canBrowseCustomReferencesByRole';

import { ROUTE_NAME } from '../domain/constants';

export default [
  buildProtectedRoute({
    path: '/references',
    name: ROUTE_NAME.references,
    meta: {
      type: ROUTE_TYPE.private,
      permissions: [
        () => getIsTenantWithCustomization(),
        hasCargoOwnerWorkplaceSubscription,
        canBrowseCustomReferencesByRole,
      ],
      component: () => import('@/contexts/referencesContext/views/ReferencesListView/index.vue'),
    },
  }),
];
