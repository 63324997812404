import {
  Ref,
  onUnmounted,
  ref,
  watch,
} from 'vue';

type TUseTruncateTextParams = {
  statusTextRef: Ref<HTMLElement | null>,
  withOverflowTooltip: Ref<boolean>,
};

export const useTruncateText = ({
  statusTextRef,
  withOverflowTooltip,
}: TUseTruncateTextParams) => {
  let resizeObserver;

  const isTextOverflowing = ref<boolean>(false);
  const isObserverActive = ref<boolean>(false);

  const checkTextOverflowing = () => {
    if (!statusTextRef.value) return;
    isTextOverflowing.value = statusTextRef.value.scrollWidth > statusTextRef.value.clientWidth;
  };

  const initResizeObserver = () => {
    if (isObserverActive.value || !statusTextRef.value) return;

    resizeObserver = new ResizeObserver(() => {
      checkTextOverflowing();
    });

    resizeObserver.observe(statusTextRef.value);

    isObserverActive.value = true;
  };

  watch(statusTextRef, () => {
    if (statusTextRef.value && withOverflowTooltip.value) {
      checkTextOverflowing();
      initResizeObserver();
    }
  }, { immediate: true });

  onUnmounted(() => {
    if (resizeObserver) {
      resizeObserver.disconnect();
    }
  });

  return { isTextOverflowing };
};
