import { defineStore } from 'pinia';
import { ref } from 'vue';

import { TDomainError } from '@/types';
import { TReachedResponse } from '@/api/types';
import { TWallet, TWalletResponse } from '@/contexts/billingContext/domain/types';
import { getWallet } from '@/contexts/billingContext/services';

import { TInsufficientFunds, TWalletStore } from './types';

export const useWalletStore = defineStore(
  'TMS_WALLET',
  (): TWalletStore => {
    const wallet = ref<TWallet | null>(null);
    const insufficientFunds = ref<TInsufficientFunds | null>(null);

    const loadWallet = () => getWallet()
      .then((response: TReachedResponse<TWalletResponse>) => {
        if (!response.data) return;
        wallet.value = response.data;
      })
      .catch((error: TDomainError) => Promise.reject(error));

    const setInsufficientFunds = (newValue: TInsufficientFunds | null) => {
      insufficientFunds.value = newValue;
    };

    const $reset = () => {
      wallet.value = null;
      insufficientFunds.value = null;
    };

    return {
      wallet,
      insufficientFunds,

      loadWallet,
      setInsufficientFunds,
      $reset,
    };
  },
  { persist: true });
