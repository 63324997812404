import { computed } from 'vue';

import store from '@/store';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { useTenantsStore } from '@/stores/tenants';

const isPiniaMigrationStoreTenantsAvailable = computed(
  () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

const isPartnershipResourcesVerificationEnabled = () => {
  if (isPiniaMigrationStoreTenantsAvailable.value) {
    return useTenantsStore().currentTenantDetails?.features.partnershipResources?.verificationEnabled;
  }
  return !!store.state.tenants.currentTenantInfo?.features.partnershipResources?.verificationEnabled;
};

export default isPartnershipResourcesVerificationEnabled;
