<template>
  <div
    :class="[
      'date-picker-input',
      `date-picker-input_${size}`,
    ]"
  >
    <input
      ref="inputRef"
      class="date-picker-input__hidden-input"
      inputmode="numeric"
      @focus="$emit('focus')"
      @blur="$emit('blur')"
    >
    <div
      :class="[
        'date-picker-input__sections',
        `date-picker-input__sections_${size}`,
      ]"
    >
      <DatePickerInputSection
        :value="state.day"
        :isSelected="selectedSection === EDatePickerInputSection.day"
        :isInputActive="isInputActive"
        :placeholderCount="dayPlaceholderCount"
        :size="size"
        @select="handleSectionSelect(EDatePickerInputSection.day)"
      />

      <span
        v-if="isDotDividerShown"
        class="date-picker-input__divider"
        :class="{ 'date-picker-input__divider_black': !!state.month }"
      >.</span>

      <DatePickerInputSection
        :value="state.month"
        :isSelected="selectedSection === EDatePickerInputSection.month"
        :isInputActive="isInputActive"
        :placeholderCount="monthPlaceholderCount"
        :size="size"
        @select="handleSectionSelect(EDatePickerInputSection.month)"
      />

      <span
        v-if="isDotDividerShown"
        class="date-picker-input__divider"
        :class="{ 'date-picker-input__divider_black': !!state.year }"
      >.</span>

      <DatePickerInputSection
        :value="state.year"
        :isSelected="selectedSection === EDatePickerInputSection.year"
        :isInputActive="isInputActive"
        :placeholderCount="yearPlaceholderCount"
        :size="size"
        @select="handleSectionSelect(EDatePickerInputSection.year)"
      />
    </div>
  </div>
</template>

<script lang="ts">
import {
  PropType,
  defineComponent,
  ref,
  toRefs,
} from 'vue';

import { EDatePickerInputSection, ESize } from '@/ui/types/constants';
import { TDateAppFormat } from '@/types';

import { useView } from './application/useView';
import DatePickerInputSection from './components/DatePickerInputSection/index.vue';
import { TDateSections } from '../../domain/types';
import { useInteractWithInput } from './application/useInteractWithInput';

export default defineComponent({
  name: 'DatePickerInput',
  components: { DatePickerInputSection },
  props: {
    state: {
      type: Object as PropType<TDateSections>,
      required: true,
    },
    isInputDisabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.medium,
    },
    isInputActive: {
      type: Boolean,
      default: true,
    },
    minDate: {
      type: [Object, null] as PropType<TDateAppFormat | null>,
      default: null,
    },
    maxDate: {
      type: [Object, null] as PropType<TDateAppFormat | null>,
      default: null,
    },
  },
  emits: ['focus', 'blur', 'change'],
  setup(props, { expose, emit }) {
    const {
      state,
      isInputActive,
      minDate,
      maxDate,
      isInputDisabled,
    } = toRefs(props);

    const inputRef = ref<HTMLInputElement | null>(null);

    const {
      dayPlaceholderCount,
      monthPlaceholderCount,
      yearPlaceholderCount,
      isDotDividerShown,
    } = useView({
      isInputActive,
      state,
    });

    const {
      selectedSection,

      handleSectionSelect,
      resetSelectedSection,
      focus,
    } = useInteractWithInput({
      state,
      isInputDisabled,
      isInputActive,
      inputRef,
      minDate,
      maxDate,
      emit,
    });

    expose({
      resetSelectedSection,
      focus,
    });

    return {
      dayPlaceholderCount,
      monthPlaceholderCount,
      yearPlaceholderCount,
      inputRef,
      selectedSection,
      isDotDividerShown,
      EDatePickerInputSection,

      handleSectionSelect,
      resetSelectedSection,
      focus,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
