<template>
  <a
    :href="href"
    target="_blank"
    rel="noopener noreferrer"
    @click.stop="handleLinkClick"
  >
    <slot />
  </a>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { useRouter } from 'vue-router';

export default defineComponent({
  name: 'SmartLink',
  props: {
    href: {
      type: String,
      required: true,
    },
    alwaysOpenNewTab: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const router = useRouter();
    const handleLinkClick = (event: any) => {
      const isLinkRelative = props.href.startsWith('/');

      /**
        Если зажата клавиша Ctrl или Command, то открываем ссылку в новой вкладке.
        Если никакая из клавиш перечисленных выше не зажата, то необходимо проверять наличие флага alwaysOpenNewTab
        и является ли ссылка относительной, и исходя из этого принимать решение о способе открытия ссылки
      */
      if (!event.metaKey && !event.ctrlKey) {
        event.preventDefault();
        // Если указана относительная ссылка, например, /docs/default/privacy.pdf
        if (isLinkRelative) {
          if (!props.alwaysOpenNewTab) {
            router.push(props.href);
          } else {
            window.open(`${import.meta.env.VITE_APP_HOST}${props.href}`, '_blank');
          }
        // Если указана абсолютная ссылка, например, https://www.google.com/
        } else if (!props.alwaysOpenNewTab) {
          window.location.href = props.href;
        } else {
          window.open(props.href, '_blank');
        }
      }
    };

    return { handleLinkClick };
  },
});
</script>
