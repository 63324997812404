<template>
  <ControlWrapper
    ref="wrapperRef"
    class="ui-multiselect"
    tabindex="0"
    :disabled="disabled"
    :isInvalid="isInvalid"
    :size="size"
    :hasBorder="hasBorder"
    @blur="$emit('blur', $event)"
    @focus="$emit('focus', $event)"
    @click.stop
  >
    <template #prefix>
      <TagGroup
        v-if="formattedValue"
        class="ui-multiselect__tag-group"
        :size="tagsSize"
        :value="formattedValue"
        :maxTagCount="maxTagCount"
        :isResponsiveTagCount="isResponsiveTagCount"
        @delete="onUpdateValue"
      />
    </template>
    <slot name="addonBefore" />
    <Input
      ref="inputRef"
      data-toggle="dropdown"
      aria-haspopup="true"
      class="ui-multiselect__dropdown-input"
      :aria-expanded="isDropdownVisible"
      :size="size"
      :placeholder="inputPlaceholder"
      :hasBorder="false"
      :disabled="disabled"
      readonly
      @keydown="onInputKeydown"
      @focus="handleInputFocus"
      @blur="handleInputBlur"
      @mousedown.prevent.stop="onInputClick"
    />
    <slot name="addonAfter" />
    <Dropdown
      ref="dropdownRef"
      :className="dropdownClassName"
      :optionsLength="optionsLength"
      :targetElement="wrapperHtmlRef"
      :visible="isDropdownVisible"
      :dropdownMatchSelectWidth="dropdownMatchSelectWidth"
      :notFoundContent="notFoundContentText"
      :isFixedOnBottomLeft="isOptionsFixedOnLeft"
      :withSearch="withSearch"
      :searchValue="searchValue"
      @keydown="onDropdownKeydown"
      @blur="handleDropdownBlur"
      @search="handleOptionSearch"
      @updateList="handleOptionsListUpdate"
    >
      <template #footerControlLeft>
        <slot name="footerControlLeft">
          <Button
            :type="EButtonType.link"
            :title="tt('shared.action.selectAll')"
            @click="handleSelectAll"
            @mousedown.prevent
          />
        </slot>
      </template>
      <template #footerControlRight>
        <slot name="footerControlRight">
          <Button
            :type="EButtonType.link"
            :title="tt('shared.action.clearAll')"
            @click="handleRemoveAll"
            @mousedown.prevent
          />
        </slot>
      </template>
      <template #notFoundContent>
        <slot v-if="hasNotFoundContentSlot" name="notFoundContent" />
      </template>
      <template v-if="hasContent">
        <slot v-if="hasSlotOptions" />
        <template v-else>
          <!-- Так как нет отдельных уникальных полей,
           используем уникальную комбинацию полей самого объекта c JSON.stringify -->
          <div
            v-for="optionGroup in optionsToDisplay"
            :key="JSON.stringify(optionGroup)"
            :class="{ 'ui-multiselect__dropdown-group_divided': optionGroup.hasDivider }"
          >
            <p
              v-if="optionGroup.label"
              class="ui-multiselect__dropdown-group-label"
            >
              {{ optionGroup.label }}
            </p>
            <DropdownItem
              v-for="option in optionGroup.options"
              :key="option.value"
              :class="{ 'ui-multiselect__option-item_focused': option.key === String(focusedOptionIndex) }"
              :value="option.label"
              :selected="isSelectedValue(value, option)"
              :disabled="option.disabled"
              :info="option.info"
              :subTitle="option.subTitle"
              :subInfo="option.subInfo"
              withCheckbox
              @click="onUpdateValue({
                value: option.value,
                label: option.label,
              })"
            >
              <template #icon>
                <component
                  :is="option.icon"
                  v-if="option.icon"
                />
              </template>
            </DropdownItem>
          </div>
        </template>
      </template>
    </Dropdown>
    <template #suffix>
      <DownSvg
        viewBox="0 0 16 16"
        class="slotted ui-multiselect__icon-arrow"
        :class="{ 'multiselect__icon-arrow_up': isDropdownVisible }"
        @mousedown.prevent.stop="handleClose"
      />
    </template>
  </ControlWrapper>
</template>
<script lang="ts">
import {
  computed,
  defineComponent,
  nextTick,
  onMounted,
  onUnmounted,
  PropType,
  ref,
  watch,
} from 'vue';
import debounce from 'lodash.debounce';

import {
  ESize,
  TSelectOption,
  TSelectOptions,
  TSelectOptionsGroup,
  TSelectValue,
  TTagGroupOption,
  EButtonType,
} from '@/ui/types';
import tt from '@/i18n/utils/translateText';
import DownSvg from '@/assets/svg/16x16/chevron-down.svg';
import { DEFAULT_DEBOUNCE_DELAY, EKeyboardKey } from '@/constants';
import PlusSvg from '@/assets/svg/16x16/plus.svg';
import { hasSlotContent } from '@/utils/hasSlotContent';

import { getGroupedOptions } from '../utils/getGroupedOptions';
import ControlWrapper from '../ControlWrapper/index.vue';
import Dropdown from '../Dropdown/index.vue';
import DropdownItem from '../Dropdown/components/DropdownItem/index.vue';
import Input from '../Input/index.vue';
import TagGroup from '../TagGroup/index.vue';
import Button from '../Button/index.vue';
import { isSelectedValue } from './utils/isSelectedValue';
import { isSelectOptionGuard, isSelectOptionsGroupsGuard } from './utils/guards';

export default defineComponent({
  name: 'Multiselect',
  components: {
    TagGroup,
    Input,
    ControlWrapper,
    Dropdown,
    DropdownItem,
    Button,
    DownSvg,
    PlusSvg,
  },
  props: {
    value: {
      type: Array as PropType<TSelectValue[] | null>,
      default: null,
    },
    // Поддерживаем 2 интерфейса. Обычным списком И группами вида
    // { label: string, options: TSelectOption[] }
    options: {
      type: Array as PropType<TSelectOptions>,
      default: () => [],
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.medium,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: tt('shared.placeholder.selectFrom'),
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    dropdownClassName: {
      type: String,
      default: '',
    },
    dropdownMatchSelectWidth: {
      type: [Number, Boolean] as PropType<number | boolean>,
      default: true,
    },
    notFoundContent: {
      type: String,
      default: '',
    },
    labelInValue: {
      type: Boolean,
      default: false,
    },
    trackBy: {
      type: String,
      default: 'value',
    },
    hasBorder: {
      type: Boolean,
      default: true,
    },
    withSearch: {
      type: Boolean,
      default: false,
    },
    withAutocomplete: {
      type: Boolean,
      default: false,
    },
    /** зафиксировать список options у левого края Select'а */
    isOptionsFixedOnLeft: {
      type: Boolean,
      default: false,
    },
    maxTagCount: {
      type: [Number, null] as PropType<number | null>,
      default: 1,
    },
    /** авторасчет для responsive мультиселекта, игнорирует maxTagCount, если true (!использовать с оглядкой на производительность) */
    isResponsiveTagCount: {
      type: Boolean,
      default: false,
    },
    /** позволяет осуществлять поиск по всем значениям элемента */
    searchAllOptions: {
      type: Boolean,
      default: false,
    },
  },
  emits: [
    'update:value',
    'change',
    'blur',
    'focus',
    'select',
    'search',
    'updateList',
  ],
  setup(props, { emit, slots, expose }) {
    const inputRef = ref<typeof Input | null>(null);
    const dropdownRef = ref<typeof Dropdown | null>(null);
    const wrapperRef = ref<typeof ControlWrapper | null>(null);
    const isDropdownVisible = ref<boolean>(false);
    const optionsLength = ref<number | null>(null);
    const isInputFocus = ref<boolean>(false);
    const isDropdownFocus = ref<boolean>(false);
    const searchValue = ref<string>('');
    const focusedOptionIndex = ref<number | null>(null);
    const autocompleteValue = ref<string | null>(null);

    const groupedOptions = computed(() => getGroupedOptions(props.options) as TSelectOptionsGroup[]);

    const hasNotFoundContentSlot = computed(() => hasSlotContent({
      slots,
      slotName: 'notFoundContent',
    }));

    const hasSlotOptions = computed(() => hasSlotContent({
      slots,
      slotName: 'default',
    }));

    const wrapperHtmlRef = computed(() => (wrapperRef.value
      ? (wrapperRef.value as typeof ControlWrapper).wrapper
      : null));

    const hasContent = computed(() => !!optionsLength.value);
    const notFoundContentText = computed(() => props.notFoundContent || tt('shared.emptyList'));

    // Вычисляем опции единым списком, если они были в сгруппированном виде, для упрощения поиска selectedValue
    const listedOptions = computed<TSelectOption[]>(() => (isSelectOptionsGroupsGuard(props.options)
      ? props.options.map((option: TSelectOptionsGroup) => option.options).flat()
      : props.options));

    // Фильтруем группы опций с условием введенного поиска
    const optionsToDisplay = computed<TSelectOptionsGroup[]>(() => {
      if (!searchValue.value) return groupedOptions.value;
      const searchUpperValue = searchValue.value.toUpperCase();
      // используем reduce, чтобы накопить только те группы, поисковые совпадения по опциям которых, пригодны к выводу
      return groupedOptions.value.reduce((result: TSelectOptionsGroup[], group: TSelectOptionsGroup) => {
        const groupOptions = group.options?.filter((opt: TSelectOption) => {
          const sourceText = String(opt.label).toUpperCase();
          if (props.searchAllOptions) {
            const infoText = String(opt.info).toUpperCase();
            const subtitleText = String(opt.subTitle).toUpperCase();
            return sourceText.indexOf(searchUpperValue) > -1
              || infoText.indexOf(searchUpperValue) > -1
              || subtitleText.indexOf(searchUpperValue) > -1;
          }
          return sourceText.indexOf(searchUpperValue) > -1;
        });
        // если после фильтрафии опций в группе не осталось - не добавляем эту группу в итоговый вывод
        return !groupOptions?.length ? result : result.concat([{
          ...group,
          options: groupOptions,
        }]);
      }, []);
    });

    const formattedValue = computed<TTagGroupOption[] | null>(() => {
      if (!props.value) return null;

      return props.value.map((item: TSelectValue) => {
        if (typeof item === 'object') {
          return {
            label: item.label,
            value: item.value,
          };
        }

        const currentListedOption = listedOptions.value?.find((option: TSelectOption) => option.value === item);

        return {
          label: currentListedOption?.label || '',
          disabled: currentListedOption?.disabled,
          value: item,
        };
      });
    });

    const inputPlaceholder = computed(() => {
      if (!props.value || props.value.length === 0) return props.placeholder;
      return '';
    });

    const tagsSize = computed(() => ([ESize.small, ESize.medium].includes(props.size) ? ESize.small : ESize.medium));

    /*
      * При взаимодействии пользователя с Селектом - есть 3 основных состояния:
      * 1 - Дефолное состояние (закрыт дропдаун, нет фокуса на элементах)
      * 2 - Открыт дропдаун и фокус на нем же
      * 3 - Открыт дропдаун, но фокус стоит на инпуте
    */
    const openDropdown = () => {
      isDropdownVisible.value = true;

      nextTick().then(() => {
        isDropdownFocus.value = true;

        // Добавляем фокус дропдауну (или полю поиска), даже если в дропдауне нет контента т.к. мы все
        // равно отображаем текст и хотим закрывать дропдаун при blur`e
        if (props.withSearch) {
          dropdownRef.value?.focusSearch();
        } else {
          dropdownRef.value?.focus({ preventScroll: true });
        }
      });
    };

    const onInputClick = () => {
      if (props.disabled) return;
      if (!isDropdownVisible.value) { // Открываем дропдаун (первый клик по селекту)
        openDropdown();
      }
    };

    const handleDropdownBlur = () => {
      isDropdownFocus.value = false;
      if (isDropdownVisible.value && !isInputFocus.value) {
        isDropdownVisible.value = false;
      }
    };

    const handleInputFocus = () => {
      isInputFocus.value = true;
      if (!isDropdownVisible.value) {
        isDropdownVisible.value = true;
      }
    };

    const handleInputBlur = () => {
      // Закрывем дропдаун только в том случае, если он был уже открыт, фокус стоял на инпуте, но кликнули по другому элементу
      if (!isDropdownFocus.value && isDropdownVisible.value && isInputFocus.value) {
        isDropdownVisible.value = false;
      }
      isInputFocus.value = false;
    };

    const handleClose = () => {
      if (props.disabled) return;
      if (isDropdownVisible.value) {
        isDropdownVisible.value = false;
      } else {
        openDropdown();
      }
    };

    const onChange = (event: Event) => {
      const targetValue = (event.target as HTMLInputElement).value;
      emit('update:value', targetValue);
      emit('change', targetValue);
    };

    const autofocus = () => wrapperRef.value?.focus();

    expose({ autofocus });

    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === EKeyboardKey.tab || event.key === EKeyboardKey.escape) {
        if (isDropdownVisible.value) {
          isDropdownVisible.value = false;
          inputRef.value?.blur(event);
        }
      }
    };

    const onUpdateValue = (option: TSelectOption) => {
      let updatedValue: TSelectValue[] = props.value ? [...props.value] : [];
      if (isSelectedValue(props.value, option)) {
        // удаляем опцию из целевого массива, если она среди выбранных
        updatedValue = updatedValue.filter((item: TSelectValue) => (typeof item === 'object'
          ? item.value !== option.value
          : item !== option.value));
      } else if (props.labelInValue) {
        updatedValue.push(option);
      } else {
        updatedValue.push(option[props.trackBy]);
      }
      emit('update:value', updatedValue);
      emit('select', updatedValue);
      emit('change', updatedValue);
    };

    const onInputKeydown = (event: KeyboardEvent) => {
      if (!hasContent.value) return;
      if (event.key === EKeyboardKey.arrowDown) {
        event.preventDefault();
        openDropdown();
        focusedOptionIndex.value = 0;
      }
    };

    const onDropdownKeydown = (event: KeyboardEvent) => {
      event.preventDefault();
      if (!optionsLength.value) return;
      if (event.key === EKeyboardKey.arrowDown) {
        // если фокуса не было, устанавливаем его на первый элемент
        if (focusedOptionIndex.value === null) {
          focusedOptionIndex.value = 0;
        } else if (focusedOptionIndex.value < optionsLength.value - 1) {
          focusedOptionIndex.value += 1;
        }
      }
      if (event.key === EKeyboardKey.arrowUp) {
        // если фокус на 1 элементе или его вообще не было, то ставим на последний элемент списка
        if (focusedOptionIndex.value === 0 || focusedOptionIndex.value === null) {
          focusedOptionIndex.value = optionsLength.value - 1;
        } else if (focusedOptionIndex.value > 0) {
          focusedOptionIndex.value -= 1;
        }
      }
      if (event.key === EKeyboardKey.enter) {
        if (focusedOptionIndex.value === null) return;
        const focusedOption = listedOptions.value[focusedOptionIndex.value];
        if (!focusedOption || focusedOption.disabled) return;
        onUpdateValue(focusedOption);
      }
    };

    const handleOptionSearch = (value: string) => {
      if (props.withAutocomplete) {
        autocompleteValue.value = value;
        emit('search', value);
      } else {
        searchValue.value = value;
      }
    };

    const handleSelectAll = () => {
      // убираем опции с disabled из целевого массива

      let optionsToSelect: TSelectValue[] = listedOptions.value.filter((option: TSelectOption) => !option.disabled);

      if (!props.labelInValue) {
        optionsToSelect = optionsToSelect.map((option: TSelectValue) => (isSelectOptionGuard(option)
          ? option.value
          : option));
      }
      emit('update:value', optionsToSelect);
      emit('change', optionsToSelect);
    };

    const handleRemoveAll = () => {
      emit('update:value', null);
      emit('change', null);
    };

    const handleOptionsListUpdate = debounce(() => {
      emit('updateList');
    }, DEFAULT_DEBOUNCE_DELAY);

    watch(isDropdownVisible, () => {
      if (isDropdownVisible.value) {
        // nextTick тут добавлен для корректной отработки фокуса при переключении с другого элемента с раскрытым
        // выпадающем списком (например Datepicker или Multiselect). Иначе focus тригерится раньше тригера blur
        // предыдущего активного элемента.
        nextTick(() => {
          emit('focus');
        });
      } else {
        focusedOptionIndex.value = null;
        // Сбрасываем поле поиска по закрытию Dropdown
        if (searchValue.value) {
          searchValue.value = '';
        }
        if (props.withAutocomplete && props.withSearch && autocompleteValue.value) {
          autocompleteValue.value = '';
          emit('search', '');
        }

        emit('blur');
      }
    });

    watch(optionsToDisplay, () => {
      // для динамически подгружаемых options
      optionsLength.value = optionsToDisplay.value?.map((option: TSelectOptionsGroup) => option.options).flat().length;
    }, {
      deep: true,
      immediate: true,
    });

    watch(focusedOptionIndex, (newIndex: number | null, oldIndex: number | null) => {
      if (newIndex === null || oldIndex === null || focusedOptionIndex.value === null) return;
      if (listedOptions.value[newIndex].disabled) {
        // если находимся вначале списка и идем вверх в конец списка
        if (oldIndex === 0 && newIndex === listedOptions.value.length - 1) {
          focusedOptionIndex.value -= 1;
        } else if (oldIndex > newIndex) { // если идем вверх
          focusedOptionIndex.value -= 1;
        } else { // если идем вниз
          focusedOptionIndex.value += 1;
        }
      }
    });

    onMounted(() => {
      document.addEventListener('keydown', handleKeydown);
    });

    onUnmounted(() => {
      document.removeEventListener('keydown', handleKeydown);
    });

    return {
      ESize,
      optionsToDisplay,
      searchValue,
      inputRef,
      dropdownRef,
      wrapperRef,
      wrapperHtmlRef,
      optionsLength,
      notFoundContentText,
      isDropdownVisible,
      isSelectedValue,
      hasNotFoundContentSlot,
      hasContent,
      hasSlotOptions,
      formattedValue,
      inputPlaceholder,
      tagsSize,
      focusedOptionIndex,
      EButtonType,

      tt,
      onUpdateValue,
      onInputKeydown,
      onDropdownKeydown,
      handleClose,
      onInputClick,
      onChange,
      handleRemoveAll,
      handleDropdownBlur,
      handleInputFocus,
      handleInputBlur,
      handleOptionSearch,
      handleSelectAll,
      handleOptionsListUpdate,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
