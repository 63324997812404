import { TEntity } from '@/types';
import tt from '@/i18n/utils/translateText';

export enum EVatRate {
  vat_20 = 'vat_20',
  vat_0 = 'vat_0',
  vat_none = 'vat_none',
}

export const VAT_RATE_TRANSLATION = {
  [EVatRate.vat_20]: tt('shared.vatRate.vat20'),
  [EVatRate.vat_0]: tt('shared.vatRate.vat0'),
  [EVatRate.vat_none]: tt('shared.vatRate.vatNone'),
};

export const VAT_RATE_TRANSLATION_SHORT = {
  [EVatRate.vat_20]: '20%',
  [EVatRate.vat_0]: '0%',
  [EVatRate.vat_none]: tt('shared.vatRate.vatNone'),
};

const getVatRateOptions = (): TEntity[] => Object.values(EVatRate).map((item) => (
  {
    id: item,
    name: VAT_RATE_TRANSLATION[item],
  }));

export default getVatRateOptions;
