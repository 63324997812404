import {
  computed,
  ComputedRef,
  ref,
  UnwrapNestedRefs,
  watch,
} from 'vue';
import { RouteLocationNormalizedLoaded, Router } from 'vue-router';
import { _UnwrapAll } from 'pinia';

import { addByIndex, removeByIndex } from '@/utils';
import { TCustomStore } from '@/store/types';
import { useSberButtonVisible } from '@/components/SberBusinessIntegrationButton/composables/useSberButtonVisible';
import { EIntegrationEvent } from '@/components/SberBusinessIntegrationButton/domain/constants';
import { EFoldersSection } from '@/stores/folders/constants';
import { TCustomizationStore } from '@/stores/customization/types';
import { useFoldersStore } from '@/stores/folders';
import { TTableColumn } from '@/types';
import { EOrderFolderSlug } from '@/contexts/orderingContext/domain/constants';
import { EExperimentalFeatures } from '@/domains/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { useTenantsStore } from '@/stores/tenants';

import { useFetchData } from './useFetchData';
import { useSiderMenuItems } from './useSiderMenuItems';
import { ECommonSchemaItemType, ECommonSchemaItemKeys } from '../domains/constants';

type TUseInteractWithSiderArguments = {
  state: UnwrapNestedRefs<Record<string, any>>,
  folderId: ComputedRef<string>,
  folderOpenKeys: ComputedRef<string[]>,
  store: TCustomStore,
  router: Router,
  route: RouteLocationNormalizedLoaded,
  customizationStore: _UnwrapAll<TCustomizationStore>,
};

type TSubmenuItemKey = ECommonSchemaItemKeys.orders
| ECommonSchemaItemKeys.matrices
| ECommonSchemaItemKeys.references;

export const useInteractWithSider = (
  {
    state,
    folderId,
    folderOpenKeys,
    store,
    router,
    route,
    customizationStore,
  }: TUseInteractWithSiderArguments) => {
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  const foldersStore = useFoldersStore();
  const tenantsStore = useTenantsStore();
  const isCompletedSocialLinking = ref(false);

  const { siderMenuItems } = useSiderMenuItems(foldersStore);
  const { isSberButtonVisible } = useSberButtonVisible(EIntegrationEvent.linking);

  const hasLinkedAccountToSber = computed(() => isSberButtonVisible.value && !isCompletedSocialLinking.value);
  const getNextFolderColumnsSettings = (paramId: string): TTableColumn[] => {
    if (!paramId) return [];
    const ordersFolders = foldersStore.foldersState[EFoldersSection.orderList].folders;
    return foldersStore.getCurrentSectionFolder(ordersFolders, paramId)?.columnsSettings || [];
  };

  const showMenuItemByType = (
    allowedTypes: ECommonSchemaItemType[],
    type: ECommonSchemaItemType,
  ) => allowedTypes.includes(type);

  /**
   * Метод устанавливает selectedKeys в зависимости от текущего url.
   * selectedKeys используется для подсветки активного элемента меню сайдбара
   */
  const setSelectedKeys = (routePath: string) => {
    switch (true) {
      case !!folderId.value: {
        state.selectedKeys = [folderId.value];
        break;
      }
      case routePath === '/': {
        state.selectedKeys = ['summary'];
        break;
      }
      case routePath === '/admin': {
        state.selectedKeys = ['admin'];
        break;
      }
      case routePath.startsWith('/admin'): {
        const stringToSubtract = '/admin/';
        const adminKey = `admin_${routePath.slice(stringToSubtract.length).split('/')[0]}`;

        state.selectedKeys = [adminKey];
        break;
      }
      case routePath === '/executors_lookups/feed': {
        state.selectedKeys = ['executors_lookups_feed'];
        break;
      }
      default: {
        const key = routePath.slice(1).split('/')[0];

        state.selectedKeys = [key];
        break;
      }
    }
  };

  const {
    fetchFolderColumnsConfig,
    fetchFolders,
    fetchRunsCountInfo,
  } = useFetchData(
    {
      folderId,
      store,
      router,
      route,
      customizationStore,
    });

  const onNewOrderItemClick = (orderFolderSlug: EOrderFolderSlug) => {
    const routeFragments = route.path.split('/').filter((item: string) => item);
    let transformedURL = '';

    // Если у url есть дочерние фрагменты, то сохраняем их (например /orders/only_orders)
    if (routeFragments[0] === ECommonSchemaItemKeys.orders) {
      routeFragments.forEach((item) => {
        // TODO: вынести в enum
        if (item === 'orders' || item === 'only_orders' || item === 'with_freight_requests') {
          transformedURL = `${transformedURL}/${item}`;
        }
      });
    } else {
      transformedURL = `/${ECommonSchemaItemKeys.orders}`;
    }

    foldersStore.setCurrentFolderId(EFoldersSection.orderList, orderFolderSlug);

    if (getNextFolderColumnsSettings(orderFolderSlug).length) {
      router.replace({
        path: transformedURL,
        query: {
          ...route.query,
          folderId: orderFolderSlug,
        },
      });
    } else {
      foldersStore.loadOrderFolderInfo(orderFolderSlug)
        .then(() => {
          router.replace({
            path: transformedURL,
            query: {
              ...route.query,
              folderId: orderFolderSlug,
            },
          });
        });
    }
  };

  const onSubmenuItemClick = (itemKey: TSubmenuItemKey, paramId: string) => {
    const transformedURL = `/${itemKey}`;
    const foldersSectionName = `${itemKey}List`;
    const section = EFoldersSection[foldersSectionName];

    foldersStore.setCurrentFolderId(section, paramId);

    if (getNextFolderColumnsSettings(paramId).length) {
      router.replace({
        path: transformedURL,
        query: { folderId: paramId },
      });
    } else {
      Promise.all([
        fetchFolderColumnsConfig(paramId, section),
      ]).then(() => {
        router.replace({
          path: transformedURL,
          query: { folderId: paramId },
        });
      });
    }
  };

  const handleSubmenuItemClick = (itemKey: TSubmenuItemKey, paramId: string | EOrderFolderSlug) => {
    if (folderId.value === paramId) return;
    if (itemKey === ECommonSchemaItemKeys.orders) {
      onNewOrderItemClick(paramId as EOrderFolderSlug);
    } else {
      onSubmenuItemClick(itemKey, paramId);
    }
  };

  const handleTitleClick = (key: string) => {
    let openKeys: string[] = [];

    if (state.openKeys.includes(key)) {
      const deletionIndex = state.openKeys.findIndex((selectedKey: string) => selectedKey === key);

      openKeys = removeByIndex(state.openKeys, deletionIndex);
    } else {
      openKeys = addByIndex(state.openKeys, state.openKeys.length, key);
    }

    store.dispatch('app/setFolderOpenKeys', openKeys);
  };

  const onMenuItemClick = (path: string) => {
    const routeUrl = path.split('/')[1].split('?')[0];
    const folderId = path.split('folderId=')[1];

    // Когда в папке всего один элемент, то редирект по нажатию на пункт меню в сайдбаре происходит напрямую в эту папку, при этом необходимо запросить и сохранить в стор данные по ней
    if (folderId) {
      const foldersSectionName = `${routeUrl}List`;
      const section = EFoldersSection[foldersSectionName];

      foldersStore.setCurrentFolderId(section, folderId);
      fetchFolderColumnsConfig(folderId, section);
    }

    router.push(path);
  };

  const handleCompleteSocialLinking = () => {
    isCompletedSocialLinking.value = true;
  };

  watch(() => tenantsStore.currentTenantDetails?.id, () => {
    if (!isPiniaMigrationStoreTenantsAvailable.value) return;
    // в fetchFolders используются данные из currentTenantInfo
    fetchFolders();
    fetchRunsCountInfo();
  });

  // TODO: удалить watch вместе с FF piniaMigrationStoreTenants
  watch(() => store.state.tenants.currentTenantInfo?.id, () => {
    if (isPiniaMigrationStoreTenantsAvailable.value) return;
    // в fetchFolders используются данные из currentTenantInfo
    fetchFolders();
    fetchRunsCountInfo();
  });

  watch(
    () => [route.path, folderId.value],
    () => {
      store.dispatch('notifications/toggleNotificationsListVisible', false);

      setSelectedKeys(route.path);
    },
  );

  watch(() => folderOpenKeys.value, (value: string[]) => {
    state.openKeys = value;
  });

  return {
    isCompletedSocialLinking,
    isSberButtonVisible,
    hasLinkedAccountToSber,
    siderMenuItems,

    setSelectedKeys,
    handleTitleClick,
    handleCompleteSocialLinking,
    fetchFolderColumnsConfig,
    fetchFolders,
    fetchRunsCountInfo,
    showMenuItemByType,
    handleSubmenuItemClick,
    onMenuItemClick,
  };
};
