<template>
  <button
    ref="buttonRef"
    class="localization-button"
    :class="{ 'localization-button_active': isDropdownVisible }"
    @click="handleDropdownToggle"
  >
    <LanguageSvg />
    {{ tt('sider.localization.language') }}
  </button>
  <Dropdown
    v-model:visible="isDropdownVisible"
    class="localization-button__dropdown"
    :targetElement="buttonRef"
    :placement="EPopoverPlacement.right"
    withoutOptions
  >
    <button
      class="localization-button__dropdown-item"
      @click.stop="handleLocalizationChange(ELocale.ru)"
    >
      <FlagRuSvg />
      {{ tt('sider.localization.rus') }}
      <CheckSvg
        v-if="currentLocale === ELocale.ru"
        class="localization-button__dropdown-item-selected-icon"
      />
    </button>
    <button
      class="localization-button__dropdown-item"
      @click.stop="handleLocalizationChange(ELocale.zh)"
    >
      <FlagZhSvg />
      {{ tt('sider.localization.zh') }}
      <CheckSvg
        v-if="currentLocale === ELocale.zh"
        class="localization-button__dropdown-item-selected-icon"
      />
    </button>
    <button
      class="localization-button__dropdown-item"
      @click.stop="handleLocalizationChange(ELocale.en)"
    >
      <FlagEnSvg />
      {{ tt('sider.localization.eng') }}
      <CheckSvg
        v-if="currentLocale === ELocale.en"
        class="localization-button__dropdown-item-selected-icon"
      />
    </button>
  </Dropdown>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  ref,
} from 'vue';

import tt from '@/i18n/utils/translateText';
import LanguageSvg from '@/assets/svg/16x16/language.svg';
import FlagRuSvg from '@/assets/svg/others/flag-ru.svg';
import FlagEnSvg from '@/assets/svg/others/flag-en.svg';
import FlagZhSvg from '@/assets/svg/others/flag-zh.svg';
import CheckSvg from '@/assets/svg/16x16/check.svg';
import { EPopoverPlacement } from '@/ui/types';
import { useI18nStore } from '@/stores/i18n';

import { ELocale } from './domain/constants';
import { Dropdown } from '../..';

export default defineComponent({
  name: 'LocalizationButton',
  components: {
    LanguageSvg,
    Dropdown,
    FlagRuSvg,
    FlagEnSvg,
    FlagZhSvg,
    CheckSvg,
  },
  setup() {
    const buttonRef = ref<HTMLButtonElement | null>(null);
    const isDropdownVisible = ref<boolean>(false);

    const i18nStore = useI18nStore();

    const currentLocale = computed(() => i18nStore.currentLocale || ELocale.ru);

    const handleDropdownToggle = () => {
      isDropdownVisible.value = !isDropdownVisible.value;
    };

    const handleLocalizationChange = (newLocale: ELocale) => {
      i18nStore.setCurrentLocale(newLocale);
    };

    return {
      buttonRef,
      isDropdownVisible,
      currentLocale,
      EPopoverPlacement,
      ELocale,

      handleDropdownToggle,
      handleLocalizationChange,
      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
