import { computed } from 'vue';

import store from '@/store';
import { TTenantFeatures } from '@/types';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { useTenantsStore } from '@/stores/tenants';

// Данный composable используется как useRolePermissions, только смотрим на доступы проставленные в "Настройках организации"
const useTenantFeatures = () => {
  const tenantsStore = useTenantsStore();
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  const features = computed(() => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      return tenantsStore.currentTenantDetails?.features;
    }
    return store.getters['tenants/currentTenantInfo']?.features as TTenantFeatures;
  });

  // lookupStrategies
  const isLookupStrategiesFeaturesEnabled = computed(() => features.value?.lookupStrategies?.enabled || false);
  const isAutomaticLookupEnabled = computed(() => features.value?.lookupStrategies?.automaticLookupEnabled || false);

  // integrations
  const isAtisuIntegrationEnabled = computed(() => features.value?.integrations?.atisu.enabled || false);

  // executionRequestGeneralConditions
  const allowManageContractorsProgressByCargoOwner = computed(
    () => features.value?.executionRequestGeneralConditions.allowManageContractorsProgressByCargoOwner || false,
  );

  const allowCancellationByExecutor = computed(
    () => features.value?.executionRequestGeneralConditions.allowCancellationByExecutor || false,
  );

  const extraServiceApprovalTimeoutDays = computed(
    () => features.value?.executionRequestGeneralConditions.extraServiceApprovalTimeoutDays || 0,
  );

  // orderInfoTranslation
  const isOrderInfoReferenceNumberTranslation = computed(
    () => features.value?.orderInfoTranslation.referenceNumber || false,
  );

  const isOrderInfoResponsibleEmployeeTranslation = computed(
    () => features.value?.orderInfoTranslation.responsibleEmployee || false,
  );

  // contractorPersonalAccount
  const contractorPersonalAccountPermissions = computed(
    () => features.value?.contractorPersonalAccount?.permissions || null,
  );

  const contractorPersonalAccountLoadingProcessFlowId = computed(
    () => features.value?.contractorPersonalAccount?.loadingProcessFlowId || null,
  );

  const contractorPersonalAccountUnloadingProcessFlowId = computed(
    () => features.value?.contractorPersonalAccount?.unloadingProcessFlowId || null,
  );

  const contractorPersonalAccountRouteProgress = computed(
    () => features.value?.contractorPersonalAccount?.routeProgress || null,
  );

  // partnershipResources
  const isPartnershipResourcesVerificationEnabled = computed(
    () => !!features.value?.partnershipResources?.verificationEnabled,
  );

  // additionalSettings
  const isReductionBuyNowEnabled = computed(
    () => !!features.value?.additionalSettings?.reductionBuyNow.enabled,
  );

  return {
    isLookupStrategiesFeaturesEnabled,
    isAutomaticLookupEnabled,

    isAtisuIntegrationEnabled,

    allowManageContractorsProgressByCargoOwner,
    allowCancellationByExecutor,
    extraServiceApprovalTimeoutDays,

    isOrderInfoReferenceNumberTranslation,
    isOrderInfoResponsibleEmployeeTranslation,

    contractorPersonalAccountPermissions,
    contractorPersonalAccountLoadingProcessFlowId,
    contractorPersonalAccountUnloadingProcessFlowId,
    contractorPersonalAccountRouteProgress,

    isPartnershipResourcesVerificationEnabled,

    isReductionBuyNowEnabled,
  };
};

export default useTenantFeatures;
