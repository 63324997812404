import {
  computed,
  Ref,
  ref,
  watch,
} from 'vue';
import throttle from 'lodash.throttle';
import isEqual from 'lodash.isequal';

import {
  COUNT_MONTHS_IN_YEAR,
  EDatePickerInputSection,
  EDatePickerValueFormat,
  EKeyboardCode,
  REGEX_DATE,
} from '@/ui/types/constants';
import {
  getCurrentDayJsDate,
  getDateByStartOfPart,
  getDateDiff,
  getDayJsDate,
  getDaysCountInCurrentMonth,
  getDaysFromDate,
  getIsDateBetweenOrSame,
  getIsDateGreaterThan,
  getIsSameDate,
  getMonthsFromDate,
  getYearsFromDate,
  isValidDate,
} from '@/utils/dateUtils';
import { TDateSections } from '@/ui/DatePicker/domain/types';
import {
  COUNT_DAY_DIGITS,
  COUNT_MONTH_DIGITS,
  COUNT_YEAR_DIGITS,
} from '@/ui/DatePicker/domain/constants';
import { TDateAppFormat } from '@/types';
import { getFormattedDate } from '@/ui/DatePicker/domain/getFormattedDate';

import {
  DELAY_FOR_KEY_PRESS,
  EMoveDirection,
  MAX_DAYS_IN_MONTH,
} from '../domain/constants';
import { TEmitNewDateStateArguments, THandleArrowDownOrUpPressArguments } from '../domain/types';

type TUseInteractWithInput = {
  state: Ref<TDateSections>,
  isInputDisabled: Ref<boolean>,
  isInputActive: Ref<boolean>,
  inputRef: Ref<HTMLInputElement | null>,
  minDate: Ref<TDateAppFormat | null>,
  maxDate: Ref<TDateAppFormat | null>,
  emit: (event: 'focus' | 'blur' | 'change', ...args: unknown[]) => void,
};

export const useInteractWithInput = ({
  state,
  isInputDisabled,
  isInputActive,
  inputRef,
  minDate,
  maxDate,
  emit,
}: TUseInteractWithInput) => {
  const selectedSection = ref<EDatePickerInputSection | null>(null);

  const minDateSections = computed(() => (minDate.value ? {
    day: `${getDaysFromDate(minDate.value)}`.padStart(COUNT_DAY_DIGITS, '0'),
    month: `${getMonthsFromDate(minDate.value) + 1}`.padStart(COUNT_MONTH_DIGITS, '0'),
    year: `${getYearsFromDate(minDate.value)}`.padStart(COUNT_YEAR_DIGITS, '0'),
  } : null));

  const maxDateSections = computed(() => (maxDate.value ? {
    day: `${getDaysFromDate(maxDate.value)}`.padStart(COUNT_DAY_DIGITS, '0'),
    month: `${getMonthsFromDate(maxDate.value) + 1}`.padStart(COUNT_MONTH_DIGITS, '0'),
    year: `${getYearsFromDate(maxDate.value)}`.padStart(COUNT_YEAR_DIGITS, '0'),
  } : null));

  const dateFromState = computed(() => {
    const formattedDate = getFormattedDate(state.value);

    return formattedDate ? getDayJsDate(formattedDate) : null;
  });

  /** Если выбран месяц и год, возвращает количество дней в выбранном месяце, иначе возвращает максимально допустимое количество дней для месяца */
  const daysCountInMonth = computed(
    () => getDaysCountInCurrentMonth(`${state.value.year}-${state.value.month}-01`) || MAX_DAYS_IN_MONTH,
  );

  /** Эмитим новое значение в случае изменения состояния */
  const emitNewDateState = ({
    newDateState,
    currentDateState,
    isKeyPressed = false,
  }: TEmitNewDateStateArguments) => {
    if (!isEqual(newDateState, currentDateState)) {
      emit('change', {
        newDateState,
        isScrollFeedInstant: isKeyPressed,
      });
    }
  };

  /** Форматируем введенное значение в секции, добавляя недостающие символы, в случае перехода между секциями  */
  const formatAndChangeSectionValue = (section: EDatePickerInputSection) => {
    const newStateValue: TDateSections = { ...state.value };

    if (section === EDatePickerInputSection.day) {
      if (state.value.day?.length && state.value.day.length < COUNT_DAY_DIGITS) {
        newStateValue.day = `${+state.value.day || 1}`.padStart(COUNT_DAY_DIGITS, '0');
      }
    } else if (section === EDatePickerInputSection.month) {
      if (state.value.month?.length && state.value.month.length < COUNT_MONTH_DIGITS) {
        newStateValue.month = `${+state.value.month || 1}`.padStart(COUNT_MONTH_DIGITS, '0');
      }
    } else if (section === EDatePickerInputSection.year) {
      if (state.value.year?.length && state.value.year.length < COUNT_YEAR_DIGITS) {
        newStateValue.year = `${+state.value.year || 1}`.padStart(COUNT_YEAR_DIGITS, '0');
      }
    }

    emitNewDateState({
      newDateState: newStateValue,
      currentDateState: state.value,
    });
  };

  const handleSectionSelect = (section: EDatePickerInputSection) => {
    if (isInputDisabled.value) return;

    if (selectedSection.value) {
      formatAndChangeSectionValue(selectedSection.value);
    }

    selectedSection.value = section;
  };

  const resetSelectedSection = () => {
    selectedSection.value = null;
  };

  /** Перемещение между секциями в зависимости от направления */
  const moveSelectedSection = (direction: EMoveDirection) => {
    if (direction === EMoveDirection.left) {
      if (selectedSection.value === EDatePickerInputSection.year) {
        selectedSection.value = EDatePickerInputSection.month;
      } else if (selectedSection.value === EDatePickerInputSection.month) {
        selectedSection.value = EDatePickerInputSection.day;
      }
    } else if (direction === EMoveDirection.right) {
      if (selectedSection.value === EDatePickerInputSection.day) {
        selectedSection.value = EDatePickerInputSection.month;
      } else if (selectedSection.value === EDatePickerInputSection.month) {
        selectedSection.value = EDatePickerInputSection.year;
      }
    }
  };

  /** Обработчик ввода цифр */
  const handleNumberPress = (enteredNumber: number) => {
    const {
      day: stateDay,
      month: stateMonth,
      year: stateYear,
    } = state.value;

    const newStateValue: TDateSections = { ...state.value };

    if (selectedSection.value === EDatePickerInputSection.day) {
      let newDay = '';

      /** Если значение дня не заполнено или заполнено полностью */
      if (!stateDay?.length || stateDay.length === COUNT_DAY_DIGITS) {
        // умножив введенную цифру на 10, выясняем, может ли быть данная цифра во втором разряде даты
        if (enteredNumber * 10 > daysCountInMonth.value) {
          newDay = `${enteredNumber}`.padStart(COUNT_DAY_DIGITS, '0');

          selectedSection.value = EDatePickerInputSection.month;
        } else {
          newDay = `${enteredNumber}`;
        }
      /** значение дня заполнено частично */
      } else {
        newDay = `${stateDay}${enteredNumber}`;
        if (+newDay > daysCountInMonth.value || +newDay === 0) return;

        selectedSection.value = EDatePickerInputSection.month;
      }

      newStateValue.day = newDay;
    } else if (selectedSection.value === EDatePickerInputSection.month) {
      let newMonth = '';

      /** Если значение месяца не заполнено или заполнено полностью */
      if (!stateMonth?.length || stateMonth.length === COUNT_MONTH_DIGITS) {
        if (enteredNumber * 10 > COUNT_MONTHS_IN_YEAR) {
          newMonth = `${enteredNumber}`.padStart(COUNT_MONTH_DIGITS, '0');

          selectedSection.value = EDatePickerInputSection.year;
        } else {
          newMonth = `${enteredNumber}`;
        }
      /** значение месяца заполнено частично */
      } else {
        newMonth = `${stateMonth}${enteredNumber}`;

        if (+newMonth > COUNT_MONTHS_IN_YEAR || +newMonth === 0) return;

        selectedSection.value = EDatePickerInputSection.year;
      }

      newStateValue.month = newMonth;
    } else if (selectedSection.value === EDatePickerInputSection.year) {
      let newYear = '';

      /** Значение года заполнено частично */
      if (!!stateYear?.length && stateYear.length < COUNT_YEAR_DIGITS) {
        newYear = `${stateYear}${enteredNumber}`;
      } else {
        newYear = `${enteredNumber}`;
      }

      /** Проверка на значение year = 0000 */
      if (newYear.length === COUNT_YEAR_DIGITS && +newYear === 0) return;

      newStateValue.year = newYear;
    }

    emitNewDateState({
      newDateState: newStateValue,
      currentDateState: state.value,
    });
  };

  /** Обработчик нажатия кнопки Delete */
  const handleDeletePress = () => {
    if (!selectedSection.value) return;

    const newStateValue: TDateSections = {
      ...state.value,
      [selectedSection.value]: null,
    };

    emitNewDateState({
      newDateState: newStateValue,
      currentDateState: state.value,
    });
  };

  /** Обработчик нажатия кнопки Backspace */
  const handleBackspacePress = () => {
    if (!selectedSection.value) return;

    const value = state.value[selectedSection.value];

    if (!value?.length) {
      moveSelectedSection(EMoveDirection.left);

      return;
    }

    const newStateValue: TDateSections = {
      ...state.value,
      [selectedSection.value]: value.slice(0, -1),
    };

    emitNewDateState({
      newDateState: newStateValue,
      currentDateState: state.value,
    });
  };

  /** Обработчик нажатия кнопок вверх/вниз */
  const handleArrowDownOrUpPress = ({
    arrowKeyCode,
    isKeyPressed = false,
  }: THandleArrowDownOrUpPressArguments) => {
    // Если мин/макс даты не установлены, то управление стрелками вверх/вниз не доступно
    if (!minDate.value || !maxDate.value) return;

    const dateDiffBeforeCurrentAndDefaultMin = getDateDiff({
      firstDate: dateFromState.value,
      secondDate: minDate.value,
      unit: 'day',
      float: true,
    });

    // Если один из фрагментов даты не установлен
    if (!state.value.day || !state.value.month || !state.value.year
      // или из фрагментов не возможно сформировать дату
      || !dateFromState.value
      // или если текущая выбранная дата меньше минимально возможной даты
      || dateDiffBeforeCurrentAndDefaultMin < 0) {
      if (!minDateSections.value) return;

      emitNewDateState({
        newDateState: { ...minDateSections.value },
        currentDateState: state.value,
      });

      return;
    }

    // если текущая выбранная дата больше максимально возможной даты
    if (getIsDateGreaterThan(dateFromState.value, maxDate.value, { precision: 'day' })) {
      if (!maxDateSections.value) return;

      emitNewDateState({
        newDateState: { ...maxDateSections.value },
        currentDateState: state.value,
      });

      return;
    }

    // если текущая выбранная дата - максимально или минимальна возможная
    if ((arrowKeyCode === EKeyboardCode.arrowUp && getIsSameDate(dateFromState.value, maxDate.value))
    || (arrowKeyCode === EKeyboardCode.arrowDown && getIsSameDate(dateFromState.value, minDate.value))
    ) return;

    if (!minDateSections.value?.year || !maxDateSections.value?.year) return;

    const { day, month, year } = state.value;

    let newDayValue = +day;
    let newMonthValue = +month;
    let newYearValue = +year;

    if (arrowKeyCode === EKeyboardCode.arrowUp) {
      if (selectedSection.value === EDatePickerInputSection.day) {
        newDayValue = +newDayValue >= daysCountInMonth.value ? 1 : +newDayValue + 1;
      } else if (selectedSection.value === EDatePickerInputSection.month) {
        newMonthValue = +newMonthValue === COUNT_MONTHS_IN_YEAR ? 1 : +newMonthValue + 1;
      } else if (selectedSection.value === EDatePickerInputSection.year) {
        newYearValue = +newYearValue === +maxDateSections.value.year ? +newYearValue : +newYearValue + 1;
      }
    } else if (arrowKeyCode === EKeyboardCode.arrowDown) {
      if (selectedSection.value === EDatePickerInputSection.day) {
        newDayValue = +newDayValue === 1 ? daysCountInMonth.value : +newDayValue - 1;
      } else if (selectedSection.value === EDatePickerInputSection.month) {
        newMonthValue = +newMonthValue === 1 ? COUNT_MONTHS_IN_YEAR : +newMonthValue - 1;
      } else if (selectedSection.value === EDatePickerInputSection.year) {
        newYearValue = +newYearValue === +minDateSections.value.year ? +newYearValue : +newYearValue - 1;
      }
    }

    const date = {
      day: `${newDayValue}`,
      month: `${newMonthValue}`,
      year: `${newYearValue}`,
    };
    const formattedDate = getFormattedDate(date);

    // Если полученная дата не входит в допустимый к выбору диапазон дат выходим
    if (!getIsDateBetweenOrSame(formattedDate, minDate.value, maxDate.value)) return;

    const newStateValue: TDateSections = {
      day: `${newDayValue}`.padStart(COUNT_DAY_DIGITS, '0'),
      month: `${newMonthValue}`.padStart(COUNT_MONTH_DIGITS, '0'),
      year: `${newYearValue}`.padStart(COUNT_YEAR_DIGITS, '0'),
    };

    emitNewDateState({
      newDateState: newStateValue,
      currentDateState: state.value,
      isKeyPressed,
    });
  };

  const handleArrowDownOrUpPressWithThrottle = throttle(handleArrowDownOrUpPress, DELAY_FOR_KEY_PRESS, {
    leading: true,
    trailing: true,
  });

  const handleKeydown = (event: KeyboardEvent) => {
    if (event.metaKey || event.shiftKey || event.ctrlKey || event.altKey || event.code === EKeyboardCode.tab) return;

    const { key, code, repeat: eventRepeat } = event;

    switch (code) {
      case EKeyboardCode.comma:
      case EKeyboardCode.period:
      case EKeyboardCode.space:
        if (!selectedSection.value || selectedSection.value === EDatePickerInputSection.year) return;

        formatAndChangeSectionValue(selectedSection.value);

        if (selectedSection.value === EDatePickerInputSection.day && state.value.day) {
          selectedSection.value = EDatePickerInputSection.month;
        } else if (selectedSection.value === EDatePickerInputSection.month && state.value.month) {
          selectedSection.value = EDatePickerInputSection.year;
        }

        break;
      case EKeyboardCode.backspace:
        handleBackspacePress();

        break;
      case EKeyboardCode.delete:
        handleDeletePress();

        break;
      case EKeyboardCode.arrowLeft:
        if (!selectedSection.value || selectedSection.value === EDatePickerInputSection.day) return;

        formatAndChangeSectionValue(selectedSection.value);
        moveSelectedSection(EMoveDirection.left);

        break;
      case EKeyboardCode.arrowRight:
        if (!selectedSection.value || selectedSection.value === EDatePickerInputSection.year) return;

        formatAndChangeSectionValue(selectedSection.value);
        moveSelectedSection(EMoveDirection.right);

        break;
      case EKeyboardCode.arrowUp:
      case EKeyboardCode.arrowDown:

        handleArrowDownOrUpPressWithThrottle({
          arrowKeyCode: code,
          isKeyPressed: eventRepeat,
        });

        break;
      default:
        if (!Number.isNaN(Number(key))) {
          handleNumberPress(+key);
        }

        event.preventDefault();
    }
  };

  /** Обработка вставки значения */
  const handlePaste = (event: ClipboardEvent) => {
    event.preventDefault();

    const pastedText = event.clipboardData?.getData('text').trim();

    if (!pastedText || !REGEX_DATE.test(pastedText)) return;

    const regexExecResult = REGEX_DATE.exec(pastedText);

    if (!regexExecResult) return;

    const [_, pastedDay, pastedMonth, pastedYear] = regexExecResult;

    const currentDate = getDateByStartOfPart(getCurrentDayJsDate(), 'day');

    const month = Number.isNaN(+pastedMonth) || !+pastedMonth || +pastedMonth > COUNT_MONTHS_IN_YEAR
      ? getMonthsFromDate(currentDate) + 1
      : pastedMonth;

    const year = Number.isNaN(+pastedYear) || !+pastedYear
      ? getYearsFromDate(currentDate)
      : pastedYear;

    // День формируем после месяца и года, для того чтобы в случае невалидного значения, получить максимально возможный день для месяца и года (28 и 29 февраля и 30/31)
    const day = Number.isNaN(+pastedDay) || !+pastedDay || !isValidDate({
      date: `${year}-${month}-${pastedDay}`,
      format: EDatePickerValueFormat.UTCformat,
      strict: true,
    })
      ? getDaysCountInCurrentMonth(`${year}-${month}-01`) || 1
      : pastedDay;

    const newStateValue: TDateSections = {
      day: `${day}`.padStart(COUNT_DAY_DIGITS, '0'),
      month: `${month}`.padStart(COUNT_MONTH_DIGITS, '0'),
      year: `${year}`.padStart(COUNT_YEAR_DIGITS, '0'),
    };

    emitNewDateState({
      newDateState: newStateValue,
      currentDateState: state.value,
    });
  };

  const focus = () => {
    inputRef.value?.focus({ preventScroll: true });
  };

  watch(isInputActive, () => {
    if (isInputActive.value) {
      document.addEventListener('keydown', handleKeydown);
      document.addEventListener('paste', handlePaste);

      if (!selectedSection.value) {
        selectedSection.value = EDatePickerInputSection.day;
      }
    } else {
      document.removeEventListener('keydown', handleKeydown);
      document.removeEventListener('paste', handlePaste);

      resetSelectedSection();
    }
  });

  return {
    selectedSection,

    focus,
    handleSectionSelect,
    resetSelectedSection,
  };
};
