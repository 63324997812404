import {
  computed,
  watch,
} from 'vue';

import { EExperimentalFeatures } from '@/domains/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { hasCargoOwnerWorkplaceSubscription, hasCarrierWorkplaceSubscription } from '@/domains/permissions/subscription';
/** TODO: удалить вместе с FF piniaMigrationStoreTenants */
import { useUserStore } from '@/stores';
import { useUserStore as useUserStoreNew } from '@/stores/user';

export const useAppTestograf = () => {
  const SURVEY_ID = 581572;
  const SURVEY_CARGO_OWNER_ID = 591349;
  const SURVEY_WIDGET_CLASS = 'ttgraf-widget-trigger';
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  /** TODO: заменить useUserStore > useUserStore() при выпиле FF piniaMigrationStoreTenants */
  const userStore = isPiniaMigrationStoreTenantsAvailable.value
    ? useUserStoreNew()
    : useUserStore;

  const isSurveyVisible = import.meta.env.VITE_APP_TESTOGRAF_ENABLED === 'true';

  const isTestografAvailable = computed(() => checkFeatureIsEnabled(EExperimentalFeatures.testograf));
  const isTestografCargoOwnerAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.testografCargoOwner));

  const setSurveyById = (id: number) => {
    if (isSurveyVisible && window.ttgrafSetWidgetParams) {
      window.ttgrafSetWidgetParams({ id });
    }
  };

  const removeSurveyWidget = () => {
    const surveyWidget = document.querySelector(`.${SURVEY_WIDGET_CLASS}`);
    if (surveyWidget) {
      surveyWidget.remove();
    }
  };

  const removeSurveyById = (id: number) => {
    const surveyPopup = document.getElementById(`ttp-${id}`);
    if (surveyPopup) {
      surveyPopup.remove();
    }
  };

  const setSurveyParams = () => {
    window.testograf = {
      params: {
        e: userStore.user?.email,
        phone: userStore.user?.phone,
      },
    };
  };

  watch([isTestografAvailable, isTestografCargoOwnerAvailable], () => {
    removeSurveyById(SURVEY_CARGO_OWNER_ID);
    removeSurveyById(SURVEY_ID);
    removeSurveyWidget();
    if (isTestografAvailable.value && hasCarrierWorkplaceSubscription()) {
      setSurveyById(SURVEY_ID);
    }
    if (isTestografCargoOwnerAvailable.value && hasCargoOwnerWorkplaceSubscription()) {
      setSurveyById(SURVEY_CARGO_OWNER_ID);
    }
  }, { immediate: true });

  watch(() => userStore.user, setSurveyParams);
};
