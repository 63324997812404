import { computed, watch } from 'vue';

import useStore from '@/store/useStore';
import useWebsocketChannel from '@/composables/useWebsocketChannel';
import { EBillingBalanceEventType } from '@/contexts/billingContext/domain/constants';
import { useWalletStore } from '@/stores/wallet';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { canBrowseWalletsByRole } from '@/domains/permissions/role/wallets';
import { hasCarrierStarterWorkplaceSubscription, hasCarrierWorkplaceSubscription } from '@/domains/permissions/subscription';
import { useTenantsStore } from '@/stores/tenants';

type TWebsocketMessagePayload = {
  payload: Record<string, string> | null,
  type: EBillingBalanceEventType,
  id: string,
};

const useTenantBalanceWsUtils = () => {
  const store = useStore();
  const walletStore = useWalletStore();
  const tenantsStore = useTenantsStore();

  const isBillingWalletAvailable = computed(() => checkFeatureIsEnabled(EExperimentalFeatures.billingWallet));
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  const canFetchWalletInfo = computed(() => isBillingWalletAvailable.value
    && canBrowseWalletsByRole()
    && (hasCarrierWorkplaceSubscription() || hasCarrierStarterWorkplaceSubscription()),
  );

  const handleNewMessage = (message: TWebsocketMessagePayload) => {
    if (!message) return;
    walletStore.loadWallet();
  };

  const { connect, closeConnection } = useWebsocketChannel({
    options: { onMountInitialization: false },
    channelName: 'TenantBalanceChannel',
    onMessage: handleNewMessage,
  });

  const connectToTenantBalanceChannel = () => {
    connect();
  };

  const disconnectFromTenantBalanceChannel = () => {
    closeConnection();
  };

  const reconnectTenantBalanceChannel = () => {
    disconnectFromTenantBalanceChannel();
    connectToTenantBalanceChannel();
  };

  if (isPiniaMigrationStoreTenantsAvailable.value) {
    watch(() => tenantsStore.currentTenantDetails?.id, () => {
      if (!tenantsStore.currentTenantDetails?.id || !canFetchWalletInfo.value) return;
      reconnectTenantBalanceChannel();
    }, { immediate: true });
  } else {
    watch(() => store.state.tenants.currentTenantInfo?.id, () => {
      if (!store.state.tenants.currentTenantInfo?.id || !canFetchWalletInfo.value) return;
      reconnectTenantBalanceChannel();
    }, { immediate: true });
  }
};

export default useTenantBalanceWsUtils;
