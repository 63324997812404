import { createPinia, setActivePinia } from 'pinia';
import piniaPluginPersistedstate from 'pinia-plugin-persistedstate';

import { useUserStore as useGlobalUserStore } from './user/index';

const pinia = createPinia();
pinia.use(piniaPluginPersistedstate);

/* Использование setActivePinia необходимо, когда нам нужно использовать Pinia за пределами Vue-компонентов до инициализации приложения.
Это требуется для того, чтобы явно установить "активный" экземпляр Pinia,
так как без инициализации Vue-приложения контекст Pinia по умолчанию отсутствует. */
setActivePinia(pinia);

/** Инициализируем в этом файле те хранилища,
 * данные которых нам нужны еще ДО полной инициализации приложения(например, модули app, user, tenants).
 * Под инициализацией подразумеваются все процессы настройки приложения,
 * которые происходят до момента подключения Pinia в приложение[App.use(pinia) в файле src/main.ts)]
 * В идеале, однако, код нужно организовать таким образом, чтобы использование Pinia происходило только после её полной инициализации,
 * тогда setActivePinia не понадобится */

/** Глобальный модуль хранилища user */
const useUserStore = useGlobalUserStore();

/** TODO: Удалить весь файл srs/stores/index.ts вместе с FF piniaMigrationStoreTenants
 * Под FF выполнен переход на использование сторов без использования метода setActivePinia.
 * Поэтому на момент жизни FF будет существовать 2 экземпляра хранилища user,
 * а использоваться будет один из них в зависимости от FF
 */
export { pinia, useUserStore };
