<template>
  <div class="billing-wallet-link">
    <Button
      :type="EButtonType.link"
      :title="activeBalance"
      :size="ESize.small"
      @click.stop="handleRedirectButtonClick"
    />
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import { EButtonType, ESize } from '@/ui/types';
import { Button } from '@/ui';

import { useView } from './application/useView';

export default defineComponent({
  name: 'BillingWalletLink',
  components: { Button },
  setup() {
    const {
      activeBalance,

      handleRedirectButtonClick,
    } = useView();

    return {
      activeBalance,
      EButtonType,
      ESize,

      handleRedirectButtonClick,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
