import { useRoute } from 'vue-router';
import { computed } from 'vue';

import store from '@/store';
import { ROUTE_TYPE } from '@/constants';
import { EBillingPlanCode, EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';
import { TSubscription } from '@/types';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { useTenantsStore } from '@/stores/tenants';

export const getIsBillingPaywallMessageShown = (): boolean => {
  const route = useRoute();
  const tenantsStore = useTenantsStore();
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  const isPrivateRoute = route?.meta?.type === ROUTE_TYPE.private;
  const hasTenants = computed(() => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      return !!tenantsStore.userTenants?.length;
    }
    return !!store.state.tenants.userTenants?.length;
  });
  const hasInstanceLicense = !!store.state.app.appSettings?.licenseSettings;
  // TODO: удалить currentTenantSubscriptions вместе с FF piniaMigrationStoreTenants
  const currentTenantSubscriptions = store.state.tenants.currentTenantInfo?.subscriptions || [];
  const hasSubscriptionWithActiveStatus = computed(() => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      return !!tenantsStore.currentTenantDetails?.subscriptions
        .find((subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active);
    }
    return !!currentTenantSubscriptions
      .find((subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active);
  });

  const hasCarrierStartSubscription = computed(() => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      return !!tenantsStore.currentTenantDetails?.subscriptions
        .find((subscription: TSubscription) => subscription.planCode === EBillingPlanCode.carrierStart);
    }
    return !!currentTenantSubscriptions
      .find((subscription: TSubscription) => subscription.planCode === EBillingPlanCode.carrierStart);
  });

  // инстанс может работать либо по лицензии, либо с биллингом
  return !hasInstanceLicense
    && hasTenants.value
    && isPrivateRoute
    && (!hasSubscriptionWithActiveStatus.value || hasCarrierStartSubscription.value);
};
