<template>
  <component
    :is="hasDropdownSlot ? 'button' : 'div'"
    ref="statusRef"
    class="status"
    :class="extraClasses"
    @click="handleDropdownToggle"
  >
    <div
      v-if="hasIconSlot"
      test-id="status-icon-wrapper"
      :class="`status__icon status__icon_${type}`"
    >
      <slot name="icon" />
    </div>
    <div
      v-else
      test-id="status-dot"
      :class="`status__dot status__dot_${type}`"
    />
    <Tooltip
      v-if="text"
      :title="tooltipTitle"
      :visible="isTooltipVisible"
    >
      <span
        ref="statusTextRef"
        class="status__text"
        :class="textExtraClasses"
      >
        {{ text }}
      </span>
    </Tooltip>
    <ChevronDownSvg v-if="hasDropdownSlot" :class="{ 'status__icon_down': isDropdownVisible }" />
    <slot />
    <Dropdown
      v-model:visible="isDropdownVisible"
      :targetElement="statusRef"
      :dropdownMatchSelectWidth="dropdownMatchSelectWidth"
      withoutOptions
    >
      <slot name="dropdown" />
    </Dropdown>
  </component>
</template>

<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  ref,
  toRefs,
} from 'vue';

import { EStatusType } from '@/domains/constants';
import { hasSlotContent } from '@/utils/hasSlotContent';
import ChevronDownSvg from '@/assets/svg/16x16/chevron-down.svg';
import { ESize } from '@/ui/types';

import Dropdown from '../Dropdown/index.vue';
import Tooltip from '../Tooltip/index.vue';
import { useTruncateText } from './application/useTruncateText';
import { useView } from './application/useView';

export default defineComponent({
  name: 'Status',
  components: {
    Tooltip,
    Dropdown,
    ChevronDownSvg,
  },
  props: {
    text: {
      type: [String, null] as PropType<string | null>,
      default: null,
    },
    type: {
      type: String as PropType<EStatusType>,
      default: EStatusType.default,
    },
    hasBackground: {
      type: Boolean,
      default: false,
    },
    tooltip: {
      type: String,
      default: '',
    },
    isDashed: {
      type: Boolean,
      default: false,
    },
    withOverflowTooltip: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.medium,
    },
    dropdownMatchSelectWidth: {
      type: [Boolean, Number] as PropType<boolean | number>,
      default: false,
    },
  },
  setup(props, { slots }) {
    const {
      type,
      hasBackground,
      isDashed,
      text,
      tooltip,
      withOverflowTooltip,
      size,
    } = toRefs(props);

    const statusTextRef = ref<HTMLElement | null>(null);
    const statusRef = ref<HTMLDivElement | null>(null);
    const isDropdownVisible = ref<boolean>(false);

    const hasDropdownSlot = computed(() => hasSlotContent({
      slots,
      slotName: 'dropdown',
    }));

    const handleDropdownToggle = () => {
      if (hasDropdownSlot.value) {
        isDropdownVisible.value = !isDropdownVisible.value;
      }
    };

    const { isTextOverflowing } = useTruncateText({
      statusTextRef,
      withOverflowTooltip,
    });

    const {
      extraClasses,
      isTooltipVisible,
      textExtraClasses,
      tooltipTitle,
    } = useView({
      hasBackground,
      isDashed,
      isTextOverflowing,
      text,
      tooltip,
      type,
      withOverflowTooltip,
      size,
    });

    const hasIconSlot = computed(() => hasSlotContent({
      slots,
      slotName: 'icon',
    }));

    return {
      extraClasses,
      isTooltipVisible,
      hasIconSlot,
      statusTextRef,
      textExtraClasses,
      tooltipTitle,
      isDropdownVisible,
      statusRef,
      hasDropdownSlot,

      handleDropdownToggle,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
