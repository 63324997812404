import {
  Ref,
  computed,
} from 'vue';

import { EStatusType } from '@/domains/constants';
import { ESize } from '@/ui/types';

type TUseView = {
  hasBackground: Ref<boolean>,
  isDashed: Ref<boolean>,
  isTextOverflowing: Ref<boolean>,
  text: Ref<string | null>,
  tooltip: Ref<string>,
  type: Ref<EStatusType>,
  withOverflowTooltip: Ref<boolean>,
  size: Ref<ESize>,
};

export const useView = ({
  hasBackground,
  isDashed,
  isTextOverflowing,
  text,
  tooltip,
  type,
  withOverflowTooltip,
  size,
}: TUseView) => {
  const extraClasses = computed(() => (hasBackground.value
    ? `status_${type.value} status__size_${size.value}`
    : ''),
  );

  const tooltipTitle = computed(() => (withOverflowTooltip.value && text.value
    ? text.value
    : tooltip.value
  ));

  const isTooltipVisible = computed(() => (!!tooltipTitle.value && !hasBackground.value)
    || (withOverflowTooltip.value && isTextOverflowing.value));

  const textExtraClasses = computed(() => ({
    status__text_underline: !!tooltip.value && !hasBackground.value,
    status__text_dashed: isDashed.value,
    [`status__text_size-${size.value}`]: !hasBackground.value,
  }));

  return {
    extraClasses,
    isTooltipVisible,
    textExtraClasses,
    tooltipTitle,
  };
};
