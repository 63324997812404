export enum ETenantTaxType {
  osno = 'osno',
  usn = 'usn',
}

export enum ETenantPriceViewMode {
  with_vat = 'with_vat',
  without_vat = 'without_vat',
  both = 'both',
}

export enum EAccountingRouteName {
  signUp = 'signUp',
  signIn = 'signIn',
  forgotPassword = 'forgotPassword',
  resetPassword = 'resetPassword',
  account = 'account',
  tenant = 'tenant',
  tenantSettings = 'tenantSettings',
  tenantIntegrations = 'tenantIntegrations',
  tenantAdditionalFeatures = 'tenantAdditionalFeatures',
  tenantSettingsFinance = 'tenantSettingsFinance',
}

export const EDM_GUIDE_LINK_FOR_EXECUTOR = 'https://confluence.trucker.group/manual/8-elektronnyj-dokumentooborot-2519716278.html';
export const EDM_GUIDE_LINK_FOR_CUSTOMER = 'https://confluence.trucker.group/manual/11-elektronnyj-dokumentooborot-2519716565.html';

export enum ELegalEntityPayload {
  name = 'name',
  inn = 'inn',
  kpp = 'kpp',
  taxType = 'taxType',
  phone = 'phone',
  legalAddress = 'legalAddress',
  priceViewMode = 'priceViewMode',
  edmAvailable = 'edmAvailable',
  invoicingEmail = 'invoicingEmail',
}

export enum EBankModelFields {
  bankName = 'bankName',
  bankBik = 'bankBik',
  bankAddress = 'bankAddress',
  bankInn = 'bankInn',
  bankKpp = 'bankKpp',
  bankCorrespondentAccount = 'bankCorrespondentAccount',
}

export enum EBankAccountFields {
  name = 'name',
  bik = 'bik',
  address = 'address',
  inn = 'inn',
  kpp = 'kpp',
  correspondentAccount = 'correspondentAccount',
  checkingAccount = 'checkingAccount',
}

export enum EEdmConnectionError {
  accountInvalidData = 'AccountInvalidData',
  credentialsInUseError = 'CredentialsInUseError',
  accountBelongsOtherOrg = 'AccountBelongsOtherOrg',
  organizationBlocked = 'OrganizationBlocked',
  apiRestricted = 'ApiRestricted',
  serverError = 'ServerError',
  integrationError = 'IntegrationError',
}

/**
 * Тип договора-оферты в зависимости от типа подписки организации
 */
export enum EPublicOfferType {
  /** Для подписки на кабинет ГВ */
  cargoOwner = 'cargo_owner',
  /** Для подписки на кабинет ГП или кабинет ГО/ГП */
  carrier = 'carrier',
}

/**
 * Виды ролей организации по лицензии
 */
export enum ELicenseRole {
  /** Тенант работает как ГВ */
  cargoOwner = 'cargo_owner',
  /** Тенант работает как ГП */
  carrier = 'carrier',
  /** Тенант работает как экспедитор */
  freightForwarder = 'freight_forwarder',
  /** Тенант работает как ГО/ГП */
  shipper = 'shipper',
}

export enum EEdmProviderItem {
  login = 'login',
  password = 'password',
  apikey = 'apikey',
}
