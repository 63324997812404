import { computed } from 'vue';

import useViewState from '@/composables/useViewState';
import { confirmReadingSpecialOffer } from '@/contexts/billingContext/services';
import { showErrorNotification } from '@/utils';
import useStore from '@/store/useStore';
import { TSubscription } from '@/types';
import { EBillingPlanCodeStatus } from '@/contexts/billingContext/domain/constants';
import { useTenantsStore } from '@/stores/tenants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';

export const useConfirmReadingSpecialOffer = () => {
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));
  const store = useStore();
  const tenantsStore = useTenantsStore();

  const {
    isLoading,
    setViewStateAs,
  } = useViewState();

  const activeSubscription = computed(() => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      return tenantsStore.currentTenantDetails?.subscriptions
        .find((subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active) || null;
    }
    return store.state.tenants.currentTenantInfo?.subscriptions?.find(
      (subscription: TSubscription) => subscription.status === EBillingPlanCodeStatus.active) || null;
  });

  const specialOffer = computed(() => activeSubscription.value?.specialOffer);

  const handleSpecialOfferReadingConfirm = () => {
    if (!!specialOffer.value?.confirmedAt || isLoading.value) return;

    setViewStateAs.loading();

    confirmReadingSpecialOffer()
      .then(() => {
        if (isPiniaMigrationStoreTenantsAvailable.value) {
          tenantsStore.loadTenantInfo();
          // TODO: удалить вместе с FF piniaMigrationStoreTenants
        } else {
          store.dispatch('tenants/fetchCurrentTenantInfo');
        }
      })
      .catch(showErrorNotification)
      .finally(setViewStateAs.done);
  };

  return {
    isLoading,
    specialOffer,

    handleSpecialOfferReadingConfirm,
  };
};
