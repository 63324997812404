import colorsPalette from '@/ui/tokens/colors/colors-palette.json';

export const THEMES = {
  apl: {
    ant: {
      primaryColor: '#00889A',
      errorColor: '#FB8FA2',
      warningColor: '#FAAD14',
      successColor: '#33D2AC',
      infoColor: '#00889A',
    },
    icons: {
      logo: 'apl',
      favicon: 'apl-favicon.svg',
      faviconNotification: 'apl-favicon-notification.svg',
    },
    variables: '_variables_apl.scss',
    welcomeLoaderProgress: {
      start: '#017280',
      end: '#A3D3D8',
    },
    jsColors: {
      ...colorsPalette.apl,
      ...colorsPalette.common,
    },
    support: {
      phone: '8 800 444 04 32',
      email: 'apex@autoprologistic.ru',
    },
    links: {
      termsOfUse: 'docs/apl/terms.html',
      privacyPolicies: 'docs/apl/privacy.html',
    },
    isInformationPanelEnabled: false,
  },
  default: {
    ant: {
      primaryColor: '#0066FF',
      warningColor: '#FAAD14',
    },
    icons: {
      logo: 'trucker-tms-dark',
      favicon: 'favicon.svg',
      faviconNotification: 'favicon-notification.svg',
    },
    variables: '_variables_default.scss',
    welcomeLoaderProgress: {
      start: '#096dd9',
      end: '#91d5ff',
    },
    jsColors: {
      ...colorsPalette.default,
      ...colorsPalette.common,
    },
    support: {
      phone: '8 800 444 04 32',
      email: 'help@trucker.group',
    },
    links: {
      termsOfUse: '/docs/default/terms.pdf',
      privacyPolicies: '/docs/default/privacy.pdf',
    },
    isInformationPanelEnabled: true,
  },
};
