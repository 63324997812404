<template>
  <li
    class="dropdown-item"
    role="option"
    tabindex="-1"
    :aria-selected="selected"
    :class="className"
    @mousedown.prevent.stop
    @click.prevent="onClick"
    @focus="$emit('focus', $event)"
    @blur="$emit('blur', $event)"
  >
    <template v-if="!hasSlot">
      <div class="dropdown-item__flex-container">
        <Checkbox
          v-if="withCheckbox"
          class="dropdown-item__checkbox"
          :checked="selected"
          :disabled="disabled"
        />
        <div class="dropdown-item__row-container">
          <div class="dropdown-item__flex-row">
            <div class="dropdown-item__flex-row">
              <span v-if="canShowFullLabel" class="dropdown-item__title_full-label">
                {{ stringValue }}
              </span>
              <Tooltip
                v-else
                :visible="isTextEllipsed"
                :title="stringValue"
                :placement="EPopoverPlacement.right"
              >
                <span
                  ref="tooltipTextRef"
                  class="dropdown-item__title"
                >
                  {{ stringValue }}
                </span>
              </Tooltip>
              <slot name="icon" />
            </div>
            <div class="dropdown-item__flex-row dropdown-item__info">
              <CheckSvg
                v-if="isCheckIconVisible"
                class="dropdown-item__check-icon"
              />
              <span
                v-if="info"
                class="dropdown-item__info-text"
              >
                {{ info }}
              </span>
            </div>
          </div>
          <div
            v-if="subTitle || subInfo"
            class="dropdown-item__flex-row dropdown-item__additional-info"
          >
            <span v-if="subTitle">
              {{ subTitle }}
            </span>
            <span v-if="subInfo">
              {{ subInfo }}
            </span>
          </div>
        </div>
      </div>
    </template>
    <slot v-else />
  </li>
</template>
<script lang="ts">
import {
  PropType,
  computed,
  defineComponent,
  ref,
} from 'vue';

import {
  EPopoverPlacement,
  TRawSelectValue,
  TSelectOption,
} from '@/ui/types';
import CheckSvg from '@/assets/svg/16x16/check.svg';
import Tooltip from '@/ui/Tooltip/index.vue';
import Checkbox from '@/ui/Checkbox/index.vue';
import { hasSlotContent } from '@/utils/hasSlotContent';

export default defineComponent({
  name: 'DropdownItem',
  components: {
    Checkbox,
    Tooltip,
    CheckSvg,
  },
  props: {
    value: {
      type: [String, Number] as PropType<TRawSelectValue>,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    selected: {
      type: Boolean,
      default: false,
    },
    isEmpty: {
      type: Boolean,
      default: false,
    },
    withCheckbox: {
      type: Boolean,
      default: false,
    },
    info: {
      type: String,
      default: '',
    },
    subTitle: {
      type: String,
      default: '',
    },
    subInfo: {
      type: String,
      default: '',
    },
    isFullLabelVisible: {
      type: Boolean,
      default: false,
    },
    options: {
      type: Array as PropType<TSelectOption[]>,
      default: () => [],
    },
  },
  emits: [
    'click',
    'focus',
    'blur',
  ],
  setup(props, { emit, slots }) {
    const hasSlot = computed(() => hasSlotContent({
      slots,
      slotName: 'default',
    }));

    const hasIconSlot = computed(() => hasSlotContent({
      slots,
      slotName: 'icon',
    }));

    const tooltipTextRef = ref<HTMLElement>();

    const className = computed(() => {
      let resultClassName = props.disabled ? 'dropdown-item_disabled' : '';

      if (props.selected) {
        resultClassName += ' dropdown-item_selected';
      }

      if (props.isEmpty) {
        resultClassName += ' dropdown-item_empty';
      }

      return resultClassName;
    });

    const hasItemContent = computed(() => props.info || props.subInfo || hasIconSlot.value || props.subTitle);

    const canShowFullLabel = computed(() => props.isFullLabelVisible && !hasItemContent.value);

    const isCheckIconVisible = computed(() => props.selected && !props.withCheckbox && !props.info);

    const stringValue = computed(() => String(props.value));

    const isTextEllipsed = computed(() => {
      if (!tooltipTextRef.value) return true;
      return tooltipTextRef.value.offsetWidth < tooltipTextRef.value.scrollWidth;
    });

    const onClick = () => {
      if (!props.disabled && !props.isEmpty) {
        emit('click');
      }
    };

    return {
      EPopoverPlacement,
      className,
      canShowFullLabel,
      hasSlot,
      isCheckIconVisible,
      stringValue,
      isTextEllipsed,
      tooltipTextRef,

      onClick,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
