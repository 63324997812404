<template>
  <CustomModal
    class="billing-insufficient-funds-modal"
    visible
    :width="432"
    :closable="false"
    centered
  >
    <p class="billing-insufficient-funds-modal__title">
      {{ tt('billing.insufficientFundsModal.thereAreInsufficientFundsInAccount') }}
    </p>

    <p
      class="billing-insufficient-funds-modal__text"
    >
      {{ tt('billing.insufficientFundsModal.toUseFunctionalityTopUpYourAccount', {
        param: {
          missingAmount: formatNumberAsCurrency(missingAmount, 0, {
            separator: ' ',
            symbol: tt('shared.currency.symbol.rub'),
            pattern: '# !'
          })
        }
      }) }}
    </p>

    <div
      class="billing-insufficient-funds-modal__footer"
    >
      <Tooltip
        :title="tt('billing.insufficientFundsModal.accessRestrictionTitle')"
        :visible="!canBrowseTenantBaseSettingsByRole"
      >
        <!-- Этот div необходим потому что при disabled у кнопки tooltip не отображается без него -->
        <div>
          <Button
            :type="EButtonType.primary"
            :title="tt('billing.insufficientFundsModal.topUp')"
            :disabled="!canBrowseTenantBaseSettingsByRole"
            @click="handleRedirectButtonClick"
          />
        </div>
      </Tooltip>
      <Button
        :type="EButtonType.secondary"
        :title="tt('shared.action.cancel')"
        @click="() => setInsufficientFunds(null)"
      />
    </div>
  </CustomModal>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

import tt from '@/i18n/utils/translateText';
import { Button, Tooltip } from '@/ui';
import { EButtonType } from '@/ui/types';
import CustomModal from '@/components/CustomModal/index.vue';
import { formatNumberAsCurrency } from '@/utils';
import { canBrowseTenantBaseSettingsByRole } from '@/domains/permissions/role/tenantBaseSettings';

import { useView } from './application/useView';

export default defineComponent({
  name: 'BillingInsufficientFundsModal',
  components: {
    CustomModal,
    Button,
    Tooltip,
  },
  emits: ['close'],
  setup() {
    const {
      missingAmount,
      handleRedirectButtonClick,
      setInsufficientFunds,
    } = useView();

    return {
      EButtonType,
      missingAmount,
      canBrowseTenantBaseSettingsByRole,

      handleRedirectButtonClick,
      formatNumberAsCurrency,
      setInsufficientFunds,
      tt,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
