import { EmployeeViewSettingsField } from '@/domains/constants';
import { TReferenceDataCountry } from '@/types';

import { TTenantsState } from './types';

/** Перенесен */
export const availableUserTenants = (state: TTenantsState) => state.userTenants;

/** Перенесен */
export const currentTenant = (state: TTenantsState) => state.currentTenant;

/** Перенесен */
export const currentTenantId = (state: TTenantsState) => state.currentTenant?.id;

/** Перенесен */
export const currentTenantInfo = (state: TTenantsState) => state.currentTenantInfo;

/** Перенесен */
export const tenantLoading = (state: TTenantsState) => state.isLoading;

/** Перенесен */
export const complexityCheck = (state: TTenantsState) => state.currentTenantInfo?.settings?.passwordComplexitySettings?.complexityCheck;

/** Перенесен */
export const countriesWithoutRu = (state: TTenantsState) => {
  const { ids, data } = state.referenceData?.countries || {
    ids: [],
    data: {},
  };
  return ids?.reduce((result, current: string) => {
    if (current !== 'RU') {
      result.push(data[current]);
    }
    return result;
  }, [] as TReferenceDataCountry[]);
};

/** Удален */
export const viewSettingsTableColumns = (state: TTenantsState) => (
  { entity }: { entity: EmployeeViewSettingsField },
) => state.currentTenant?.employee?.viewSettings[entity];
