import { computed } from 'vue';

import { EUserSystemRole } from '@/constants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
/** TODO: удалить вместе с FF piniaMigrationStoreTenants */
import { useUserStore } from '@/stores';
import { useUserStore as useUserStoreNew } from '@/stores/user';

const isPiniaMigrationStoreTenantsAvailable = computed(
  () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

/** TODO: заменить useUserStore > useUserStore() при выпиле FF piniaMigrationStoreTenants */
const userStore = computed(() => (isPiniaMigrationStoreTenantsAvailable.value
  ? useUserStoreNew()
  : useUserStore));

const isCurrentUserAdmin = () => userStore.value.user?.systemRole === EUserSystemRole.admin;

export default isCurrentUserAdmin;
