import { defineStore } from 'pinia';
import { computed, ref } from 'vue';

import {
  createNewTenant,
  fetchTenantInfo,
  getReferenceData,
  getTenantsForCurrentUser,
} from '@/contexts/accountingContext/services';
import {
  TCurrentTenantInfoResponse,
  TTenantFormData,
  TTenantsResponse,
} from '@/contexts/accountingContext/domain/types';
import { TReachedResponse } from '@/api/types';
import {
  TErrorNotification,
  TReferenceDataCountry,
  TTenant,
} from '@/types';
import { showErrorNotification, normalizePayloadToDataIds } from '@/utils';
import logger from '@/logger';
import tt from '@/i18n/utils/translateText';

import { TTenantReferenceData, TTenantsStore } from './types';

export const useTenantsStore = defineStore('TMS_TENANTS', (): TTenantsStore => {
  const userTenants = ref<TTenantsResponse | null>(null);
  const currentTenant = ref<TTenant | null>(null);
  const currentTenantDetails = ref<TCurrentTenantInfoResponse | null>(null);
  const currentTenantReferenceData = ref<TTenantReferenceData | null>(null);
  const lastTenantId = ref<string | null>(null);
  const isTenantsLoading = ref<boolean>(false);

  /** Загрузить тенанты текущего пользователя */
  const loadTenants = async () => {
    isTenantsLoading.value = true;

    try {
      const response: TReachedResponse<TTenantsResponse> = await getTenantsForCurrentUser();
      const { data = [] } = response;

      userTenants.value = data;

      // При регистрации нового юзера у него еще нет организаций
      if (data.length) {
        /* Определяем выбранный тенант:
        * если в localStorage хранился выбранный тенант, то ищем его по ID и обновляем из ответа,
        * чтобы были актуальные данные по тенанту всегда в localStorage
        */
        if (currentTenant.value?.id) {
          lastTenantId.value = currentTenant.value.id;
          // Данные по тенанту всегда должны обновляться при запросе на /tenants
          currentTenant.value = data.find(
            (tenant: TTenant) => tenant.id === currentTenant.value?.id) || currentTenant.value;
        } else if (lastTenantId.value) {
        /** если в localStorage нет выбранного тенанта, но есть ID последнего выбранного,
         * то ищем его в списке всех тенантов и выбираем
         */
          const lastSelectedTenant = data.find((tenant: TTenant) => tenant.id === lastTenantId.value);
          currentTenant.value = lastSelectedTenant || data[0];
          lastTenantId.value = lastSelectedTenant?.id || data[0].id;
        } else {
        /** иначе выбираем первого тенанта из пришедшего списка */
        // eslint-disable-next-line prefer-destructuring
          currentTenant.value = data[0];
          lastTenantId.value = data[0].id;
        }
      }
    } catch (error) {
      showErrorNotification(error as TErrorNotification);
    } finally {
      isTenantsLoading.value = false;
    }
  };

  /** Загрузить подробную информацию о тенанте */
  const loadTenantInfo = async () => {
    if (!currentTenant.value?.id) {
      logger.warn('[fetchTenantInfo] Cannot fetch current tenant info. There is no current tenant in store.');
      return;
    }

    try {
      const response = await fetchTenantInfo(currentTenant.value.id);
      if (response?.data) {
        currentTenantDetails.value = response.data;
      }
    } catch (error) {
      logger.error('[fetchTenantInfo] Error fetching tenant info:', error);
      showErrorNotification({ message: tt('admin.shared.errors.tenantInfo') });
    }
  };

  /** Загрузить справочную информацию о тенанте */
  const loadTenantReferenceData = async () => {
    try {
      const response = await getReferenceData();
      if (response.data) {
        currentTenantReferenceData.value = {
          countries: normalizePayloadToDataIds(response.data.countries, 'isoCode'),
          federalDistricts: normalizePayloadToDataIds(response.data.federalDistricts, 'code'),
          regions: normalizePayloadToDataIds(response.data.regions, 'isoCode'),
        };
      }
    } catch (error) {
      showErrorNotification(error as TErrorNotification);
    }
  };

  /** Регистрация нового тенанта */
  const registerNewTenant = async (tenantAttributes: TTenantFormData) => {
    try {
      await createNewTenant({ tenantAttributes });
      await loadTenants();
    } catch (error) {
      showErrorNotification(error as TErrorNotification);
    }
  };

  const selectTenant = (tenant: TTenant) => {
    currentTenant.value = tenant;
    lastTenantId.value = tenant.id;
  };

  const getTenantComplexityCheck = computed(
    () => currentTenantDetails.value?.settings?.passwordComplexitySettings?.complexityCheck || null);

  const getCountriesWithoutRu = computed<TReferenceDataCountry[]>(() => {
    const { ids = [], data = {} } = currentTenantReferenceData.value?.countries || {};
    return ids.flatMap((id: string) => (id !== 'RU' ? data[id] : []));
  });

  const $reset = () => {
    userTenants.value = null;
    currentTenant.value = null;
    currentTenantDetails.value = null;
    currentTenantReferenceData.value = null;
  };

  return {
    userTenants,
    currentTenant,
    currentTenantDetails,
    currentTenantReferenceData,
    lastTenantId,
    isTenantsLoading,

    getTenantComplexityCheck,
    getCountriesWithoutRu,

    loadTenants,
    loadTenantInfo,
    loadTenantReferenceData,
    registerNewTenant,
    selectTenant,
    $reset,
  };
}, { persist: true });
