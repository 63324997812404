import { computed, ref } from 'vue';

import useWebsocketChannel from '@/composables/useWebsocketChannel';
import useStore from '@/store/useStore';
import { TWsChannelSubscribers } from '@/websocket/types';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { useTenantsStore } from '@/stores/tenants';

export const useTenantSubscriptionsWsChannel = (subscribers: TWsChannelSubscribers) => {
  const channelName = 'TenantSubscriptionsChannel';
  const store = useStore();
  const tenantsStore = useTenantsStore();
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));

  const employeeId = computed(() => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      return tenantsStore.currentTenant?.employee.id || null;
    }
    return store.getters['tenants/currentTenant']?.employee?.id || null;
  });

  /** Активное подключение к каналу */
  const hasActiveConnection = ref(false);

  const { closeConnection, connect } = useWebsocketChannel({
    channelName,
    subscribers,
    options: { onMountInitialization: false },
  });

  const connectToTenantSubscriptionsChannel = () => {
    if (hasActiveConnection.value) return;

    hasActiveConnection.value = true;

    connect({ employeeId: employeeId.value });
  };

  const closeTenantSubscriptionsChannelConnection = () => {
    if (!hasActiveConnection.value) return;

    hasActiveConnection.value = false;

    closeConnection();
  };

  return {
    connectToTenantSubscriptionsChannel,
    closeTenantSubscriptionsChannelConnection,
  };
};
