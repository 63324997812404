<template>
  <div class="info-block-item">
    <p class="info-block-item__label">
      {{ label }}

      <Tooltip
        v-if="infoTooltipTitle"
        :title="infoTooltipTitle"
      >
        <InfoSvg class="info-block-item__info-icon" />
      </Tooltip>
    </p>

    <div class="info-block-item__value">
      <slot>
        <template v-if="hasValue">
          <template v-if="Array.isArray(value)">
            <div
              v-for="item in value"
              :key="item"
              class="info-block-item__list_inline"
            >
              <Badge
                v-if="asBadge"
                :size="ESize.small"
              >
                {{ item }}
              </Badge>
              <span v-else>{{ item }}</span>
            </div>
          </template>

          <template v-else>
            <Badge
              v-if="asBadge"
              :size="ESize.small"
            >
              {{ value }}
            </Badge>
            <span v-else>{{ value }}</span>
          </template>
        </template>

        <EmptyLabel
          v-else
          class="info-block-item__empty"
          inTableCell
          :declension="declension"
        />
      </slot>
    </div>
  </div>
</template>

<script lang="ts">
import {
  defineComponent,
  PropType,
  computed,
} from 'vue';

import InfoSvg from '@/assets/svg/16x16/info.svg';
import {
  EmptyLabel,
  Tooltip,
  Badge,
} from '@/ui';
import { EDeclension, ESize } from '@/ui/types';

export default defineComponent({
  name: 'InfoBlockItem',
  components: {
    EmptyLabel,
    InfoSvg,
    Tooltip,
    Badge,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    value: {
      type: [String, Number, Array, null] as PropType<string | number | null | string[]>,
      default: null,
    },
    declension: {
      type: String as PropType<EDeclension>,
      default: EDeclension.neuter,
    },
    allowShowEmptyState: {
      type: Boolean,
      default: false,
    },
    infoTooltipTitle: {
      type: String,
      default: '',
    },
    asBadge: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const hasValue = computed(() => props.value || props.value === 0);

    return {
      hasValue,
      ESize,
    };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
