<template>
  <span
    class="badge"
    :class="`badge_${color} badge_${size}`"
  >
    <span
      v-if="hasIconSlot"
      class="badge__icon"
    >
      <slot name="icon" />
    </span>
    <slot />
  </span>
</template>

<script lang="ts">
import {
  computed,
  defineComponent,
  PropType,
} from 'vue';

import { ESize, EBadgeColor } from '@/ui/types';
import { hasSlotContent } from '@/utils/hasSlotContent';

export default defineComponent({
  name: 'Badge',
  props: {
    color: {
      type: String as PropType<EBadgeColor>,
      default: EBadgeColor.gray,
    },
    size: {
      type: String as PropType<ESize>,
      default: ESize.medium,
    },
  },
  setup(_, { slots }) {
    const hasIconSlot = computed(() => hasSlotContent({
      slots,
      slotName: 'icon',
    }));

    return { hasIconSlot };
  },
});
</script>

<style lang="scss" src="./styles.scss" />
