<template>
  <!--
    lockedSince - дата блокировки аккаунта за неудачные попытки входа.
    Если есть пользователь и дата, то показываем форму для разблокировки
  -->
  <template v-if="user && lockedSince">
    <Layout class="app-layout-wrapper">
      <UnlockView />
    </Layout>
  </template>
  <!--
    Если истек таймер валидности пароля и в сторе есть дата валидности пароля
    то выводим страницу обновления пароля
  -->
  <template v-else-if="isOTP || (!passwordExpirationTimer && passwordExpiresAt)">
    <UpdatePasswordView :isOTP="isOTP" />
  </template>

  <div
    v-else
    class="app-layout-wrapper"
  >
    <LicensePaywallMessage v-if="getIsLicensePaywallMessageShown()" />
    <BillingDebtPaywallMessage
      v-else-if="isBillingDebtPaywallMessageShown"
      @close="handleBillingDebtPaywallMessageVisibility"
    />
    <BillingSpecialOfferPaywallMessage v-else-if="getIsBillingSpecialOfferPaywallMessageShown()" />
    <BillingPaywallMessage v-else-if="getIsBillingPaywallMessageShown()" />
    <Layout :hasSider="hasSider">
      <AppLayoutSider v-if="hasSider" />
      <LicenseRestrictionLayout v-if="getIsLicenseRestrictionLayoutShown()" />
      <BillingRestrictionLayout v-else-if="getIsBillingRestrictionLayoutShown()" />
      <slot v-else />
    </Layout>
    <BillingInsufficientFundsModal
      v-if="isBillingInsufficientFundsModalVisible"
    />
  </div>

  <ActionProgress />
</template>

<script lang="ts">
import { Layout } from 'ant-design-vue';
import { defineComponent } from 'vue';

import ActionProgress from '@/components/ActionProgress/index.vue';
import BillingPaywallMessage from '@/contexts/billingContext/components/BillingPaywallMessage/index.vue';
import BillingDebtPaywallMessage from '@/contexts/billingContext/components/BillingDebtPaywallMessage/index.vue';
import BillingSpecialOfferPaywallMessage from '@/contexts/billingContext/components/BillingSpecialOfferPaywallMessage/index.vue';
import UpdatePasswordView from '@/views/Infrastructure/UpdatePasswordView/index.vue';
import UnlockView from '@/views/Infrastructure/UnlockView/index.vue';
import BillingRestrictionLayout from '@/contexts/billingContext/components/BillingRestrictionLayout/index.vue';
import LicensePaywallMessage from '@/contexts/accountingContext/components/LicensePaywallMessage/index.vue';
import LicenseRestrictionLayout from '@/contexts/accountingContext/components/LicenseRestrictionLayout/index.vue';
import { getIsLicensePaywallMessageShown } from '@/contexts/accountingContext/domain/getIsLicensePaywallMessageShown';
import { getIsBillingPaywallMessageShown } from '@/contexts/billingContext/domain/getIsBillingPaywallMessageShown';
import { getIsBillingSpecialOfferPaywallMessageShown } from '@/contexts/billingContext/domain/getIsBillingSpecialOfferPaywallMessageShown';
import { useChangeBillingDebtPaywallMessageVisibility } from '@/contexts/billingContext/application/useChangeBillingDebtPaywallMessageVisibility';
import { getIsBillingRestrictionLayoutShown } from '@/contexts/billingContext/domain/getIsBillingRestrictionLayoutShown';
import { getIsLicenseRestrictionLayoutShown } from '@/contexts/accountingContext/domain/getIsLicenseRestrictionLayoutShown';
import BillingInsufficientFundsModal from '@/contexts/billingContext/components/BillingInsufficientFundsModal/index.vue';

import { useView } from './application/useView';
import AppLayoutSider from './components/AppLayoutSider/index.vue';

export default defineComponent({
  name: 'AppLayoutWrapper',
  components: {
    Layout,
    ActionProgress,
    AppLayoutSider,
    UpdatePasswordView,
    UnlockView,
    BillingPaywallMessage,
    BillingRestrictionLayout,
    LicensePaywallMessage,
    BillingDebtPaywallMessage,
    BillingSpecialOfferPaywallMessage,
    LicenseRestrictionLayout,
    BillingInsufficientFundsModal,
  },
  props: {
    hasSider: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const {
      user,
      passwordExpirationTimer,
      passwordExpiresAt,
      isOTP,
      lockedSince,
      isBillingInsufficientFundsModalVisible,
    } = useView();

    const {
      isBillingDebtPaywallMessageShown,

      handleBillingDebtPaywallMessageVisibility,
    } = useChangeBillingDebtPaywallMessageVisibility();

    return {
      user,
      passwordExpirationTimer,
      passwordExpiresAt,
      isOTP,
      lockedSince,
      isBillingDebtPaywallMessageShown,
      isBillingInsufficientFundsModalVisible,

      handleBillingDebtPaywallMessageVisibility,
      getIsBillingPaywallMessageShown,
      getIsBillingSpecialOfferPaywallMessageShown,
      getIsLicensePaywallMessageShown,
      getIsBillingRestrictionLayoutShown,
      getIsLicenseRestrictionLayoutShown,
      useChangeBillingDebtPaywallMessageVisibility,
    };
  },
});
</script>

<style lang="scss" scoped src="./styles.scss" />
