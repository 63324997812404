import {
  computed,
  watch,
} from 'vue';

import { rebuildRejectedProtectedRoutes } from '@/router/utils/processRejectedRoute';
import { EVatRate } from '@/domains/getVatRateOptions';
import useStore from '@/store/useStore';
import { ETenantPriceViewMode } from '@/contexts/accountingContext/domain/constants';
import redirectToInitialRoute from '@/domains/redirectToInitialRoute';
import { showErrorNotification } from '@/utils';
import { useWalletStore } from '@/stores/wallet';
import { useTenantsStore } from '@/stores/tenants';
import { checkFeatureIsEnabled } from '@/domains/checkFeatureIsEnabled';
import { EExperimentalFeatures } from '@/domains/constants';
import { canBrowseWalletsByRole } from '@/domains/permissions/role/wallets';
import { hasCarrierStarterWorkplaceSubscription, hasCarrierWorkplaceSubscription } from '@/domains/permissions/subscription';

const useTenantServices = () => {
  const isPiniaMigrationStoreTenantsAvailable = computed(
    () => checkFeatureIsEnabled(EExperimentalFeatures.piniaMigrationStoreTenants));
  const store = useStore();
  const tenantsStore = useTenantsStore();
  const walletStore = useWalletStore();

  const priceViewMode = computed(() => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      return tenantsStore.currentTenantDetails?.legalEntity?.priceViewMode;
    }
    return store.state.tenants.currentTenantInfo?.legalEntity?.priceViewMode;
  });

  const userTenants = computed(() => {
    if (isPiniaMigrationStoreTenantsAvailable.value) {
      return tenantsStore.userTenants || [];
    }
    return store.state.tenants.userTenants;
  });

  const isBillingWalletAvailable = computed(() => checkFeatureIsEnabled(EExperimentalFeatures.billingWallet));

  const canFetchWalletInfo = computed(() => isBillingWalletAvailable.value
    && canBrowseWalletsByRole()
    && (hasCarrierWorkplaceSubscription() || hasCarrierStarterWorkplaceSubscription()),
  );

  const fetchCommonTenantData = () => {
    const tenantDetailsRequest = isPiniaMigrationStoreTenantsAvailable.value
      ? tenantsStore.loadTenantInfo
      : () => store.dispatch('tenants/fetchCurrentTenantInfo');

    const tenantReferenceDataRequest = isPiniaMigrationStoreTenantsAvailable.value
      ? tenantsStore.loadTenantReferenceData
      : () => store.dispatch('tenants/fetchReferenceData');

    return tenantDetailsRequest()
      .then(() => {
        const promises: Promise<void>[] = [store.dispatch('notifications/fetchUnreadNotificationsCount')];

        if (userTenants.value?.length) {
          promises.push(tenantReferenceDataRequest());
        }

        if (canFetchWalletInfo.value) {
          promises.push(walletStore.loadWallet());
        }

        Promise.all(promises).catch(showErrorNotification);
      })
      .catch(showErrorNotification);
  };

  // Отрабатывает при смене тенанта и при первичном сете тенанта после авторизации
  watch(() => tenantsStore.currentTenant?.id, (newTenantId?: string, prevTenantId?: string) => {
    if (!newTenantId) return;
    fetchCommonTenantData()
      .then(() => {
        if (!prevTenantId) {
          /** При первичном сете тенанта после авторизации делаем редирект
            * к последнему сохраненному роуту и сбрасываем его значение */
          redirectToInitialRoute(store.state.app.pathBeforeAuth);
          store.commit('app/SET_LAST_PATH_BEFORE_AUTH', null);
        } else {
          // при смене тенанта редиректим по требованиям по умолчанию
          redirectToInitialRoute();
        }
      });
    rebuildRejectedProtectedRoutes();
  });

  const vatRateByTenantSettings = computed(
    () => (priceViewMode.value !== ETenantPriceViewMode.without_vat ? EVatRate.vat_20 : EVatRate.vat_none));

  // TODO: удалить весь вотчер вместе с FF piniaMigrationStoreTenants
  watch(() => store.state.tenants.currentTenant?.id, (newCurrentTenantId?: string, prevCurrentTenantId?: string) => {
    if (!newCurrentTenantId) return;
    fetchCommonTenantData()
      .then(() => {
        if (!prevCurrentTenantId) {
        /** При первичном сете тенанта после авторизации делаем редирект
        * к последнему сохраненному роуту и сбрасываем его значение */
          redirectToInitialRoute(store.state.app.pathBeforeAuth);
          store.commit('app/SET_LAST_PATH_BEFORE_AUTH', null);
        } else {
        // при смене тенанта редиректим по требованиям по умолчанию
          redirectToInitialRoute();
        }
      });
    rebuildRejectedProtectedRoutes();
  });

  return {
    vatRateByTenantSettings,

    fetchCommonTenantData,
  };
};

export default useTenantServices;
